import React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setAuthLoaded, setLastRoute } from '../../../../common/redux/actions';
import Spinner from 'react-bootstrap/Spinner';
import PublicLayout from "../../../../common/components/layouts/public";
import LzIcon from '../../../../common/components/commons/icon';
import LzDimmer from '../../../../common/components/commons/dimmer';

class PrivateLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabKey: props.activeTabKey,
        };
    }

    componentDidMount() {
        this.saveLastRoute();
        this.checkLogin();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authToken, activeTabKey } = this.props;

        // Check login if auth token has changed
        if (prevProps.authToken !== authToken) {
            this.checkLogin();
        }

        // If active tab has changed then re-assign to state
        if (prevProps.activeTabKey !== activeTabKey) {
            this.setState({ activeTabKey: activeTabKey });
        }
    }

    saveLastRoute() {
        const { history, setLastRoute } = this.props;
        setLastRoute(history?.location?.pathname || '');
    }

    checkLogin() {
        const { authToken, authLoaded } = this.props;

        // console.log('CHECKING LOGIN', authToken, authLoaded);

        // Check for logged in user
        if (!authToken || '' === authToken) {
            // TODO: Save current screen location to be redirected to after login/register
            this.props.setAuthLoaded(false);
            this.props.history.replace('/landing');
        }

        // Check if logged in but fresh data not loaded for this window
        if (!authLoaded) {
            // Call the load api and set loaded to true.
            // lzCallApi('load-auth-data');
            // TODO: Enable when ready
            this.props.setAuthLoaded(true);
        }
    }

    render() {
        const { activeTabKey } = this.state;
        const { screenTitle, backLink, children, showLoading, authUser, className } = this.props;

        return (
            <PublicLayout className={className}>

                {(authUser && !showLoading) ?
                    <div>
                        {(backLink || screenTitle) &&
                            <div className="ndf-top-head">
                                <Link to={backLink ? backLink : '/'}>
                                    <LzIcon name="arrow-left" />
                                </Link>
                                {screenTitle && <h1>{screenTitle}</h1>}
                            </div>
                        }

                        <div className="lz-screen-main">
                            {children}
                        </div>

                        <div className="ndf-bot-tabs">
                            <Link to="/" className={'home' === activeTabKey ? 'active' : ''}>
                                <LzIcon source="ndf-png" name="home" />
                                <p>Home</p>
                            </Link>
                            <Link to="/plan" className={'plan' === activeTabKey ? 'active' : ''}>
                                <LzIcon source="ndf-png" name="settings" />
                                <p>Plans</p>
                            </Link>
                            <Link to="/deliveries-history" className={'deliveries' === activeTabKey ? 'active' : ''}>
                                <LzIcon source="ndf-png" name="cart" />
                                <p>Deliveries</p>
                            </Link>
                            <Link to="/account" className={'account' === activeTabKey ? 'active' : ''}>
                                <LzIcon source="ndf-png" name="user" />
                                <p>Account</p>
                            </Link>
                        </div>
                    </div>
                    :
                    <LzDimmer active={true} style={{ zIndex: 999 }}>
                        <Spinner animation="border" />
                    </LzDimmer>
                }

            </PublicLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        authToken: state.auth.token,
        authLoaded: state.auth.loaded,
        loading: state.commons.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthLoaded: (value) => dispatch(setAuthLoaded(value)),
        setLastRoute: (value) => dispatch(setLastRoute(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PrivateLayout));
