import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class TermsAndConditionsModal extends React.Component {

  render() {
    const ifUrl = 'https://dairydelivery.conformiz.com/MobileAppDocs/Terms';

    return (
      <Modal show={this.props.show} onHide={() => this.props.onHide()} style={{ height: '100vh' }}>
        <Modal.Header closeButton><Modal.Title>Terms & Conditions</Modal.Title></Modal.Header>
        <Modal.Body>
          <iframe
            style={{
              width: '100%',
              height: 'calc(100vh - 190px)',
              border: 0,
            }}
            src={ifUrl}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          {this.props.onAccept && <Button variant="primary" onClick={() => this.props.onAccept()}>Accept</Button>}
          {this.props.onReject ?
            <Button variant="secondary" onClick={() => this.props.onReject()}>Reject</Button>
            :
            <Button variant="secondary" onClick={() => this.props.onHide()}>Close</Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }

}

export default TermsAndConditionsModal;
