import { lzIsArray } from "../services/LzType";

export default class LzOptionModel {

    constructor(model, valueField, textField) {
        if (!model) {
            model = {};
        }

        valueField = valueField ? valueField : 'value';
        textField = textField ? textField : 'text';

        this.value = model[valueField] ? +model[valueField] : 0;
        this.text = model[textField] ? model[textField] : '';
        this.key = this.value;
    }

    static parseArray(data, valueField, textField) {
        if (!lzIsArray(data)) {
            return [];
        }

        const out = [];

        for (const item of data) {
            out.push(new LzOptionModel(item, valueField, textField));
        }

        return out;
    }

}
