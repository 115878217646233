import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';
import PrivateLayout from '../../../layouts/private';

class UserDashboardScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentBalance: 0,
            customerName: '',
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { authUser } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('dashboard', {
            client_id: authUser.client_id,
        });

        // Load data if call was successful
        if (data && data.customerName) {
            this.setState({
                currentBalance: +data.currentBalance,
                customerName: data.customerName,
            });
        }
    }

    render() {
        const { currentBalance, customerName } = this.state;

        return (
            <PrivateLayout screenTitle="Home" activeTabKey="home" backLink="/logout">

                {'' === customerName ? '' :
                    <div className="lz-dashboard-customer-info">
                        <h3>Welcome {customerName}</h3>
                        <p>Your Amount Due is <b>{lzGetFormattedAmount(currentBalance, true)}</b></p>
                    </div>
                }

                <Container className="lz-dashboard">
                    <Row>
                        <Col xs={6}>
                            <Link to="/plan">
                                <LzIcon name="cog" />
                                <p>Orders</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/ledger">
                                <LzIcon name="list-alt" />
                                <p>Bill History</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/payment-history">
                                <LzIcon name="credit-card" />
                                <p>Payments</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/customer-support">
                                <LzIcon name="support" />
                                <p>Customer Care</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/profile">
                                <LzIcon name="user" />
                                <p>Profile</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/password">
                                <LzIcon name="lock" />
                                <p>Password</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/logout">
                                <LzIcon name="sign-out" />
                                <p>Exit</p>
                            </Link>
                        </Col>
                    </Row>
                </Container>


            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(UserDashboardScreen);
