import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzDateAddDays, lzGetFormattedDate, lzGetWeekEndDate, lzGetWeekStartDate, LZ_DATE_FIELD_FORMAT } from '../../../../../../common/services/LzMoment';
import LzSpinnerComponent from '../../../commons/spinner';

class PlansCalendarWeekView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weekDays: this.buildWeekDays(props.startDate),
            selMouseDown: false,
        };

        this.lastEnterDt = '';
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate) {
            this.setState({ weekDays: this.buildWeekDays(this.props.startDate) });
        }
    }

    buildWeekDays(startDate) {
        const weekDays = [];

        for (let d = 0; d < 7; d++) {
            weekDays.push(lzGetFormattedDate(lzDateAddDays(startDate, d), LZ_DATE_FIELD_FORMAT));
        }

        return weekDays;
    }

    onPrevWeek() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzDateAddDays(currDate, -7);
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetWeekStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetWeekEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onNextWeek() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzDateAddDays(currDate, 7);
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetWeekStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetWeekEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onMonthYearChange(monthYear) {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = moment(monthYear + lzGetFormattedDate(currDate, '-DD'));
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetWeekStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetWeekEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onDateCellClick(dt) {
        // console.log('Mouse-Click', dt);
        const { selectionsAction, selectionsDates } = this.props;
        if (selectionsAction !== '' && dt > lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT)) {
            const updatedSelDates = selectionsDates.slice(); // avoid mutation
            const found = updatedSelDates.indexOf(dt);
            if (found < 0) { // not found
                updatedSelDates.push(dt); // then add
            } else {
                updatedSelDates.splice(found, 1);
            }
            this.changeSelections(selectionsAction, updatedSelDates)
        }
    }

    onDateCellMouseDown(dt) {
        // console.log('Mouse-Down', dt);
        this.lastEnterDt = dt;
        this.setState({ selMouseDown: true }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseUp(dt) {
        // console.log('Mouse-Up', dt);
        this.setState({ selMouseDown: false }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseEnter(t) {
        const dt = document.elementFromPoint(t.clientX, t.clientY).attributes?.alt?.value || '';
        // console.log('Mouse-Enter', dt);
        if (this.state.selMouseDown && dt !== '' && dt !== this.lastEnterDt) {
            this.lastEnterDt = dt;
            this.onDateCellClick(dt);
        }
    }

    changeSelections(act, data) {
        this.props.onChangeSelections(act, data);
    }

    render() {
        const { currDate, product, plan, selectionsAction, selectionsDates } = this.props;
        const { weekDays } = this.state;

        if (!currDate) {
            return <div style={{ padding: 24, textAlign: 'center' }}><LzSpinnerComponent /></div>;
        }

        const today = lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT);

        return (
            <div className="lz-calendar-week-view">

                {'' === selectionsAction &&
                    <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onPrevWeek()}>
                                <LzIcon name="chevron-left" />
                            </Button>
                        </div>
                        <div style={{ flexGrow: 1, textAlign: 'center', fontSize: 21, fontWeight: 'bold' }}>
                            <input type="month" className="lz-month-field"
                                value={lzGetFormattedDate(currDate, 'YYYY-MM')} onChange={(ev) => this.onMonthYearChange(ev.target.value)}
                            />
                        </div>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onNextWeek()}>
                                <LzIcon name="chevron-right" />
                            </Button>
                        </div>
                    </div>
                }

                <table>
                    <tbody
                        onTouchStart={(e) => this.onDateCellMouseDown(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchEnd={(e) => this.onDateCellMouseUp(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchMove={(e) => this.onDateCellMouseEnter(e.targetTouches[0])}
                    >
                        {weekDays.map((dt, dayDex) => {

                            const compareWithToday = dt ? dt.localeCompare(today) : -1; // -1 if dt < today, 0 if equal, 1 if dt > today
                            const delivered = plan.delivered[dt] || 0; // if any delivered is found then it is taken
                            const plannedQty = delivered > 0 || compareWithToday < 0 ? 0 : plan.getPlannedQty(dt); // planned qty to be 0 before today or if delivered already
                            const scheduled = plannedQty > 0 && dt >= today;
                            const halted = plan.halted.indexOf(dt) >= 0;
                            const special = delivered > 0 || compareWithToday < 0 ? 0 : plan.special[dt] || 0;

                            return (
                                <tr key={'day-' + dayDex}>
                                    <td
                                        className={
                                            (dt ? '' : ' null-day') +
                                            (dt < today ? ' past' : '') +
                                            (dt === today ? ' today' : '') +
                                            (delivered ? ' delivered' : '') +
                                            (halted ? ' halted' : '') +
                                            (special ? ' special' : '') +
                                            (scheduled ? ' scheduled' : '') +
                                            (selectionsAction !== '' && selectionsDates.indexOf(dt) >= 0 ? ' selected' : '')
                                        }
                                    >
                                        {dt &&
                                            <React.Fragment>
                                                <div className="day-number" alt={dt}>{lzGetFormattedDate(dt, 'ddd Do MMM YYYY')}</div>
                                                {plannedQty > 0 && <div className="planned-qty">{plannedQty} {product.unit}</div>}
                                                {delivered > 0 && <div className="planned-qty">{delivered} {product.unit}</div>}
                                                {special > 0 && <div className="planned-qty">{special} {product.unit}</div>}
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
        );
    }

}

export default PlansCalendarWeekView;
