import React from 'react';
import { Link } from 'react-router-dom';
import { lzGetAssetUrl } from '../../../../../../common/services/LzUtils';
import ImgLogo from '../../../../assets/images/logo-white.png';

class TermsRejectedLandingScreen extends React.Component {

  render() {
    return (
      <div
        className="gradient-bg1"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img
          src={lzGetAssetUrl(ImgLogo)}
          alt='Logo'
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '60vw',
            maxHeight: '40vw',
            marginBottom: '7vh',
          }}
        />
        <div style={{ color: 'white', padding: 16, fontSize: 21, lineHeight: 1.6, textAlign: 'center' }}
        >You must <Link to={'/login'} style={{ color: 'yellow' }}>Accept the Terms & Conditions</Link> to continue using this application!</div>
      </div>
    );
  }

}

export default TermsRejectedLandingScreen;
