import React from 'react';
import { Button } from 'react-bootstrap';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzDateAddDays, lzGetFormattedDate, LZ_DATE_FIELD_FORMAT } from '../../../../../../common/services/LzMoment';
import LzSpinnerComponent from '../../../commons/spinner';

class PlansCalendarDayView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selMouseDown: false,
        };

        this.lastEnterDt = '';
    }

    onPrevDay() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzGetFormattedDate(lzDateAddDays(currDate, -1), LZ_DATE_FIELD_FORMAT);
        onChangeDates(newCurrDate, newCurrDate, newCurrDate);
    }

    onNextDay() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzGetFormattedDate(lzDateAddDays(currDate, 1), LZ_DATE_FIELD_FORMAT);
        onChangeDates(newCurrDate, newCurrDate, newCurrDate);
    }

    onDateChange(dt) {
        const { onChangeDates } = this.props;
        const newCurrDate = lzGetFormattedDate(dt);
        onChangeDates(newCurrDate, newCurrDate, newCurrDate);
    }

    onDateCellClick(dt) {
        // console.log('Mouse-Click', dt);
        const { selectionsAction, selectionsDates } = this.props;
        if (selectionsAction !== '' && dt > lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT)) {
            const updatedSelDates = selectionsDates.slice(); // avoid mutation
            const found = updatedSelDates.indexOf(dt);
            if (found < 0) { // not found
                updatedSelDates.push(dt); // then add
            } else {
                updatedSelDates.splice(found, 1);
            }
            this.changeSelections(selectionsAction, updatedSelDates)
        }
    }

    onDateCellMouseDown(dt) {
        // console.log('Mouse-Down', dt);
        this.lastEnterDt = dt;
        this.setState({ selMouseDown: true }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseUp(dt) {
        // console.log('Mouse-Up', dt);
        this.setState({ selMouseDown: false }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseEnter(t) {
        const dt = document.elementFromPoint(t.clientX, t.clientY).attributes?.alt?.value || '';
        // console.log('Mouse-Enter', dt);
        if (this.state.selMouseDown && dt !== '' && dt !== this.lastEnterDt) {
            this.lastEnterDt = dt;
            this.onDateCellClick(dt);
        }
    }

    changeSelections(act, data) {
        this.props.onChangeSelections(act, data);
    }

    render() {
        const { currDate, product, plan, selectionsAction, selectionsDates } = this.props;

        if (!currDate) {
            return <div style={{ padding: 24, textAlign: 'center' }}><LzSpinnerComponent /></div>;
        }

        const today = lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT);

        const compareWithToday = currDate ? currDate.localeCompare(today) : -1; // -1 if currDate < today, 0 if equal, 1 if currDate > today
        const delivered = plan.delivered[currDate] || 0; // if any delivered is found then it is taken
        const plannedQty = delivered > 0 || compareWithToday < 0 ? 0 : plan.getPlannedQty(currDate); // planned qty to be 0 before today or if delivered already
        const scheduled = plannedQty > 0 && currDate >= today;
        const halted = plan.halted.indexOf(currDate) >= 0;
        const special = delivered > 0 || compareWithToday < 0 ? 0 : plan.special[currDate] || 0;

        return (
            <div className="lz-calendar-day-view">

                {'' === selectionsAction &&
                    <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onPrevDay()}>
                                <LzIcon name="chevron-left" />
                            </Button>
                        </div>
                        <div style={{ flexGrow: 1, textAlign: 'center', fontSize: 21, fontWeight: 'bold' }}>
                            <input type="date" className="lz-month-field"
                                value={lzGetFormattedDate(currDate, 'YYYY-MM-DD')} onChange={(ev) => this.onDateChange(ev.target.value)}
                            />
                        </div>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onNextDay()}>
                                <LzIcon name="chevron-right" />
                            </Button>
                        </div>
                    </div>
                }

                <table>
                    <tbody
                        onTouchStart={(e) => this.onDateCellMouseDown(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchEnd={(e) => this.onDateCellMouseUp(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchMove={(e) => this.onDateCellMouseEnter(e.targetTouches[0])}
                    >
                        <tr>
                            <td
                                className={
                                    (currDate ? '' : ' null-day') +
                                    (currDate < today ? ' past' : '') +
                                    (currDate === today ? ' today' : '') +
                                    (delivered ? ' delivered' : '') +
                                    (halted ? ' halted' : '') +
                                    (special ? ' special' : '') +
                                    (scheduled ? ' scheduled' : '') +
                                    (selectionsAction !== '' && selectionsDates.indexOf(currDate) >= 0 ? ' selected' : '')
                                }
                            >
                                <React.Fragment>
                                    <div className="day-number" alt={currDate}>{lzGetFormattedDate(currDate, 'ddd Do MMM YYYY')}</div>
                                    {plannedQty > 0 && <div className="planned-qty">{plannedQty} {product.unit}</div>}
                                    {delivered > 0 && <div className="planned-qty">{delivered} {product.unit}</div>}
                                    {special > 0 && <div className="planned-qty">{special} {product.unit}</div>}
                                </React.Fragment>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        );
    }

}

export default PlansCalendarDayView;
