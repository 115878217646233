import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldSwitch from '../../../../../../common/components/commons/fields/switch';
import LzForm from '../../../../../../common/components/commons/form';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import PrivateLayout from '../../../layouts/private';

class ChangeSMSAlertsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      daily_delivery_sms: 0,
      alert_new_product: 0,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    // Make the api call and get the data
    const data = await lzCallApi('CustomerNotificationStatus', {
      client_id: this.props.authUser.client_id,
    }, 'post');

    // Load data if call was successful
    if (data) {
      this.setState({
        daily_delivery_sms: +data.daily_delivery_sms,
        alert_new_product: +data.alert_new_product,
      });
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  async save() {
    const { daily_delivery_sms, alert_new_product } = this.state;
    const { authUser, setSuccessMessage } = this.props;

    // Make the api call and get processed data
    const data = await lzCallApi('SaveCustomerNotificationStatus', {
      client_id: authUser.client_id,
      daily_delivery_sms: daily_delivery_sms ? 1 : 0,
      alert_new_product: alert_new_product ? 1 : 0,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your settings are successfully changed!');
    }
  }

  render() {
    const { daily_delivery_sms, alert_new_product } = this.state;

    return (
      <PrivateLayout layout="settings" screenTitle="Manage SMS Alerts" backLink="/settings">
        <div className="lz-form-page">
          <Container>

            <LzForm onSubmit={() => this.save()}>

              <LzFormFieldSwitch
                asSwitch
                size="md"
                label="I wish to receive daily delivery SMS"
                transformOrigin="center"
                name="daily_delivery_sms"
                value={daily_delivery_sms}
                onChange={this.handleInputChange}
              />

              <br />

              <LzFormFieldSwitch
                asSwitch
                size="md"
                label="I wish to receive news about new products and promotions"
                transformOrigin="center"
                name="alert_new_product"
                value={alert_new_product}
                onChange={this.handleInputChange}
              />

              <br />

              <LzButton fluid type="submit" label="Save" />

            </LzForm>

          </Container>
        </div>
      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setErrorMessage: (value) => dispatch(setErrorMessage(value)),
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSMSAlertsScreen);
