import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import { Row, Col, Container } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzGetFormattedDate } from '../../../../../../common/services/LzMoment';

class UpdatePlanIntervalScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {
                fromDate: lzGetFormattedDate('now', 'YYYY-MM-DD'),
                quantity: 1,
                intervalDays: 1,
            },
        };
    }

    componentDidMount() {
        this.loadIntervalPlan();
    }

    handleModelChange = (name, value) => {
        this.setState({ model: Object.assign({}, this.state.model, { [name]: value }) });
    };

    async loadIntervalPlan() {
        const { authUser, product, history, setErrorMessage } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get the data
        const data = await lzCallApi('getCustomerIntervalSchedulerStatus', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Load data if call was successful
        if (data && data[0]) {
            this.setState({
                model: {
                    fromDate: data[0].start_interval_scheduler,
                    quantity: data[0].product_quantity,
                    intervalDays: data[0].interval_days,
                },
            });
        }
    }

    async saveIntervalPlan() {
        const { model } = this.state;
        const { product, authUser, setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('SaveIntervalScheduler', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            product_quantity: model.quantity,
            interval_days: model.intervalDays,
            start_interval_scheduler: model.fromDate,
        }, 'post', true);
    }

    async cancelPlan() {
        const { authUser, product, setSuccessMessage, history } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('CancelRegularOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully cancelled!');
            history.push('/plan-select-type');
        }
    }

    render() {
        const { model } = this.state;

        return (
            <PrivateLayout layout="plan" screenTitle="Interval Plan">

                <div className="lz-form-page lz-plan-form">

                    <Container>

                        <LzForm onSubmit={() => this.saveIntervalPlan()}>

                            <Row>
                                <Col xs={4}><div className="label-box">From:</div></Col>
                                <Col xs={8}>
                                    <input type="date" className="lz-weekly-qty"
                                        name="fromDate" value={model.fromDate}
                                        onChange={(ev) => this.handleModelChange('fromDate', ev.target.value)}
                                    />
                                </Col>
                            </Row>
                            <br /><br />

                            <Row>
                                <Col xs={8}><div className="label-box filled">Quantity:</div></Col>
                                <Col xs={4}>
                                    <input type="number" className="lz-weekly-qty"
                                        name="quantity" value={model.quantity}
                                        onChange={(ev) => this.handleModelChange('quantity', ev.target.value)}
                                    />
                                </Col>
                            </Row>
                            <br /><br />

                            <Row>
                                <Col xs={8}><div className="label-box filled">Add Interval Days:</div></Col>
                                <Col xs={4}>
                                    <input type="number" className="lz-weekly-qty"
                                        name="intervalDays" value={model.intervalDays}
                                        onChange={(ev) => this.handleModelChange('intervalDays', ev.target.value)}
                                    />
                                </Col>
                            </Row>
                            <br /><br />

                            {/*<Alert variant="warning">
                                <LzIcon name="warning" /> Your current plan (if any) will be cancelled and a new plan will start from the date you enter above.
                            </Alert>*/}

                            <div style={{ textAlign: 'right', paddingTop: 10 }}>
                                <LzButton type="submit" icon="save" label="Save Plan" style={{ display: 'inline-block' }} />
                            </div>

                            <LzButton type="button" icon="times" label="Cancel Current Schedule" onClick={() => this.cancelPlan()} />

                            <Link to="/plan-calendar"><LzButton type="button" icon="cogs" label="Advanced Options" /></Link>

                        </LzForm>

                    </Container>

                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanIntervalScreen));
