import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { setErrorMessage } from '../../../../../../common/redux/actions';

// NOTE: This screen is discarded as per instructions from client in the Tasks document point # 27.
//          Just kept here as backup.

class UpdatePlanSelectTypeScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkingPlan: false,
        };
        this.lastRoute = props.lastRoute;
    }

    componentDidMount() {
        const { history } = this.props;

        // Only load the plan type if you came from "plan product selection" screen, otherwise we came from
        // weekly or interval screen so we should go directly back to the "plan product selection" screen to
        // avoid the loop.
        if ('/plan' === this.lastRoute) {
            this.loadProductPlanType();
        } else {
            history.goBack();
        }
    }

    async loadProductPlanType() {
        const { authUser, product, history, setErrorMessage } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        this.setState({ checkingPlan: true });

        // Make the api call and get the data
        const data = await lzCallApi('getCustomerRegularOrderType', {
            product_id: product.product_id,
            client_id: authUser.client_id,
        }, 'post');

        // Load data if call was successful
        if (2 === data) {
            history.push('/plan-interval');
        }
        else if (1 === data) {
            history.push('/plan-weekly');
        }
        this.setState({ checkingPlan: false });
    }

    render() {
        const { product } = this.props;
        const { checkingPlan } = this.state;

        return (
            <PrivateLayout layout="plan" screenTitle="Update Plan" activeTabKey="plan">

                {(product && !checkingPlan) &&
                    <div className="lz-schedule-type-sel">

                        <h5>Product Selected:</h5>
                        <div className="lz-product-sel">
                            <img src={product.product_image} alt="prod" />
                            {product.product_name}
                        </div>
                        <Link to="/plan"><LzButton><LzIcon name="arrow-left" /> Change Product</LzButton></Link>

                        <h5 style={{ marginTop: 30 }}>How would you like orders to be scheduled?</h5>

                        <h6>For orders less than 2 liters daily:</h6>
                        <Link to="/plan-interval"><LzButton type="button">Interval Based Schedule</LzButton></Link>

                        <h6>To deliver orders on weekly basis:</h6>
                        <Link to="/plan-weekly"><LzButton type="button">Weekly Based Schedule</LzButton></Link>

                    </div>
                }

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
        lastRoute: state.commons.lastRoute,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanSelectTypeScreen));
