import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import { Container } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';

class UpdatePlanIntervalOneTimeScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {},
        };
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    async saveOneTimeIntervalPlan() {
        const { model } = this.state;
        const { authUser, setSuccessMessage, setErrorMessage, product, history } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get processed data
        const data = await lzCallApi('createSpecialOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            start_date: model.onetimeFromDate,
            end_date: model.onetimeToDate,
            quantity: model.onetimeQuantity,
            preferred_time_id: 0,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your special order was successfully saved!');
        }
    }

    render() {
        const { model } = this.state;
        const { history } = this.props;

        return (
            <PrivateLayout screenTitle="One-Time Order">

                <div className="lz-form-page lz-plan-form">

                    <Container>

                        <LzForm onSubmit={() => this.saveOneTimeIntervalPlan()}>

                            <LzFormFieldInput required fluid type="date" label="From"
                                name="onetimeFromDate" value={model.onetimeFromDate} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid type="date" label="To"
                                name="onetimeToDate" value={model.onetimeToDate} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid type="number" label="Quantity"
                                name="onetimeQuantity" value={model.onetimeQuantity} onChange={this.handleModelChange} />

                            <LzButton fluid type="submit" icon="save" label="Save One-Time Order" />

                            <LzButton fluid type="button" variant="secondary" icon="arrow-left" label="Go Back" onClick={() => history.goBack()} />

                        </LzForm>

                    </Container>

                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanIntervalOneTimeScreen));
