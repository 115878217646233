import React from 'react';
import { connect } from 'react-redux';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import UserModel from '../../../../../../common/models/user';
import PrivateLayout from '../../../layouts/private';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzIcon from '../../../../../../common/components/commons/icon';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldTexarea from '../../../../../../common/components/commons/fields/textarea';

class UpdateProfileScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      model: null,
      edit: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  handleInputChange = (name, value) => {
    this.setState({ model: Object.assign({}, this.state.model, { [name]: value }) });
  };

  async load() {
    // Make the api call and get the data
    const data = await lzCallApi('getCustomerProfile', {
      client_id: this.props.authUser.client_id,
    }, 'post');

    // Load data if call was successful
    if (data && data.profile) {
      this.setState({
        model: new UserModel(data.profile),
      });
    }
  }

  async save() {
    const { model } = this.state;
    const { authUser, setSuccessMessage } = this.props;

    // Make the api call and get processed data
    const data = await lzCallApi('saveCustomerProfile', {
      client_id: authUser.client_id,
      fullname: model.fullname,
      cell_no_1: model.cell_no_1,
      address: model.address,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your profile is successfully updated!');
      this.setState({ edit: false });
    }
  }

  render() {
    const { model, edit } = this.state;

    return (
      <PrivateLayout layout="settings" screenTitle="Profile" backLink="/settings">

        {model &&
          <div className="profile-data">

            <h4>{model.fullname}</h4>

            {edit ?
              <div>

                <LzForm onSubmit={() => this.save()}>

                  {/*<LzFormFieldInput required fluid label="" placeholder="Your Phone #" hint=" "
                    name="cell_no_1" value={model.cell_no_1} onChange={this.handleInputChange} />*/}
                  <div className="field"><div className="icon"><LzIcon name="phone" /></div> {model.cell_no_1}</div>

                  <LzFormFieldTexarea required fluid label="" placeholder="Your Address" hint=" "
                    name="address" value={model.address} onChange={this.handleInputChange} />

                  <LzButton fluid type="submit" label="Update Profile" />

                </LzForm>

              </div>
              :
              <div onClick={() => this.setState({ edit: true })}>

                <div className="field"><div className="icon"><LzIcon name="phone" /></div> {model.cell_no_1}</div>

                <div className="field"><div className="icon"><LzIcon name="map-marker" /></div> {model.address}</div>

              </div>
            }

          </div>
        }

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProfileScreen);
