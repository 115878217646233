import { AppConstants } from "./Constants";

export const lzSaveToLocalStorage = (key, data, forBaseCompany) => {
  localStorage.setItem(
    AppConstants.app_code + '_' + (forBaseCompany ? 0 : AppConstants.app_company_id) + '_' + key,
    JSON.stringify({ type: typeof data, value: data })
  );
};

export const lzGetFromLocalStorage = (key, defaultData, forBaseCompany) => {
  try {
    // Loads as json
    const data = localStorage.getItem(AppConstants.app_code + '_' + (forBaseCompany ? 0 : AppConstants.app_company_id) + '_' + key);

    // If not found then send default data
    if (!data) {
      return defaultData;
    }

    // Else parse json
    const load = JSON.parse(data);

    // Return the saved value
    return load.value;
  } catch (e) {
    console.log('LocalStorage: ' + key, e.toString());
    return '';
  }
};

export const lzRemoveFromLocalStorage = (key, forBaseCompany) => {
  localStorage.removeItem(
    AppConstants.app_code + '_' + (forBaseCompany ? 0 : AppConstants.app_company_id) + '_' + key
  );
};
