import { AppConstants } from './common/services/Constants';
import { lzSaveToLocalStorage } from './common/services/LzLocalStorage';
import { lzLogInfo } from './common/services/LzUtils';

// Import styles of the app that is to be built.
// TODO: This needs to be auto-handled too, but right now there are some restrictions in react.
// import './companies/dairycraft/assets/styles/sass/index.scss';
// import './companies/mksfarms/assets/styles/sass/index.scss';
// import './companies/safentaste/assets/styles/sass/index.scss';
import './companies/tazafarms/assets/styles/sass/index.scss';
// import './companies/ndf/assets/styles/sass/index.scss';

console.log('LOADING APP...');

// Save the iOS bundle path to local storage if registered
if (typeof window.IOS_BUNDLE_PATH !== 'undefined') {
  lzLogInfo('SAVING iOS Bundle Path', window.IOS_BUNDLE_PATH);
  lzSaveToLocalStorage('ios_bundle_path', window.IOS_BUNDLE_PATH);
} else {
  lzLogInfo('iOS Bundle Path NOT FOUND!!!');
}

if ('dairycraft' === AppConstants.app_code) {
  require('./companies/dairycraft/index');
  console.log('LOADED Dairy Craft APP!');
} else if ('mksfarms' === AppConstants.app_code) {
  require('./companies/mksfarms/index');
  console.log('LOADED MKs Farms APP!');
} else if ('safentaste' === AppConstants.app_code) {
  require('./companies/safentaste/index');
  console.log('LOADED Safe N Taste APP!');
} else if ('ndf' === AppConstants.app_code) {
  require('./companies/ndf/index');
  console.log('LOADED NDF APP!');
} else if ('tazafarms' === AppConstants.app_code) {
  require('./companies/tazafarms/index');
  console.log('LOADED TAZA-Taste of Health APP!');
} else {
  require('./common/index');
  console.log('LOADED Default APP!');
}
