import React from 'react';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../common/components/commons/icon';

class LzBotIconsBar extends React.Component {

  render() {
    const { activeTabKey, isGuest } = this.props;

    return (
      <div className="lz-bot-icons-bar">

        {/*<Link to="/plan" className={'pouch ' + ('plan' === activeTabKey ? 'active' : '')}>
          <LzIcon source="taza-png" name="product" />
          <p>Product</p>
        </Link>*/}

        <Link to="/plan" className={'plan' === activeTabKey ? 'active' : ''}>
          <LzIcon name="shopping-basket" style={{ margin: '3px 0' }} />
          <p>Product</p>
        </Link>

        {!isGuest &&
          <Link to="/ledger" className={'record' === activeTabKey ? 'active' : ''}>
            <LzIcon source="taza-png" name="ledger" />
            <p>Bill History</p>
          </Link>
        }

        {/*!isGuest &&
          <Link to="/make-payment" className={'payments' === activeTabKey ? 'active' : ''}>
            <LzIcon source="taza-png" name="pay" />
            <p>Payments</p>
          </Link>
        */}

        <Link to="/customer-support" className={'complaints' === activeTabKey ? 'active' : ''}>
          <LzIcon source="taza-png" name="complaint" />
          <p>Complaints</p>
        </Link>

        <Link to="/settings" className={'settings' === activeTabKey ? 'active' : ''}>
          <LzIcon source="taza-png" name="settings" />
          <p>Settings</p>
        </Link>

      </div>
    );
  }

}

export default LzBotIconsBar;
