import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzIcon from '../../../commons/icon';
import PrivateLayout from '../../../layouts/private';

class UserDashboardScreen extends React.Component {

    render() {
        return (
            <PrivateLayout screenTitle="Dashboard">
                <Container className="lz-dashboard">
                    <Row>
                        <Col xs={6}>
                            <Link to="/plan">
                                <LzIcon name="cog" />
                                <p>Orders</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/deliveries-history">
                                <LzIcon name="th-list" />
                                <p>Deliveries</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/customer-support">
                                <LzIcon name="support" />
                                <p>Support</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/profile">
                                <LzIcon name="user" />
                                <p>Profile</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/password">
                                <LzIcon name="lock" />
                                <p>Password</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/logout">
                                <LzIcon name="sign-out" />
                                <p>Exit</p>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </PrivateLayout>
        );
    }

}

export default UserDashboardScreen;
