import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { lzGetFormattedDate, lzGetFormattedTime, lzGetMonthEndDate, lzGetMonthStartDate } from '../../../../../../common/services/LzMoment';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { Col, Container, Row } from 'react-bootstrap';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';

class DeliveriesHistoryScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            startDate: lzGetMonthStartDate('YYYY-MM-DD'),
            endDate: lzGetMonthEndDate('YYYY-MM-DD'),
        };
    }

    componentDidMount() {
        this.loadHistory();
    }

    async loadHistory() {
        const { startDate, endDate } = this.state;
        const { authUser } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('deliverybetweenDateRange', {
            client_id: authUser.client_id,
            start_date: startDate,
            end_date: endDate,
        });

        // Load data if call was successful
        if (data) {
            this.setState({ records: data });
        }
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { startDate, endDate, records } = this.state;

        return (
            <PrivateLayout screenTitle="Delivery History">

                <div className="mk-heading-bar">
                    <Container>
                        <Row noGutters>
                            <Col xs={5}>
                                <LzFormFieldInput fluid inline type="date"
                                    name="startDate" value={startDate} onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col xs={5}>
                                <LzFormFieldInput fluid inline type="date"
                                    name="endDate" value={endDate} onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col xs={2}>
                                <LzButton fluid variant="link" type="button" onClick={() => this.loadHistory()}><LzIcon name="refresh" /></LzButton>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    {records.map((rec, dex) =>
                        <div className="mk-delivery-history-row">
                            <h6>{rec.product_name}</h6>
                            <Row noGutters>
                                <Col xs={4}>
                                    <h6>Date</h6>
                                    {lzGetFormattedDate(rec.date)}
                                </Col>
                                <Col xs={2}>
                                    <h6>Time</h6>
                                    {lzGetFormattedTime(rec.time)}
                                </Col>
                                <Col xs={2}>
                                    <h6>Qty</h6>
                                    {rec.quantity + ' ' + rec.unit}
                                </Col>
                                <Col xs={2}>
                                    <h6>Price</h6>
                                    {lzGetFormattedAmount(rec.amount, true)}
                                </Col>
                                <Col xs={2}>
                                    <h6>Status</h6>
                                    Delivered
                                </Col>
                            </Row>
                        </div>
                    )}
                </Container>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(DeliveriesHistoryScreen);
