import React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzForm from '../../../../../../common/components/commons/form';
import PublicLayout from "../../../layouts/public";
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzCallApi } from '../../../../../../common/services/LzApiService';

class ForgotPasswordScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      xxphonenum: '',
    };
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit() {
    this.callForgotPwdApi();
  }

  async callForgotPwdApi() {
    const { setSuccessMessage } = this.props;
    const { xxphonenum } = this.state;

    // Make the api call and get processed data
    const data = await lzCallApi('customerForgetPassword', {
      cell_no_1: xxphonenum,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your login details are sent to you via sms, please check and continue to login...');
    }

  }

  render() {
    const { xxphonenum } = this.state;

    return (
      <PublicLayout verticallySpreaded>
        <Container>
          <LzForm className="form" onSubmit={() => this.onSubmit()}>

            <LzFormFieldInput fluid required type="text" hint=" " placeholder="Phone No."
              name="xxphonenum" value={xxphonenum} onChange={this.handleInputChange} />

            <LzButton fluid type="submit" variant="primary"
              icon="send" label="Send Login Credentials"
            />

          </LzForm>

          <div style={{ textAlign: "center" }}>
            <Link to={'/login'}>Ready to login? click here</Link>
          </div>
        </Container>
      </PublicLayout>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ForgotPasswordScreen);
