import { setCurrentLocation } from "../redux/actions";
import { isAndroid, isIOS, isBrowser, runOnHost, getIOSBundlePath, callIOSNativeCommand } from "./LzHostInterface";

export const lzGetAssetUrl = (reactPath) => {
    let out = reactPath;
    let ident = 'Default';

    if (isAndroid()) {
        ident = 'Android';
        out = out.replace('./static', 'file:///android_asset/static');
    } else if (isIOS()) {
        ident = 'iOS';
        out = out.replace('./static', getIOSBundlePath().replace('index.html', 'static'));
    } else if (isBrowser()) {
        ident = 'Browser';
        // Its browser so leave it as is
    }

    // TODO: Other cases come here

    lzLogInfo('LOC', ident, window.location.href, out);

    return out;
};

export const lzGetFormattedAmount = (value, allowDecimals) => {
    let input = allowDecimals ? value : Math.round(value);
    return new Intl.NumberFormat('en-PK', {
        style: 'currency',
        currency: 'PKR'
    }).format(input).replace('.00', '');
};

export const getDropdownOptions = (data, value_field, text_field) => {
    if (!data) {
        return [];
    }

    //    console.log('GET-DDR-OPTS', data, value_field, text_field);

    return data.map((d) => {
        return {
            key: d[value_field],
            value: d[value_field],
            text: d[text_field],
        };
    });
};

export const lzLogInfo = (...args) => {
    args.unshift('LZ-LOG-INFO');
    console.log(...args);
};

export const lzShowToast = (msg) => {
    runOnHost(
        () => {
            window.Android.showToast(msg);
        },
        () => {
            callIOSNativeCommand('TOAST|' + msg);
        },
        () => {
            console.log('TOAST', msg);
        },
    );
};

export const lzGetCurrentLocation = (showMap, pinSelection, oldLat, oldLng) => {
    const valShowMap = !!showMap;
    const valPinSelection = !!pinSelection;
    const valOldLat = typeof oldLat === 'undefined' ? 0 : +oldLat;
    const valOldLng = typeof oldLng === 'undefined' ? 0 : +oldLng;

    runOnHost(
        () => {
            window.Android.initLocationLoad(valShowMap, valPinSelection, valOldLat, valOldLng);
        },
        () => {
            callIOSNativeCommand('GLOC|' + valShowMap + '|' + valPinSelection + '|' + valOldLat + '|' + valOldLng);
        },
        () => {
            const reduxDispatch = window.reduxStore.dispatch;

            if (window.navigator.geolocation) {
                lzLogInfo('Geo Location getCurrentPosition() Called...');
                window.navigator.geolocation.getCurrentPosition((position) => {
                    lzLogInfo('Geo Location data fetched:', position);
                    reduxDispatch(setCurrentLocation(position.coords));
                });
            } else {
                lzLogInfo('Geo Location not supported!');
            }
        },
    );
};

export const lzCallInAppBrowser = (url) => {
    runOnHost(
        () => {
            window.Android.showInAppBrowser(url);
        },
        () => {
            callIOSNativeCommand('IAB|' + url);
        },
        () => {
            window.open(url, '_blank');
        },
    );
};

window.hostCalledLocationUpdate = (latitude, longitude, address) => {
    const reduxDispatch = window.reduxStore.dispatch;

    lzLogInfo('Geo Location data fetched:', latitude, longitude, address);
    reduxDispatch(setCurrentLocation({ latitude: latitude, longitude: longitude, address: address }));
};

/**
 * Get logged in user id whether its a guest or a regular user.
 */
export const lzGetLoggedInUserId = () => {
    // Get state and dispatch from redux store
    const reduxState = window.reduxStore.getState();

    // Get if regular user
    if (reduxState.auth && reduxState.auth.user) {
        const id = +reduxState.auth.user.client_id;
        if (id > 0) {
            return { id: id, is_guest: false };
        }
    }

    // Get if guest user
    if (reduxState.auth && reduxState.auth.guestLoginId) {
        const id = +reduxState.auth.guestLoginId;
        if (id > 0) {
            return { id: id, is_guest: true };
        }
    }

    return { id: 0, is_guest: false };
};
