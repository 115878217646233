import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzDateAddDays, lzDateAddMonths, lzGetDayOfWeek, lzGetFormattedDate, lzGetMonthEndDate, lzGetMonthStartDate, LZ_DATE_FIELD_FORMAT } from '../../../../../../common/services/LzMoment';
import LzSpinnerComponent from '../../../commons/spinner';

class PlansCalendarMonthView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            matrix: this.buildMonthMatrix(props.currDate),
            selMouseDown: false,
        };

        this.lastEnterDt = '';
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currDate !== this.props.currDate) {
            this.setState({ matrix: this.buildMonthMatrix(this.props.currDate) });
        }
    }

    buildMonthMatrix(currDate) {
        const matrix = [];
        const days = [1, 2, 3, 4, 5, 6, 7]; // 1 - Mon, 2 - Tue, ...
        const firstDoM = lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT, currDate);
        const lastDoM = lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT, currDate);
        const firstDayOfMonthWeekday = lzGetDayOfWeek(firstDoM);
        let dateTemp = null;

        // First week
        let weekDays = [];
        for (const day of days) {
            if (day === firstDayOfMonthWeekday) { // 1st of month
                dateTemp = firstDoM;
            } else if (dateTemp) { // month already started
                dateTemp = lzDateAddDays(dateTemp, 1).format(LZ_DATE_FIELD_FORMAT);
            }
            weekDays.push(dateTemp);
        }
        matrix.push(weekDays);

        // Remaining weeks
        while (dateTemp && dateTemp <= lastDoM) {
            weekDays = [];
            for (const day of days) {
                dateTemp = lzDateAddDays(dateTemp, 1).format(LZ_DATE_FIELD_FORMAT);
                if (dateTemp > lastDoM) {
                    dateTemp = null;
                }
                if (1 === day && !dateTemp) { // if last date of month comes on exactly last week, then avoid next blank week line
                    break;
                }
                weekDays.push(dateTemp);
            }
            matrix.push(weekDays);
        }

        return matrix;
    }

    onPrevMonth() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzDateAddMonths(currDate, -1);
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onNextMonth() {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = lzDateAddMonths(currDate, 1);
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onMonthYearChange(monthYear) {
        const { currDate, onChangeDates } = this.props;
        const newCurrDate = moment(monthYear + lzGetFormattedDate(currDate, '-DD'));
        onChangeDates(
            lzGetFormattedDate(newCurrDate, LZ_DATE_FIELD_FORMAT),
            lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT, newCurrDate),
            lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT, newCurrDate)
        );
    }

    onDateCellClick(dt) {
        // console.log('Mouse-Click', dt);
        const { selectionsAction, selectionsDates } = this.props;
        if (selectionsAction !== '' && dt > lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT)) {
            const updatedSelDates = selectionsDates.slice(); // avoid mutation
            const found = updatedSelDates.indexOf(dt);
            if (found < 0) { // not found
                updatedSelDates.push(dt); // then add
            } else {
                updatedSelDates.splice(found, 1);
            }
            this.changeSelections(selectionsAction, updatedSelDates)
        }
    }

    onDateCellMouseDown(dt) {
        // console.log('Mouse-Down', dt);
        this.lastEnterDt = dt;
        this.setState({ selMouseDown: true }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseUp(dt) {
        // console.log('Mouse-Up', dt);
        this.setState({ selMouseDown: false }, () => this.onDateCellClick(dt));
    }

    onDateCellMouseEnter(t) {
        const dt = document.elementFromPoint(t.clientX, t.clientY).attributes?.alt?.value || '';
        // console.log('Mouse-Enter', dt);
        if (this.state.selMouseDown && dt !== '' && dt !== this.lastEnterDt) {
            this.lastEnterDt = dt;
            this.onDateCellClick(dt);
        }
    }

    changeSelections(act, data) {
        this.props.onChangeSelections(act, data);
    }

    render() {
        const { currDate, product, plan, selectionsAction, selectionsDates } = this.props;
        const { matrix } = this.state;

        if (!currDate) {
            return <div style={{ padding: 24, textAlign: 'center' }}><LzSpinnerComponent /></div>;
        }

        const today = lzGetFormattedDate(null, LZ_DATE_FIELD_FORMAT);

        return (
            <div className="lz-calendar-month-view">

                {'' === selectionsAction &&
                    <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onPrevMonth()}>
                                <LzIcon name="chevron-left" />
                            </Button>
                        </div>
                        <div style={{ flexGrow: 1, textAlign: 'center', fontSize: 21, fontWeight: 'bold' }}>
                            <input type="month" className="lz-month-field"
                                value={lzGetFormattedDate(currDate, 'YYYY-MM')} onChange={(ev) => this.onMonthYearChange(ev.target.value)}
                            />
                        </div>
                        <div>
                            <Button variant="primary" size="sm" className="lz-button" onClick={() => this.onNextMonth()}>
                                <LzIcon name="chevron-right" />
                            </Button>
                        </div>
                    </div>
                }

                <table>
                    <thead>
                        <tr>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </tr>
                    </thead>
                    <tbody
                        onTouchStart={(e) => this.onDateCellMouseDown(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchEnd={(e) => this.onDateCellMouseUp(e.targetTouches[0]?.target?.attributes?.alt?.value || '')}
                        onTouchMove={(e) => this.onDateCellMouseEnter(e.targetTouches[0])}
                    >
                        {matrix.map((week, weekDex) => {
                            return (
                                <tr key={'week-' + weekDex}>
                                    {week.map((dt, dayDex) => {

                                        const compareWithToday = dt ? dt.localeCompare(today) : -1; // -1 if dt < today, 0 if equal, 1 if dt > today
                                        const delivered = plan.delivered[dt] || 0; // if any delivered is found then it is taken
                                        const plannedQty = delivered > 0 || compareWithToday < 0 ? 0 : plan.getPlannedQty(dt); // planned qty to be 0 before today or if delivered already
                                        const scheduled = plannedQty > 0 && dt >= today;
                                        const halted = plan.halted.indexOf(dt) >= 0;
                                        const special = delivered > 0 || compareWithToday < 0 ? 0 : plan.special[dt] || 0;

                                        return (
                                            <td
                                                key={'week-' + weekDex + '-day-' + dayDex}
                                                className={
                                                    (dt ? '' : ' null-day') +
                                                    (dt < today ? ' past' : '') +
                                                    (dt === today ? ' today' : '') +
                                                    (delivered ? ' delivered' : '') +
                                                    (halted ? ' halted' : '') +
                                                    (special ? ' special' : '') +
                                                    (scheduled ? ' scheduled' : '') +
                                                    (selectionsAction !== '' && selectionsDates.indexOf(dt) >= 0 ? ' selected' : '')
                                                }
                                            >
                                                {dt &&
                                                    <React.Fragment>
                                                        <div className="day-number" alt={dt}>{lzGetFormattedDate(dt, 'D')}</div>
                                                        {plannedQty > 0 && <div className="planned-qty">{plannedQty} {product.unit}</div>}
                                                        {delivered > 0 && <div className="planned-qty">{delivered} {product.unit}</div>}
                                                        {special > 0 && <div className="planned-qty">{special} {product.unit}</div>}
                                                    </React.Fragment>
                                                }
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>
        );
    }

}

export default PlansCalendarMonthView;
