import { lzGetFromLocalStorage, lzSaveToLocalStorage } from '../../services/LzLocalStorage';
import { actions } from '../actions';

const defaultState = {
    sidenavOpen: lzGetFromLocalStorage('sidenavOpen', false),
};

const layout = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SHOW_SIDENAV:
            lzSaveToLocalStorage('sidenavOpen', true);
            return Object.assign({}, state, { sidenavOpen: true });

        case actions.HIDE_SIDENAV:
            lzSaveToLocalStorage('sidenavOpen', false);
            return Object.assign({}, state, { sidenavOpen: false });

        case actions.SET_SIDENAV:
            lzSaveToLocalStorage('sidenavOpen', action.value);
            return Object.assign({}, state, { sidenavOpen: action.value });

        case actions.TOGGLE_SIDENAV:
            const newValue = !state.sidenavOpen;
            lzSaveToLocalStorage('sidenavOpen', newValue);
            return Object.assign({}, state, { sidenavOpen: newValue });

        default:
            return state;

    }

};

export default layout;
