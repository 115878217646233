import { lzIsArray } from "../services/LzType";

export default class ProductModel {

    constructor(model) {
        if (!model) {
            model = {};
        }

        this.product_id = model.product_id ? +model.product_id : 0;
        this.product_name = model.product_name ? model.product_name : '';
        this.description = model.description ? model.description : '';
        this.product_image = model.product_image ? model.product_image : '';
        this.order_type = model.order_type ? +model.order_type : 0;
        this.price = model.price ? +model.price : 0;
        this.regular_order_type = model.regular_order_type ? +model.regular_order_type : 0;
        this.unit = model.unit ? model.unit : '';
        this.is_selected = model.is_selected ? +model.is_selected : 0;
        this.is_halt = model.is_halt ? +model.is_halt : 0;
    }

    static parseArray(data) {
        if (!lzIsArray(data)) {
            return [];
        }

        const out = [];

        for (const item of data) {
            out.push(new ProductModel(item));
        }

        return out;
    }

}
