import React from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { connect } from 'react-redux';
import { setErrorMessage, setGuestLoginId, setSuccessMessage } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldSelect from '../../../../../../common/components/commons/fields/select';
import LzOptionModel from '../../../../../../common/models/option';
import { Col, Container, Row } from 'react-bootstrap';
import LzFormFieldTexarea from '../../../../../../common/components/commons/fields/textarea';
import RegisterStepsComponent from './register-steps';
import { lzGetCurrentLocation } from '../../../../../../common/services/LzUtils';
import LzDimmer from '../../../../../../common/components/commons/dimmer';
import LzIcon from '../../../../../../common/components/commons/icon';
import LzGoogleMap from '../../../../../../common/components/commons/google-map';
import RegisterFormMapComponent from './register-form-map';
import LzFormFieldSwitch from '../../../../../../common/components/commons/fields/switch';
import TermsAndConditionsModal from '../../private/help/pop-terms';

const networks = [
  { key: '0', value: '0', text: 'Default' },
  { key: '1', value: '1', text: 'Ufone' },
  { key: '2', value: '2', text: 'Telenor' },
  { key: '3', value: '3', text: 'Mobilink' },
  { key: '4', value: '4', text: 'Warid' },
  { key: '5', value: '5', text: 'Zong' },
];

class RegisterFormComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      xxfullname: '',
      xxdob: '',
      xxphonenum: '',
      xxcellnetwork: '',
      xxusername: '',
      xxpassword: '',
      xxaddress: '',
      xxlatitude: 0,
      xxlongitude: 0,
      xxlatlngerr: '',
      xxzone_id: 0,
      zones: [],
      fieldErrors: {},
      loadAddressCalled: false,
      showSelectionMap: false,
      acceptTerms: false,
      showTerms: false,
    };
  }

  componentDidMount() {
    // Initially load the current location
    lzGetCurrentLocation();

    this.loadZones();
  }

  componentDidUpdate(prevProps) {
    const { xxaddress, loadAddressCalled } = this.state;
    const { currentLocation } = this.props;

    if (currentLocation !== prevProps.currentLocation) {
      if (loadAddressCalled) {
        this.setState({
          xxaddress: '' === xxaddress ? currentLocation?.address || '' : '',
          loadAddressCalled: false,
        });
      }
    }
  }

  async loadZones() {
    const data = await lzCallApi('getZoneList');
    if (data) {
      this.setState({ zones: LzOptionModel.parseArray(data, 'zone_id', 'name') });
    }
  }

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value,                                                            // assign the value
      fieldErrors: Object.assign({}, this.state.fieldErrors, { [name]: '' }),   // clear the error
    });
  };

  onClickNextOnStep1() {
    const { xxfullname, xxzone_id, xxaddress, xxlatitude, xxlongitude } = this.state;

    let isError = false;
    let xxlatlngerr = '';
    const fieldErrorsUpdate = Object.assign({}, this.state.fieldErrors);

    if ('' === xxfullname) {
      fieldErrorsUpdate.xxfullname = 'please provide your fullname...';
      isError = true;
    }

    if (0 === xxzone_id) {
      fieldErrorsUpdate.xxzone_id = 'please select your area...';
      isError = true;
    }

    if ('' === xxaddress) {
      fieldErrorsUpdate.xxaddress = 'please provide your address...';
      isError = true;
    }

    if (0 === xxlatitude || 0 === xxlongitude) {
      xxlatlngerr = 'please select your location...';
      isError = true;
    }

    if (isError) {
      this.setState({ xxlatlngerr: xxlatlngerr, fieldErrors: fieldErrorsUpdate });
      return;
    }

    this.setState({ step: 2 });
  }

  onClickNextOnStep2() {
    const { xxusername, xxpassword, xxphonenum, xxcellnetwork } = this.state;

    let isError = false;
    const fieldErrorsUpdate = Object.assign({}, this.state.fieldErrors);

    if ('' === xxusername) {
      fieldErrorsUpdate.xxusername = 'please provide a unique username...';
      isError = true;
    }

    if ('' === xxpassword) {
      fieldErrorsUpdate.xxpassword = 'please enter your password...';
      isError = true;
    }

    if ('' === xxphonenum) {
      fieldErrorsUpdate.xxphonenum = 'please provide your cell number...';
      isError = true;
    }

    if ('' === xxcellnetwork) {
      fieldErrorsUpdate.xxcellnetwork = 'please select your cell network...';
      isError = true;
    }

    if (isError) {
      this.setState({ fieldErrors: fieldErrorsUpdate });
      return;
    }

    this.callRegisterApi();
  }

  async callRegisterApi() {
    const { guestLoginId, setSuccessMessage, setErrorMessage, setGuestLoginId, history } = this.props;
    const { xxfullname, xxdob, xxphonenum, xxcellnetwork, xxusername, xxpassword, xxaddress, xxlatitude, xxlongitude, xxzone_id } = this.state;

    // Do not allow registeration without selecting a location
    if (0 === xxlatitude || 0 === xxlongitude) {
      setErrorMessage('Please select your location on map to continue registration.');
      return;
    }

    const cellPhoneNo = '+92' + xxphonenum;

    const clientId = guestLoginId || 0;

    // Make the api call and get processed data
    const data = await lzCallApi(clientId > 0 ? 'updateGuestLogin' : 'customerSignUP', {
      client_id: clientId,
      fullName: xxfullname,
      date_of_birth: xxdob,
      cell_no_1: cellPhoneNo,
      network_id: xxcellnetwork,
      userName: xxusername,
      email: xxusername,
      password: xxpassword,
      address: xxaddress,
      latitude: xxlatitude,
      longitude: xxlongitude,
      zone_id: +xxzone_id,
    }, 'post');

    // Check if call was successful
    if (data) {
      /* setSuccessMessage('Your account was successfully created and is under approval! Please verify the code sent via sms to your phone number.');
      lzSaveToLocalStorage('regClientId', +data.client_id);
      lzSaveToLocalStorage('regPhoneNo', cellPhoneNo);
      history.replace('/register-verify'); */
      setGuestLoginId(0);
      setSuccessMessage('Your account is created successfully! Please continue to login.');
      history.replace('/login');
    }
  }

  render() {
    const { step, xxfullname, /*xxdob,*/ xxphonenum, xxcellnetwork, xxusername, xxpassword, xxaddress, showTerms,
      xxlatitude, xxlongitude, xxlatlngerr, xxzone_id, zones, fieldErrors, showSelectionMap, acceptTerms } = this.state;
    const { loading, guestLoginId } = this.props;

    if (loading) {
      return <LzDimmer active={true} />
    }

    return (
      <React.Fragment>

        <RegisterStepsComponent step={step} />

        <Container>
          <LzForm className="form">

            {1 === step ?
              <React.Fragment>

                <LzFormFieldInput fluid required type="text" hint=" " placeholder="Full Name"
                  hasError={!!fieldErrors.xxfullname} errorMessage={fieldErrors.xxfullname}
                  name="xxfullname" value={xxfullname} onChange={this.handleInputChange} />

                {/*<LzFormFieldInput fluid required type="date" hint=" " placeholder="Date of Birth"
                  name="xxdob" value={xxdob} onChange={this.handleInputChange} />*/}

                <LzFormFieldSelect fluid required firstOptionText="Select Area" options={zones}
                  hasError={!!fieldErrors.xxzone_id} errorMessage={fieldErrors.xxzone_id}
                  name="xxzone_id" value={xxzone_id} onChange={this.handleInputChange} />

                <LzFormFieldTexarea fluid hint=" " placeholder="Address"
                  hasError={!!fieldErrors.xxaddress} errorMessage={fieldErrors.xxaddress}
                  name="xxaddress" value={xxaddress} onChange={this.handleInputChange} />

                <LzButton size="sm" label="Load Address by GPS Location" style={{ marginTop: -10 }}
                  onClick={() => this.setState({ loadAddressCalled: true }, lzGetCurrentLocation(false))} />

                <b style={{ position: 'relative', display: 'block', margin: '12px -15px', color: 'gray', textAlign: 'center' }}>
                  {0 === xxlatitude || 0 === xxlongitude ?
                    <React.Fragment>
                      <LzIcon name="map-marker" style={{ marginRight: 6 }} />
                      no location selected!
                      <LzButton size="sm" label="Select" style={{ display: 'inline-block', width: 'auto', padding: '2px 12px', marginTop: 0, marginLeft: 16 }}
                        onClick={() => this.setState({ showSelectionMap: true })}
                      />
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <LzGoogleMap
                        showCenterMarker
                        center={{ lat: xxlatitude, lng: xxlongitude }}
                        style={{ position: 'relative', width: '100%', height: 90, marginBottom: 6 }}
                      />
                      <LzIcon name="map-marker" style={{ marginRight: 6 }} /> {Number(xxlatitude).toFixed(6)}°N - {Number(xxlongitude).toFixed(6)}°E
                      <LzButton size="sm" label="Change" style={{ display: 'inline-block', width: 'auto', padding: '2px 12px', marginTop: 0, marginLeft: 16 }}
                        onClick={() => this.setState({ showSelectionMap: true })}
                      />
                    </React.Fragment>
                  }
                  {xxlatlngerr !== '' && <div style={{ color: 'maroon' }}>{xxlatlngerr}</div>}
                </b>

              </React.Fragment>
              :
              <React.Fragment>

                <LzFormFieldInput fluid required type="email" hint=" " placeholder="Username"
                  hasError={!!fieldErrors.xxusername} errorMessage={fieldErrors.xxusername}
                  name="xxusername" value={xxusername} onChange={this.handleInputChange} />

                <LzFormFieldInput fluid required type="password" hint=" " placeholder="Password"
                  hasError={!!fieldErrors.xxpassword} errorMessage={fieldErrors.xxpassword}
                  name="xxpassword" value={xxpassword} onChange={this.handleInputChange} />

                <Row noGutters>
                  <Col xs={3} style={{ paddingRight: 5 }}>
                    <LzFormFieldInput fluid type="text" hint=" " placeholder="" disabled
                      name="xxphonenum_country_code" value='+92' />
                  </Col>
                  <Col xs={9}>
                    <LzFormFieldInput fluid required type="number" hint=" " placeholder="xxx xxx xxxx"
                      hasError={!!fieldErrors.xxphonenum} errorMessage={fieldErrors.xxphonenum}
                      name="xxphonenum" value={xxphonenum} onChange={this.handleInputChange} />
                  </Col>
                </Row>

                <LzFormFieldSelect fluid required firstOptionText="Select Network" options={networks}
                  hasError={!!fieldErrors.xxcellnetwork} errorMessage={fieldErrors.xxcellnetwork}
                  name="xxcellnetwork" value={xxcellnetwork} onChange={this.handleInputChange} />

              </React.Fragment>
            }

          </LzForm>
        </Container>

        <div className={guestLoginId > 0 ? 'bot-wizard-button-modal' : 'bot-wizard-buttons'}>
          {1 === step ?
            <React.Fragment>
              <Link to={'/login'}><LzButton variant="outline-primary" icon="" label="Back" /></Link>
              <LzButton disabled={!acceptTerms} variant="primary" icon="" label="Next" onClick={() => this.onClickNextOnStep1()} />
            </React.Fragment>
            :
            <React.Fragment>
              <LzButton variant="outline-primary" icon="" label="Back" onClick={() => this.setState({ step: 1 })} />
              <LzButton variant="primary" icon="" label="Submit" onClick={() => this.onClickNextOnStep2()} />
            </React.Fragment>
          }
        </div>

        {showSelectionMap &&
          <RegisterFormMapComponent
            center={{ lat: xxlatitude, lng: xxlongitude }}
            onChange={(lat, lng) => this.setState({ xxlatitude: lat, xxlongitude: lng, xxlatlngerr: '', showSelectionMap: false })}
            onClose={() => this.setState({ showSelectionMap: false })}
          />
        }

        <LzFormFieldSwitch
          asSwitch
          size="xs"
          align="center"
          label={<span>I agree to the <span
            onClick={() => this.setState({ showTerms: true })}
            style={{ textDecoration: 'underline', color: 'green' }}
          >Terms & Conditions</span></span>}
          name="acceptTerms"
          value={acceptTerms}
          onChange={this.handleInputChange}
        />

        <TermsAndConditionsModal
          show={showTerms}
          onHide={() => this.setState({ showTerms: false })}
          onAccept={() => this.setState({ acceptTerms: true, showTerms: false })}
          onReject={() => this.setState({ acceptTerms: false, showTerms: false })}
        />

      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    loading: state.commons.loading,
    currentLocation: state.commons.currentLocation,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    setErrorMessage: (value) => dispatch(setErrorMessage(value)),
    setGuestLoginId: (value) => dispatch(setGuestLoginId(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterFormComponent));
