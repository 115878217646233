import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { lzGetFormattedDateTime, lzGetMonthEndDate, lzGetMonthStartDate } from '../../../../../../common/services/LzMoment';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { Col, Container, Row, Table } from 'react-bootstrap';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';

class DeliveriesHistoryScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            startDate: lzGetMonthStartDate('YYYY-MM-DD'),
            endDate: lzGetMonthEndDate('YYYY-MM-DD'),
        };
    }

    componentDidMount() {
        this.loadHistory();
    }

    async loadHistory() {
        const { startDate, endDate } = this.state;
        const { authUser } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('deliverybetweenDateRange', {
            client_id: authUser.client_id,
            start_date: startDate,
            end_date: endDate,
        });

        // Load data if call was successful
        if (data) {
            this.setState({ records: data });
        }
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { startDate, endDate, records } = this.state;

        return (
            <PrivateLayout screenTitle="Delivery History">

                <div className="heading-bar">
                    <Container>
                        <Row noGutters>
                            <Col xs={5}>
                                <LzFormFieldInput fluid inline type="date"
                                    name="startDate" value={startDate} onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col xs={5}>
                                <LzFormFieldInput fluid inline type="date"
                                    name="endDate" value={endDate} onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col xs={2}>
                                <LzButton fluid variant="link" type="button" onClick={() => this.loadHistory()}><LzIcon name="refresh" /></LzButton>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Datetime</th>
                                <th>Qty</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((rec, dex) =>
                                <tr key={'deliv-' + dex}>
                                    <td>{rec.product_name}</td>
                                    <td>{lzGetFormattedDateTime(rec.date + ' ' + rec.time)}</td>
                                    <td>{rec.quantity + ' ' + rec.unit}</td>
                                    <td>{lzGetFormattedAmount(rec.amount, true)}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Container>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(DeliveriesHistoryScreen);
