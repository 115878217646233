import React from 'react';
import PublicLayout from '../../../../../../common/components/layouts/public';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import UserModel from '../../../../../../common/models/user';
import { connect } from 'react-redux';
import { setAuthToken, setAuthUser } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import SocialLinksComponent from '../../../commons/social-links';
import LzIcon from '../../../../../../common/components/commons/icon';

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xxusername: '',
            xxpassword: '',
            remember: true, // to save credentials by default and avoid login every time
        };
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onSubmit() {
        this.callLoginApi();
    }

    async callLoginApi() {
        const { setAuthUser, setAuthToken, history } = this.props;
        const { xxusername, xxpassword, remember } = this.state;

        // Make the api call and get processed data
        const data = await lzCallApi('Login', {
            userName: xxusername,
            password: xxpassword,
        }, 'post');

        // Validate data and use it
        if (data && data.client_id) {
            // Create new auth user object
            const user = new UserModel(data);

            // Set to store (and local storage if opted to)
            setAuthUser(user, remember);
            setAuthToken(data.client_id, remember);

            console.log('REDIRECTING...');
            history.replace('/');
            // used replace instead of push to remove login from browser history
            // and make it go to exit if back pressed
        }
    }

    render() {
        const { xxusername, xxpassword } = this.state;

        return (
            <PublicLayout>
                <div className="mk-screen-dark-form">
                    <Link to="/landing"><LzIcon source="mk-svg" name="arrow-left" /></Link>
                    <h1>Welcome Back!</h1>
                    <div>
                        <LzForm className="form" onSubmit={() => this.onSubmit()}>

                            <h2>SIGN IN</h2>

                            <LzFormFieldInput fluid type="text" label="Email"
                                name="xxusername" value={xxusername} onChange={this.handleInputChange} />

                            <LzFormFieldInput fluid type="password" label="Password"
                                name="xxpassword" value={xxpassword} onChange={this.handleInputChange} />

                            <div className="forgot">
                                <Link to={'/forgot-password'}>Forgot password?</Link>
                            </div>

                            <LzButton type="submit" variant="primary" iconSource="mk-svg" icon="arrow-right" label=" " />

                        </LzForm>
                    </div>
                    <SocialLinksComponent />
                </div>
            </PublicLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
        setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(LoginScreen);
