import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import SelectProductComponent from '../../../../../../common/components/commons/select-product';
import { setProduct } from '../../../../../../common/redux/actions';

class UpdatePlanProductsScreen extends React.Component {

    selectProduct(product) {
        const { setProduct, history } = this.props;

        setProduct(product);

        if (product) {
            history.push('/plan-select-type');
        }
    }

    render() {
        return (
            <PrivateLayout screenTitle="Update Plan">

                <SelectProductComponent onSelect={(product) => this.selectProduct(product)} />

            </PrivateLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setProduct: (value) => dispatch(setProduct(value)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(UpdatePlanProductsScreen));
