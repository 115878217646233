import React from 'react';
import PublicLayout from '../../../../../../common/components/layouts/public';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import UserModel from '../../../../../../common/models/user';
import { connect } from 'react-redux';
import { setAuthToken, setAuthUser } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xxusername: '',
            xxpassword: '',
            remember: true, // to save credentials by default and avoid login every time
        };
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onSubmit() {
        this.callLoginApi();
    }

    async callLoginApi() {
        const { setAuthUser, setAuthToken, history } = this.props;
        const { xxusername, xxpassword, remember } = this.state;

        // Make the api call and get processed data
        const data = await lzCallApi('Login', {
            userName: xxusername,
            password: xxpassword,
        }, 'post');

        // Validate data and use it
        if (data && data.client_id) {
            // Create new auth user object
            const user = new UserModel(data);

            // Set to store (and local storage if opted to)
            setAuthUser(user, remember);
            setAuthToken(data.client_id, remember);

            console.log('REDIRECTING...');
            history.replace('/');
            // used replace instead of push to remove login from browser history
            // and make it go to exit if back pressed
        }
    }

    render() {
        const { xxusername, xxpassword } = this.state;

        return (
            <PublicLayout>
                <div className="lz-form-page">

                    <div className="ndf-logo"></div>

                    <h1>Sign In</h1>

                    <LzForm className="form" onSubmit={() => this.onSubmit()}>
                        <LzFormFieldInput fluid type="text" placeholder="Username" hint=" "
                            name="xxusername" value={xxusername} onChange={this.handleInputChange} />
                        <LzFormFieldInput fluid type="password" placeholder="Password" hint=" "
                            name="xxpassword" value={xxpassword} onChange={this.handleInputChange} />
                        <div className="forgot">
                            <Link to={'/forgot-password'}>Forgot password?</Link>
                        </div>
                    </LzForm>

                    <div>
                        <LzButton type="button" variant="primary" label="Sign In" onClick={() => this.onSubmit()} />
                        <Link className="register" to={'/register'}>New Member? Create Account</Link>
                    </div>

                </div>
            </PublicLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
        setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(LoginScreen);
