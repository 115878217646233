import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { setAuthLoaded, setAuthToken, setAuthUser } from '../../../../redux/actions';
import { lzRemoveFromLocalStorage } from '../../../../services/LzLocalStorage';
import { removeFCMRegisterToken } from '../../../../services/LzFirebaseCloudMessaging';

class LogoutScreen extends React.Component {

  componentDidMount() {
    // Call server to clear FCM token if exists (call before clearing auth token)
    removeFCMRegisterToken();

    // Clear from store and force clear from local storage to remove "Remember me!" setting
    this.props.setAuthUser(null, true);
    this.props.setAuthToken('', true);
    this.props.setAuthLoaded(false);

    lzRemoveFromLocalStorage('companyId', true);

    this.props.history.replace('/landing');
  }

  render() {
    return <span>..redirecting...</span>;
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
    setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    setAuthLoaded: (value) => dispatch(setAuthLoaded(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LogoutScreen));
