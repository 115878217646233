import React from 'react';
import { Modal } from 'react-bootstrap';
import LzButton from '../../../../../../common/components/commons/button';
import RegisterFormComponent from './register-form';

class GuestContinueSignUpComponent extends React.Component {

  render() {
    const { customerId, onClose } = this.props;

    return (
      <React.Fragment>

        <Modal centered show={true} onHide={() => onClose()}>
          <Modal.Header closeButton><Modal.Title>Continue Guest SignUp</Modal.Title></Modal.Header>
          <Modal.Body>

            <RegisterFormComponent guestLoginId={customerId} />

          </Modal.Body>
          <Modal.Footer>
            <LzButton label="Close" variant="secondary" onClick={() => onClose()} />
          </Modal.Footer>
        </Modal>

      </React.Fragment>
    );
  }

}

export default GuestContinueSignUpComponent;
