import React from 'react';
import Button from 'react-bootstrap/Button';
import LzIcon from '../commons/icon';

class LzButton extends React.Component {

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { fluid, disabled, variant, color, size, icon, iconSource, iconRounded, className, style, label, type, children } = this.props;

        return (
            <Button
                type={type || 'button'}
                variant={variant || 'primary'}
                disabled={disabled}
                size={size}
                style={style}
                className={
                    'lz-button' +
                    (fluid ? ' lz-fluid' : '') +
                    (color ? ' lz-' + color : '') +
                    (className ? ' ' + className : '')
                }
                onClick={() => this.onClick()}
            >
                {icon && <LzIcon source={iconSource} name={icon} rounded={iconRounded} style={{ marginRight: 5 }} />}
                {' '}
                {children}
                {label}
            </Button>
        );
    }

}

export default LzButton;
