import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { setSideNav } from '../../../../../common/redux/actions';
import LzIcon from '../../../../../common/components/commons/icon';

class LzSideNav extends React.Component {

    render() {
        const { setSideNav, sidenavOpen, style, textAlign, headerClose, headerOpen, items, footer } = this.props;

        const header = headerOpen ? headerOpen : headerClose;

        const styles = style || { textAlign: "left" };
        if (style && style.textAlign) {
            styles.textAlign = textAlign;
        }

        // console.log('SIDENAV-ITEMS', items);

        return (
            <React.Fragment>
                <div className={'lz-sidenav-backdrop' + (sidenavOpen ? ' active' : '')} onClick={() => setSideNav(false)}></div>
                <div
                    className={'lz-sidenav' + (sidenavOpen ? ' lz-sidenav-open' : '')}
                    style={styles}
                >
                    {header &&
                        <div className="lz-sidenav-header">
                            {sidenavOpen ? headerOpen : headerClose}
                        </div>
                    }

                    {(items && items.length > 0) &&
                        <div className="lz-sidenav-items">
                            {items.map((item, dex) =>
                                <div
                                    key={'sidenavItem_' + dex}
                                >
                                    <React.Fragment>
                                        {(item.items && item.items.length > 0)
                                            ?
                                            <DropdownButton
                                                className="lz-sidenav-item"
                                                drop="right"
                                                title={
                                                    <div>
                                                        {item.icon && <LzIcon name={item.icon} />}
                                                        {sidenavOpen ? item.title : ''}
                                                        {sidenavOpen ? <LzIcon placeTopRight muted name="angle-right" /> : ''}
                                                    </div>
                                                }
                                            >
                                                {item.items.map((sitem, sdex) =>
                                                    <React.Fragment key={'snSubKey' + dex + '-' + sdex}>
                                                        {'-' === sitem.title
                                                            ?
                                                            <Dropdown.Divider />
                                                            :
                                                            <Dropdown.Item as={Link} to={sitem.route} onClick={() => setSideNav(false)}>
                                                                {sitem.icon && <LzIcon name={sitem.icon} />}
                                                                {sitem.title}
                                                            </Dropdown.Item>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </DropdownButton>
                                            :
                                            <Link to={item.route} onClick={() => setSideNav(false)}>
                                                <div className="lz-sidenav-item">
                                                    {item.icon && <LzIcon name={item.icon} />}
                                                    {sidenavOpen ? item.title : ''}
                                                </div>
                                            </Link>
                                        }
                                    </React.Fragment>
                                </div>
                            )}
                        </div>
                    }

                    {footer &&
                        <div className="lz-sidenav-footer">
                            {footer}
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        sidenavOpen: state.layout.sidenavOpen,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSideNav: (value) => dispatch(setSideNav(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LzSideNav));
