import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { Alert, Container } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';

class UpdatePlanIntervalScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {},
        };
    }

    componentDidMount() {
        this.loadIntervalPlan();
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    async loadIntervalPlan() {
        const { authUser, product, history, setErrorMessage } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get the data
        const data = await lzCallApi('getCustomerIntervalSchedulerStatus', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Load data if call was successful
        if (data && data[0]) {
            this.setState({
                model: {
                    fromDate: data[0].start_interval_scheduler,
                    quantity: data[0].product_quantity,
                    intervalDays: data[0].interval_days,
                },
            });
        }
    }

    async saveIntervalPlan() {
        const { model } = this.state;
        const { product, authUser, setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('SaveIntervalScheduler', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            product_quantity: model.quantity,
            interval_days: model.intervalDays,
            start_interval_scheduler: model.fromDate,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully saved!');
        }
    }

    async cancelPlan() {
        const { authUser, product, setSuccessMessage, history } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('CancelRegularOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully cancelled!');
            history.push('/plan-select-type');
        }
    }

    render() {
        const { model } = this.state;

        return (
            <PrivateLayout screenTitle="Interval Plan">

                <div className="lz-form-page lz-plan-form">

                    <Container>

                        <LzForm onSubmit={() => this.saveIntervalPlan()}>

                            <LzFormFieldInput required fluid type="date" label="Start From"
                                placeholder="enter a future date to schedule a delivery in future"
                                name="fromDate" value={model.fromDate} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid type="number" label="Quantity"
                                name="quantity" value={model.quantity} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid type="number" label="Interval Days"
                                name="intervalDays" value={model.intervalDays} onChange={this.handleModelChange} />

                            <Alert variant="warning">
                                <LzIcon name="warning" /> Your current plan (if any) will be cancelled and a new plan will start from the date you enter above.
                            </Alert>

                            <LzButton fluid type="submit" icon="save" label="Save Plan" />

                            <LzButton fluid type="button" variant="danger" icon="times" label="Cancel/Change Plan" onClick={() => this.cancelPlan()} />

                            <Link to="/plan-interval-halt"><LzButton fluid type="button" variant="danger" icon="exclamation-circle" label="Halt Plan" /></Link>

                            <Link to="/plan-interval-onetime"><LzButton fluid type="button" variant="danger" icon="plus" label="Place One-Time Order" /></Link>

                        </LzForm>

                    </Container>

                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanIntervalScreen));
