import { actions } from '../actions';

const defaultState = {
    loading: false,
    errorMessage: '',
    successMessage: '',
    lastRoute: '',
    currentLocation: null,
};

const commons = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SET_LOADING:
            return Object.assign({}, state, { loading: action.value });

        case actions.SET_ERROR_MESSAGE:
            console.log('SETTING_ERROR_MESSAGE', action.value);
            return Object.assign({}, state, { errorMessage: action.value });

        case actions.SET_SUCCESS_MESSAGE:
            console.log('SETTING_SUCCESS_MESSAGE', action.value);
            return Object.assign({}, state, { successMessage: action.value });

        case actions.SET_LAST_ROUTE:
            console.log('SETTING_LAST_ROUTE', action.value);
            return Object.assign({}, state, { lastRoute: action.value });

        case actions.SET_CURRENT_LOCATION:
            console.log('SETTING_CURRENT_LOCATION', action.value);
            return Object.assign({}, state, { currentLocation: action.value });

        default:
            return state;

    }

};

export default commons;
