import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { lzGetFormattedDate, lzGetMonthEndDate, lzGetMonthStartDate, lzGetWeekEndDate, lzGetWeekStartDate, LZ_DATE_FIELD_FORMAT } from '../../../../../../common/services/LzMoment';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import PlansCalendarMonthView from './calendar-month-view';
import PlanModel from '../../../../../../common/models/plan';
import LzButton from '../../../../../../common/components/commons/button';
import LzSpinnerComponent from '../../../commons/spinner';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../../commons/loading';
import PlansCalendarWeekView from './calendar-week-view';
import PlansCalendarDayView from './calendar-day-view';

class PlansCalendarScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currView: 'month',
            currDate: lzGetFormattedDate('', LZ_DATE_FIELD_FORMAT),
            startDate: lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT),
            endDate: lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT),
            plan: null,
            selAct: '',
            selDts: [],
            selQty: 1,
        };
    }

    componentDidMount() {
        this.load();
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onViewChange(view) {
        const { currDate } = this.state;

        // Assume day view for initialization
        let startDate = currDate;
        let endDate = currDate;

        if ('month' === view) {
            startDate = lzGetMonthStartDate(LZ_DATE_FIELD_FORMAT, currDate);
            endDate = lzGetMonthEndDate(LZ_DATE_FIELD_FORMAT, currDate);
        } else if ('week' === view) {
            startDate = lzGetWeekStartDate(LZ_DATE_FIELD_FORMAT, currDate);
            endDate = lzGetWeekEndDate(LZ_DATE_FIELD_FORMAT, currDate);
        }

        this.setState({
            currView: view,
            startDate: startDate,
            endDate: endDate,
        }, () => this.load());
    }

    async load() {
        const { startDate, endDate } = this.state;
        const { authUser, product } = this.props;

        if (!product) {
            console.log('skipping plan loading because no product...');
            return;
        }

        // Make the api call and get the data
        const data = await lzCallApi('getPlanData', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            start_date: startDate,
            end_date: endDate,
        });

        // Load data if call was successful
        if (data) {
            this.setState({ plan: new PlanModel(data) });
        }
    }

    onChangeDates(currDate, startDate, endDate) {
        this.setState({
            currDate: currDate,
            startDate: startDate,
            endDate: endDate,
        },
            () => this.load()
        );
    }

    onChangeSelections(act, data) {
        this.setState({ selAct: act, selDts: data });
    }

    onSaveSelections() {
        const { selAct } = this.state;

        if ('halt' === selAct) {
            this.saveHalted();
        }

        if ('special' === selAct) {
            this.saveSpecial();
        }
    }

    async saveHalted() {
        const { startDate, endDate, selDts } = this.state;
        const { authUser, product } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('saveHaltedDates', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            start_date: startDate,
            end_date: endDate,
            dates: selDts,
        });

        // Load data if call was successful
        if (data) {
            this.setState({ plan: new PlanModel(data), selAct: '', selDts: [] });
        }
    }

    async saveSpecial() {
        const { startDate, endDate, selDts, selQty } = this.state;
        const { authUser, product } = this.props;

        const order = {};
        for (const dt of selDts) {
            order[dt] = selQty;
        }

        // Make the api call and get the data
        const data = await lzCallApi('saveSpecialOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            start_date: startDate,
            end_date: endDate,
            order: order,
        });

        // Load data if call was successful
        if (data) {
            this.setState({ plan: new PlanModel(data), selAct: '', selDts: [], selQty: 1 });
        }
    }

    render() {
        const { product } = this.props;
        const { currView, currDate, startDate, endDate, plan, selAct, selDts, selQty } = this.state;

        if (!product) {
            return <Redirect to="/plan" />;
        }

        if (!plan) {
            return <LoadingComponent />
        }

        console.log('PLAN', plan);

        return (
            <PrivateLayout layout="plan" screenTitle="Plans Calendar" activeTabKey="plan">

                {plan ?
                    <div style={{
                        position: 'absolute',
                        top: 12,
                        left: 12,
                        bottom: 12,
                        right: 12,
                        background: 'white',
                        borderRadius: 24,
                        overflow: 'auto',
                    }}>

                        <Tabs defaultActiveKey={currView} onSelect={(key) => this.onViewChange(key)}>
                            <Tab eventKey="month" tabClassName="tabbed-tab" title="Month" disabled={selAct !== ''}>
                                <PlansCalendarMonthView
                                    currDate={currDate} startDate={startDate} endDate={endDate}
                                    onChangeDates={(currDate, startDate, endDate) => this.onChangeDates(currDate, startDate, endDate)}
                                    selectionsAction={selAct} selectionsDates={selDts}
                                    onChangeSelections={(act, data) => this.onChangeSelections(act, data)}
                                    product={product} plan={plan}
                                />
                            </Tab>
                            <Tab eventKey="week" tabClassName="tabbed-tab" title="Week" disabled={selAct !== ''}>
                                <PlansCalendarWeekView
                                    currDate={currDate} startDate={startDate} endDate={endDate}
                                    onChangeDates={(currDate, startDate, endDate) => this.onChangeDates(currDate, startDate, endDate)}
                                    selectionsAction={selAct} selectionsDates={selDts}
                                    onChangeSelections={(act, data) => this.onChangeSelections(act, data)}
                                    product={product} plan={plan}
                                />
                            </Tab>
                            <Tab eventKey="day" tabClassName="tabbed-tab" title="Day" disabled={selAct !== ''}>
                                <PlansCalendarDayView
                                    currDate={currDate} startDate={startDate} endDate={endDate}
                                    onChangeDates={(currDate, startDate, endDate) => this.onChangeDates(currDate, startDate, endDate)}
                                    selectionsAction={selAct} selectionsDates={selDts}
                                    onChangeSelections={(act, data) => this.onChangeSelections(act, data)}
                                    product={product} plan={plan}
                                />
                            </Tab>
                        </Tabs>

                        {'' === selAct ?
                            <Container>
                                {'weekly' === plan.type &&
                                    <Row noGutters className="legend-wrapper">
                                        <Col xs={5} className="label">Current Plan: <b>Weekly</b></Col>
                                        <Col xs={2}><Link to="/plan-weekly"><LzButton fluid size="sm" label="Edit" /></Link></Col>
                                        <Col xs={5}><Link to="/plan-interval"><LzButton fluid size="sm" label="Switch to Interval" /></Link></Col>
                                    </Row>
                                }
                                {'interval' === plan.type &&
                                    <Row noGutters className="legend-wrapper">
                                        <Col xs={5} className="label">Current Plan: <b>Interval</b></Col>
                                        <Col xs={2}><Link to="/plan-interval"><LzButton fluid size="sm" label="Edit" /></Link></Col>
                                        <Col xs={5}><Link to="/plan-weekly"><LzButton fluid size="sm" label="Switch to Weekly" /></Link></Col>
                                    </Row>
                                }
                                <Row noGutters className="legend-wrapper">
                                    <Col xs={1}><div className="lz-legend lz-danger-light"></div></Col>
                                    <Col xs={8} className="legend-label">days with order halted</Col>
                                    <Col xs={3}><LzButton fluid size="sm" label="Edit" onClick={() => this.onChangeSelections('halt', plan.halted)} /></Col>
                                </Row>
                                <Row noGutters className="legend-wrapper">
                                    <Col xs={1}><div className="lz-legend lz-info-lighter"></div></Col>
                                    <Col xs={8} className="legend-label">days having special order</Col>
                                    <Col xs={3}><LzButton fluid size="sm" label="Edit" onClick={() => this.onChangeSelections('special', Object.keys(plan.special))} /></Col>
                                </Row>
                                <Row noGutters className="legend-wrapper">
                                    <Col xs={1}><div className="lz-legend lz-warning-light"></div></Col>
                                    <Col xs={11} className="legend-label">days with order already delivered</Col>
                                </Row>
                                <Row noGutters className="legend-wrapper">
                                    <Col xs={1}><div className="lz-legend lz-success-lighter"></div></Col>
                                    <Col xs={11} className="legend-label">days with delivery planned</Col>
                                </Row>
                            </Container>
                            :
                            <Container>
                                <div style={{ textAlign: 'center', color: 'red', fontSize: 11 }}>select the days in the calendar view and click save</div>
                                {'special' == selAct &&
                                    <Row style={{ marginTop: 12 }}>
                                        <Col style={{ color: 'black', lineHeight: 2, textAlign: 'right' }}>Qty in {product.unit} :</Col>
                                        <Col>
                                            <input type="number" value={selQty} onChange={(ev) => this.setState({ selQty: +ev.target.value })}
                                                style={{ border: '2px solid gray', borderRadius: 12, width: '70%', padding: '3px 12px' }} />
                                        </Col>
                                    </Row>
                                }
                                <Row noGutters style={{ marginTop: 12 }}>
                                    <Col><LzButton fluid icon="save" label="Save" color="blue" onClick={() => this.onSaveSelections()} /></Col>
                                    <Col><LzButton fluid icon="times" label="Cancel" variant="secondary" onClick={() => this.onChangeSelections('', [])} /></Col>
                                </Row>
                            </Container>
                        }

                    </div>
                    :
                    <LzSpinnerComponent />
                }

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

export default connect(
    mapStateToProps
)(PlansCalendarScreen);
