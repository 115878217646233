import React from 'react';
import PrivateLayout from '../../../layouts/private';

class ContactUsScreen extends React.Component {

  render() {
    const ifUrl = 'https://dairydelivery.conformiz.com/MobileAppDocs/ContactUs';

    return (
      <PrivateLayout hideBalanceBox screenTitle="Contact Us">

        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 0,
            scrollbarWidth: 5,
          }}
          src={ifUrl}
        ></iframe>

      </PrivateLayout>
    );
  }

}

export default ContactUsScreen;
