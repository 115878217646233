import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzButton from '../../../commons/button';
import LzFormFieldInput from '../../../commons/fields/input';
import LzForm from '../../../commons/form';
import PublicLayout from '../../../layouts/public';

class ForgotPasswordScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      xxusername: '',
      xxphonenum: '',
    };
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit() {
    this.callForgotPwdApi();
  }

  async callForgotPwdApi() {

  }

  render() {
    const { xxusername, xxphonenum } = this.state;

    return (
      <PublicLayout>
        <div className="lz-form-page">
          <div className="logo"></div>
          <div className="slogan"></div>
          <Container>
            <LzForm className="form" onSubmit={() => this.onSubmit()}>

              <LzFormFieldInput required fluid disableAutocomplete type="text"
                label="" placeholder="username" hint=" "
                name="xxusername" value={xxusername}
                onChange={this.handleInputChange}
              />

              <LzFormFieldInput fluid required type="text" hint=" " placeholder="Phone No."
                name="xxphonenum" value={xxphonenum} onChange={this.handleInputChange} />

              <LzButton fluid type="submit" variant="primary"
                icon="send" label="Send Reset Code"
              />

            </LzForm>

            <div style={{ textAlign: "center" }}>
              <Link to={'/login'}>Ready to login? click here</Link>
            </div>
          </Container>
        </div>
      </PublicLayout>
    );
  }

}

export default ForgotPasswordScreen;
