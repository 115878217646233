import React from 'react';
import Container from 'react-bootstrap/Container';
import PublicLayout from '../../../layouts/public';
import ImgLogo from '../../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../services/LzApiService';
import UserModel from '../../../../models/user';
import { connect } from 'react-redux';
import { setAuthToken, setAuthUser } from '../../../../redux/actions';
import LzForm from '../../../commons/form';
import LzFormFieldInput from '../../../commons/fields/input';
import LzFormFieldSwitch from '../../../commons/fields/switch';
import LzButton from '../../../commons/button';

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            remember: true, // to save credentials by default and avoid login every time
        };
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onSubmit() {
        this.callLoginApi();
    }

    async callLoginApi() {
        const { setAuthUser, setAuthToken, history } = this.props;
        const { username, password, remember } = this.state;

        // Make the api call and get processed data
        const data = await lzCallApi('login', {
            userName: username,
            password: password,
        }, 'post');

        // Validate data and use it
        if (data && data.client_id) {
            // Create new auth user object
            const user = new UserModel(data);

            // Set to store (and local storage if opted to)
            setAuthUser(user, remember);
            setAuthToken(data.client_id, remember);

            console.log('REDIRECTING...');
            history.replace('/');
            // used replace instead of push to remove login from browser history
            // and make it go to exit if back pressed
        }
    }

    render() {
        const { username, password, remember } = this.state;

        return (
            <PublicLayout>
                <div className="lz-form-page">
                    <img src={ImgLogo} alt="logo" className="logo" />
                    <Container>
                        <LzForm className="form" onSubmit={() => this.onSubmit()}>

                            <LzFormFieldInput required fluid type="email"
                                icon="envelope" label="Email Addresss" placeholder="enter your email address"
                                name="username" value={username}
                                onChange={this.handleInputChange}
                            />

                            <LzFormFieldInput required fluid type="password"
                                icon="lock" label="Password" placeholder="enter your password"
                                name="password" value={password}
                                onChange={this.handleInputChange}
                            />

                            <LzFormFieldSwitch
                                label="Keep me logged in"
                                name="remember"
                                value={remember}
                                onChange={this.handleInputChange}
                            />

                            <LzButton fluid type="submit" variant="primary"
                                icon="sign-in" label="Sign In"
                            />

                        </LzForm>

                        <Link to={'/forgot-password'}>forgot password? click here</Link>
                    </Container>
                </div>
            </PublicLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
        setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(LoginScreen);
