import { lzIsArray } from "../services/LzType";

export default class UserModel {

    constructor(model) {
        if (!model) {
            model = {};
        }

        this.client_id = model.client_id ? +model.client_id : 0;
        this.fullname = model.fullname ? model.fullname : '';
        this.email = model.email ? model.email : '';
        this.cell_no_1 = model.cell_no_1 ? model.cell_no_1 : '';
        this.cnic = model.cnic && model.cnic !== '0' ? model.cnic : '';
        this.address = model.address && model.address !== 'no' ? model.address : '';
        this.rider_name = model.rider_name ? model.rider_name : '';
        this.rider_phoneNumber = model.rider_phoneNumber ? model.rider_phoneNumber : '';
        this.total_delivery = model.total_delivery ? +model.total_delivery : 0;
        this.zone_id = model.zone_id ? +model.zone_id : 0;
        this.zone_name = model.zone_name ? model.zone_name : '';
    }

    static parseArray(data) {
        if (!lzIsArray(data)) {
            return [];
        }

        const out = [];
        for (const item of data) {
            out.push(new UserModel(item));
        }

        return out;
    }

}
