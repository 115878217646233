import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import PublicLayout from "../../../../../../common/components/layouts/public";
import UserModel from '../../../../../../common/models/user';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import { setAuthToken, setAuthUser, setErrorMessage } from '../../../../../../common/redux/actions';
import LzOptionModel from '../../../../../../common/models/option';
import { CompanyConstants } from '../../../../Constants';
import { AppConstants } from '../../../../../../common/services/Constants';
import { lzSaveToLocalStorage } from '../../../../../../common/services/LzLocalStorage';

class LoginScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      xxcompany_id: 18,
      xxusername: '',
      xxpassword: '',
      remember: true, // to save credentials by default and avoid login every time
    };

    this.companies = LzOptionModel.parseArray(CompanyConstants.companies, 'id', 'name');
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit() {
    this.callLoginApi();
  }

  async callLoginApi() {
    const { setAuthUser, setAuthToken, setErrorMessage, history } = this.props;
    const { xxcompany_id, xxusername, xxpassword, remember } = this.state;

    if (0 === xxcompany_id) {
      setErrorMessage('Please select a franchise first!');
      return;
    }

    AppConstants.app_company_id = xxcompany_id;
    lzSaveToLocalStorage('companyId', xxcompany_id, true);

    // Make the api call and get processed data
    const data = await lzCallApi('login', {
      userName: xxusername,
      password: xxpassword,
    }, 'post');

    // Validate data and use it
    if (data && data.client_id) {
      // Create new auth user object
      const user = new UserModel(data);

      // Set to store (and local storage if opted to)
      setAuthUser(user, remember);
      setAuthToken(data.client_id, remember);

      // Save at the time of login that which company was selected, so that on refresh we can load it.
      // The 3rd argument tells to save as base company data so that we can load later when we dont know the company id.
      lzSaveToLocalStorage('companyId', +data.company_branch_id, true);

      console.log('REDIRECTING...');
      history.replace('/');
      // used replace instead of push to remove login from browser history
      // and make it go to exit if back pressed
    }
  }

  render() {
    const { xxusername, xxpassword } = this.state;

    return (
      <PublicLayout>
        <div className="lz-form-page" style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'space-around',
        }}>
          <div className="logo"></div>
          {/*<div className="slogan"></div>*/}
          <Container>
            <LzForm className="form" onSubmit={() => this.onSubmit()}>

              {/*<LzFormFieldSelect fluid required firstOptionText="Select Franchise..." options={this.companies}
                                name="xxcompany_id" value={xxcompany_id} onChange={this.handleInputChange} />*/}

              <LzFormFieldInput required fluid disableAutocomplete type="text"
                label="" placeholder="username" hint=" "
                name="xxusername" value={xxusername}
                onChange={this.handleInputChange}
              />

              <LzFormFieldInput required fluid disableAutocomplete type="password"
                label="" placeholder="password" hint=" "
                name="xxpassword" value={xxpassword}
                onChange={this.handleInputChange}
              />

              <LzButton fluid type="submit" variant="primary"
                icon="" label="Login"
              />

            </LzForm>

            <div style={{ textAlign: "center" }}>
              <Link to={'/forgot-password'}>forgot password? click here</Link>
              <div style={{ height: 8 }}></div>
              <Link to={'/register'}>not registered? click here</Link>
            </div>
          </Container>
          <div style={{ textAlign: "center" }}>
            <a href="http://www.conformiz.com">Powered by Conformiz</a>
          </div>
        </div>
      </PublicLayout>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
    setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    setErrorMessage: (value) => dispatch(setErrorMessage(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginScreen);
