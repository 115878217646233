import React from 'react';
import PublicLayout from '../../../../../../common/components/layouts/public';
import { Link } from 'react-router-dom';
import SocialLinksComponent from '../../../commons/social-links';
import ImgLogo from '../../../../assets/images/logo.png';
import { lzGetAssetUrl } from '../../../../../../common/services/LzUtils';

class LandingScreen extends React.Component {

    render() {
        return (
            <PublicLayout>
                <div className="mk-screen-landing">
                    <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' className='logo' />
                    <h1>Hello!</h1>
                    <div>
                        <Link to="/register" >
                            <div className="mk-leftbox">
                                <h2>SIGN UP</h2>
                                <p>
                                    New here?<br />
                                    <br />
                                    Sign up to gain access to this amazing app
                                </p>
                            </div>
                        </Link>
                        <Link to="/login" >
                            <div className="mk-ritebox">
                                <h2>SIGN IN</h2>
                                <p>
                                    Returning<br />
                                    <br />
                                    Just sign in to resume what you were doing
                                </p>
                            </div>
                        </Link>
                    </div>
                    <SocialLinksComponent />
                </div>
            </PublicLayout>
        );
    }

}

export default LandingScreen;
