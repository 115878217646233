import React from 'react';

class SocialLinksComponent extends React.Component {

    render() {
        return (
            <div>
                
            </div>
        );
    }

}

export default SocialLinksComponent;
