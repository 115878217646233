import React from 'react';
import PrivateLayout from '../../../layouts/private';
import { Container } from 'react-bootstrap';

class SupportInfoScreen extends React.Component {

    render() {
        return (
            <PrivateLayout screenTitle="Support Info">

                <Container>

                    <h3 style={{ textAlign: "center", fontWeight: "bold", color: "black" }}>
                        <br />
                        <br />
                        <br />
                        CALL US<br />
                        03 000 73 7777<br />
                        031 3333 42 62<br />
                    </h3>

                </Container>

            </PrivateLayout>
        );
    }

}

export default SupportInfoScreen;
