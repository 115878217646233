import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import PublicLayout from "../../../../common/components/layouts/public";
import LzDimmer from '../../../../common/components/commons/dimmer';
import { lzGetAssetUrl } from '../../../../common/services/LzUtils';
import LzSideNav from "./partials/sidenav";
import LzTopBar from "./partials/topbar";
import ImgIcon from '../../assets/images/icon.png';
import ImgLogo from '../../assets/images/logo.png';
import { setAuthLoaded, setLastRoute } from '../../../../common/redux/actions';

class PrivateLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sideNavItems: this.getSideNavItems(props.authUser),
    };
  }

  componentDidMount() {
    this.saveLastRoute();
    this.checkLogin();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { authToken, authUser } = this.props;

    // Check login if auth token has changed
    if (prevProps.authToken !== authToken) {
      this.checkLogin();
    }

    // If auth user has changed then re-build side-nav
    if (prevProps.authUser !== authUser) {
      // Re-build side-nav
      this.setState({ sideNavItems: this.getSideNavItems(authUser) });
    }
  }

  saveLastRoute() {
    const { history, setLastRoute } = this.props;
    setLastRoute(history?.location?.pathname || '');
  }

  checkLogin() {
    const { authToken, authLoaded } = this.props;

    console.log('CHECKING LOGIN', authToken, authLoaded);

    // Check for logged in user
    if (!authToken || '' === authToken) {
      // TODO: Save current screen location to be redirected to after login/register
      this.props.setAuthLoaded(false);
      this.props.history.replace('/landing');
    }

    // Check if logged in but fresh data not loaded for this window
    if (!authLoaded) {
      // Call the load api and set loaded to true.
      // lzCallApi('load-auth-data');
      // TODO: Enable when ready
      this.props.setAuthLoaded(true);
    }
  }

  getSideNavItems(authUser) {
    const items = [
      { icon: 'dashboard', title: 'Dashboard', route: '/' },
      { icon: 'cog', title: 'Manage Orders', route: '/plan' },
      { icon: 'list-alt', title: 'Bill History', route: '/ledger' },
      { icon: 'th-list', title: 'Delivery History', route: '/deliveries-history' },
      { icon: 'credit-card', title: 'Payments History', route: '/payment-history' },
      { icon: 'support', title: 'Customer Support', route: '/customer-support' },
    ];

    // console.log('NAV', authUser, items);

    return items;
  }

  render() {
    const { sideNavItems } = this.state;
    const { screenTitle, children, showLoading, authUser, noBackground } = this.props;

    return (
      <PublicLayout noBackground={noBackground}>

        {(authUser && !showLoading) ?
          <div style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "hidden",
          }}>

            <LzSideNav
              headerClose={<img src={lzGetAssetUrl(ImgIcon)} alt='Icon' />}
              headerOpen={<img src={lzGetAssetUrl(ImgLogo)} alt='Logo' />}
              items={sideNavItems}
            />

            <div style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}>
              <LzTopBar screenTitle={screenTitle} />

              <div className="lz-screen-main">
                {children}
              </div>

            </div>

          </div>
          :
          <LzDimmer active={true} style={{ zIndex: 999 }}>
            <Spinner animation="border" />
          </LzDimmer>
        }

      </PublicLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    authToken: state.auth.token,
    authLoaded: state.auth.loaded,
    loading: state.commons.loading,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setAuthLoaded: (value) => dispatch(setAuthLoaded(value)),
    setLastRoute: (value) => dispatch(setLastRoute(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateLayout));
