import React from 'react';
import { Route, HashRouter as Router, Redirect } from 'react-router-dom';
import { createStore } from "redux";
import { Provider } from 'react-redux';
import reducer from "../../common/redux/reducers";
import { isAndroid, isIOS } from "../../common/services/LzHostInterface";

import LogoutScreen from '../../common/components/screens/private/user/logout';
import LoginScreen from './components/screens/public/user/login';
import RegisterScreen from './components/screens/public/user/register';
import RegisterSMSCodeScreen from './components/screens/public/user/register-verify';
import UserDashboardScreen from './components/screens/private/user/dashboard';
import UserSettingsScreen from './components/screens/private/user/settings';
import ChangeSMSAlertsScreen from './components/screens/private/user/change-sms-alerts';
import UserAccountScreen from './components/screens/private/user/account';
import ChangePasswordScreen from './components/screens/private/user/change-password';
import UpdateProfileScreen from './components/screens/private/user/update-profile';
import UpdatePlanProductsScreen from './components/screens/private/plan/select-product';
import UpdatePlanSelectTypeScreen from './components/screens/private/plan/select-type';
import UpdatePlanIntervalScreen from './components/screens/private/plan/interval';
import UpdatePlanIntervalHaltScreen from './components/screens/private/plan/interval-halt';
import UpdatePlanIntervalOneTimeScreen from './components/screens/private/plan/interval-onetime';
import FreeSampleScreen from './components/screens/private/plan/free-sample';
import UpdatePlanWeeklyScreen from './components/screens/private/plan/weekly';
import LedgerScreen from './components/screens/private/history/ledger';
import DeliveriesHistoryScreen from './components/screens/private/history/deliveries';
import PaymentsHistoryScreen from './components/screens/private/history/payments';
import MakePaymentsScreen from './components/screens/private/history/make-payment';
import MakePaymentJazzCashScreen from './components/screens/private/history/make-payment-jazzcash';
import MakePaymentAlfaPayScreen from './components/screens/private/history/make-payment-alfapay';
import CustomerSupportScreen from './components/screens/private/help/customer-support';
import AboutApplicationScreen from '../../common/components/screens/public/commons/about-application';
import ForgotPasswordScreen from './components/screens/public/user/forgot-password';
import LandingScreen from './components/screens/public/user/landing';
import PlansCalendarScreen from './components/screens/private/plan/calendar';
import ProductDetailScreen from './components/screens/private/plan/product-detail';
import QualityReportScreen from './components/screens/private/help/quality-report';
import FAQsScreen from './components/screens/private/help/faqs';
import ContactUsScreen from './components/screens/private/help/contact-us';
import TermsRejectedLandingScreen from './components/screens/public/user/terms-rejected-landing';

window.reduxStore = createStore(reducer);

export const routing = (
  <Provider store={window.reduxStore}>
    <Router>
      <div>
        <Route path="/landing" component={LandingScreen} />
        <Route path="/terms-rejected-landing" component={TermsRejectedLandingScreen} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/register" component={RegisterScreen} />
        <Route path="/register-verify" component={RegisterSMSCodeScreen} />
        <Route path="/forgot-password" component={ForgotPasswordScreen} />
        <Route path="/about-application" component={AboutApplicationScreen} />

        <Route path="/" component={UserDashboardScreen} exact />
        <Route path="/account" component={UserAccountScreen} />
        <Route path="/profile" component={UpdateProfileScreen} />
        <Route path="/password" component={ChangePasswordScreen} />
        <Route path="/logout" component={LogoutScreen} />
        <Route path="/settings" component={UserSettingsScreen} />
        <Route path="/sms-alerts" component={ChangeSMSAlertsScreen} />

        <Route path="/plan" component={UpdatePlanProductsScreen} />
        <Route path="/plan-select-type" component={UpdatePlanSelectTypeScreen} />
        <Route path="/plan-interval" component={UpdatePlanIntervalScreen} />
        <Route path="/plan-interval-halt" component={UpdatePlanIntervalHaltScreen} />
        <Route path="/plan-interval-onetime" component={UpdatePlanIntervalOneTimeScreen} />
        <Route path="/plan-free-sample" component={FreeSampleScreen} />
        <Route path="/plan-weekly" component={UpdatePlanWeeklyScreen} />
        <Route path="/plan-calendar" component={PlansCalendarScreen} />
        <Route path="/plan-product-detail" component={ProductDetailScreen} />

        <Route path="/ledger" component={LedgerScreen} />
        <Route path="/deliveries-history" component={DeliveriesHistoryScreen} />
        <Route path="/payment-history" component={PaymentsHistoryScreen} />
        <Route path="/make-payment" component={MakePaymentsScreen} />
        <Route path="/make-payment-jazzcash/:type?" component={MakePaymentJazzCashScreen} />
        <Route path="/make-payment-alfapay/:type?" component={MakePaymentAlfaPayScreen} />

        <Route path="/customer-support" component={CustomerSupportScreen} />
        <Route path="/quality-report" component={QualityReportScreen} />
        <Route path="/faqs" component={FAQsScreen} />
        <Route path="/contact-us" component={ContactUsScreen} />

        {/* this below rule will send to / initially even if a url like /user-login is loaded in the browser */}
        {(isAndroid() || isIOS()) && <Route path="*" render={() => (<Redirect to="/" />)} />}
      </div>
    </Router>
  </Provider>
);
