import React from 'react';
import { connect } from 'react-redux';
import { toggleSideNav } from '../../../redux/actions';
import Dropdown from 'react-bootstrap/Dropdown';
import LzIcon from '../../commons/icon';
import { Link } from 'react-router-dom';

class LzTopBar extends React.Component {

    render() {
        const { authUser, toggleSideNav, screenTitle } = this.props;

        return (
            <div className="lz-topbar">

                <LzIcon
                    name="bars"
                    style={{
                        marginTop: 3,
                        cursor: "pointer",
                    }}
                    onClick={() => toggleSideNav()}
                />

                {screenTitle && <h1>{screenTitle}</h1>}

                <div>
                    <LzIcon name="user-circle-o" />
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            {authUser.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Welcome {authUser.name}</Dropdown.Header>
                            <Dropdown.Item as={Link} to="/"><LzIcon name="dashboard" /> Dashboard</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/profile"><LzIcon name="user" /> Update Profile</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/password"><LzIcon name="lock" /> Change Password</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Link} to="/logout"><LzIcon name="sign-out" /> Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleSideNav: () => dispatch(toggleSideNav()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LzTopBar);
