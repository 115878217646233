import React from 'react';
import PrivateLayout from '../../../layouts/private';
import LzButton from '../../../../../../common/components/commons/button';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';

class MakePaymentsScreen extends React.Component {

  render() {
    return (
      <PrivateLayout screenTitle="Payments History" activeTabKey="payments" className="taza-payment-screen">

        <Link to="/make-payment-jazzcash/MWALLET"><LzButton icon="jazzcash" iconSource="taza-png" className="payment-button">Pay via Mobile Wallet<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

        {false && <Link to="/make-payment-jazzcash/MPAY"><LzButton icon="jazzcash" iconSource="taza-png" className="payment-button">Pay via Credit Card<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>}

        <Link to="/make-payment-alfapay/1"><LzButton icon="alfapay" iconSource="taza-png" className="payment-button">Pay via Alfa Wallet<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

        <Link to="/make-payment-alfapay/2"><LzButton icon="alfapay" iconSource="taza-png" className="payment-button">Pay via Alfa Account<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

        <Link to="/make-payment-alfapay/3"><LzButton icon="alfapay" iconSource="taza-png" className="payment-button">Pay via Credit Card<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

      </PrivateLayout>
    );
  }

}

export default MakePaymentsScreen;
