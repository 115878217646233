import React from 'react';
import PublicLayout from '../../../../../../common/components/layouts/public';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { connect } from 'react-redux';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import SocialLinksComponent from '../../../commons/social-links';
import LzIcon from '../../../../../../common/components/commons/icon';
import LzFormFieldSelect from '../../../../../../common/components/commons/fields/select';
import LzOptionModel from '../../../../../../common/models/option';

class RegisterScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xxfullname: '',
            xxphonenum: '',
            xxusername: '',
            xxpassword: '',
            xxzone_id: 0,
            zones: [],
        };
    }

    componentDidMount() {
        this.loadZones();
    }

    async loadZones() {
        const data = await lzCallApi('getZoneList');
        if (data) {
            this.setState({ zones: LzOptionModel.parseArray(data, 'zone_id', 'name') });
        }
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onSubmit() {
        this.callRegisterApi();
    }

    async callRegisterApi() {
        const { setSuccessMessage } = this.props;
        const { xxfullname, xxphonenum, xxusername, xxpassword, xxzone_id } = this.state;

        // Make the api call and get processed data
        const data = await lzCallApi('customerSignUP', {
            fullName: xxfullname,
            cell_no_1: xxphonenum,
            userName: xxusername,
            email: xxusername,
            password: xxpassword,
            zone_id: xxzone_id,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your account was successfully registered! Please login to continue...');
        }
    }

    render() {
        const { xxfullname, xxphonenum, xxusername, xxpassword, xxzone_id, zones } = this.state;

        return (
            <PublicLayout>
                <div className="mk-screen-dark-form">
                    <Link to="/landing"><LzIcon source="mk-svg" name="arrow-left" /></Link>
                    <h1>Welcome!</h1>
                    <div>
                        <LzForm className="form" onSubmit={() => this.onSubmit()}>

                            <h2>SIGN UP</h2>

                            <LzFormFieldInput fluid required type="text" label="Full Name"
                                name="xxfullname" value={xxfullname} onChange={this.handleInputChange} />

                            <LzFormFieldInput fluid required type="text" label="Phone No."
                                name="xxphonenum" value={xxphonenum} onChange={this.handleInputChange} />

                            <LzFormFieldSelect fluid required label="Zone" options={zones}
                                name="xxzone_id" value={xxzone_id} onChange={this.handleInputChange} />

                            <LzFormFieldInput fluid required type="email" label="Email"
                                name="xxusername" value={xxusername} onChange={this.handleInputChange} />

                            <LzFormFieldInput fluid required type="password" label="Password"
                                name="xxpassword" value={xxpassword} onChange={this.handleInputChange} />

                            <LzButton type="submit" variant="primary" iconSource="mk-svg" icon="arrow-right" label=" " />

                        </LzForm>
                    </div>
                    <SocialLinksComponent />
                </div>
            </PublicLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(RegisterScreen);
