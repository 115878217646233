import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import PrivateLayout from '../../../layouts/private';

class UserAccountScreen extends React.Component {

    render() {
        return (
            <PrivateLayout screenTitle="My Account" activeTabKey="account">

                <Container className="ndf-dashboard">
                    <div className="icons">
                        <Row>
                            <Col xs={12}>
                                <Link to="/profile">
                                    <LzIcon name="user" />
                                    <p>Update Profile</p>
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Link to="/password">
                                    <LzIcon name="lock" />
                                    <p>Change Password</p>
                                </Link>
                            </Col>
                            <Col xs={12}>
                                <Link to="/logout">
                                    <LzIcon name="sign-out" />
                                    <p>Sign Out</p>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </PrivateLayout>
        );
    }

}

export default UserAccountScreen;
