import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import ImgLogo from '../../../../assets/images/logo.png';
import PrivateLayout from '../../../layouts/private';
import { lzGetAssetUrl, lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';
import { lzCallApi } from '../../../../../../common/services/LzApiService';

class UserDashboardScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentBalance: 0,
            customerName: '',
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { authUser } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('dashboard', {
            client_id: authUser.client_id,
        });

        // Load data if call was successful
        if (data && data.customerName) {
            this.setState({
                customerName: data.customerName,
                currentBalance: +data.currentBalance || 0,
            });
        }
    }

    render() {
        const { customerName, currentBalance } = this.state;
        const { authUser } = this.props;

        console.log(authUser);

        return (
            <PrivateLayout hideTopBar screenTitle="Home" activeTabKey="home" backLink="/logout" className="dairycraft-layout-dashboard">

                <div className="dc-dashboard">
                    <div className="top-part">
                        <Container>

                            <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' className='logo' />

                            <Row>
                                <Col xs={6}>
                                    <small>Amount Due:</small>
                                    <big>{lzGetFormattedAmount(currentBalance, true)}</big>
                                </Col>
                                <Col xs={6}>
                                    <small>Delivery Incharge:</small>
                                    <big>{authUser.rider_name}</big>
                                    <small>{authUser.rider_phoneNumber}</small>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="bot-part">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div style={{ fontSize: 21, marginBottom: 16 }}>Welcome {customerName}!</div>
                                </Col>
                                <Col xs={6}>
                                    <Link to="/plan">
                                        <LzIcon name="coffee" />
                                        <p>Manage Order</p>
                                    </Link>
                                </Col>
                                <Col xs={6}>
                                    <Link to="/deliveries-history">
                                        <LzIcon name="truck" />
                                        <p>Delivery History</p>
                                    </Link>
                                </Col>
                                <Col xs={6}>
                                    <Link to="/payment-history">
                                        <LzIcon name="credit-card" />
                                        <p>Payment History</p>
                                    </Link>
                                </Col>
                                <Col xs={6}>
                                    <Link to="/customer-support">
                                        <LzIcon name="support" />
                                        <p>Customer Support</p>
                                    </Link>
                                </Col>
                                {/*<Col xs={6}>
                            <Link to="/messages">
                                <LzIcon  name="messages" />
                                <p>Messages</p>
                            </Link>
                        </Col>*/}
                                <Col xs={6}>
                                    <Link to="/account">
                                        <LzIcon name="user" />
                                        <p>Account Settings</p>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(UserDashboardScreen);
