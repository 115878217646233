import React from 'react';

class LzDimmer extends React.Component {

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { active, style, onClick, children } = this.props;

        const styles = style || {};
        if (onClick) {
            styles.cursor = 'pointer';
        }

        return (
            <div style={{ display: "contents" }}>
                {active &&
                    <div
                        className="lz-dimmer"
                        style={styles}
                        onClick={() => this.onClick()}
                    >
                        {children}
                    </div>
                }
            </div>
        );
    }

}

export default LzDimmer;
