import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import PrivateLayout from '../../../layouts/private';

class UserSettingsScreen extends React.Component {

  render() {
    return (
      <PrivateLayout screenTitle="Home" activeTabKey="settings" backLink="/logout" lockForGuests>

        <div className="settings-links">
          <Link to="/profile"><div className="icon"><LzIcon source="taza-png" name="profile" /></div> Profile</Link>
          <Link to="/password"><div className="icon"><LzIcon source="taza-png" name="password" /></div> Change Password</Link>
          <Link to="/sms-alerts"><div className="icon"><LzIcon source="taza-png" name="sms-alerts" /></div> Manage SMS Alerts</Link>
          {/*<Link to="/make-payment"><div className="icon" style={{ paddingTop: 2 }}><LzIcon name="money" /></div> Make Payment</Link>*/}
          {/*<Link to="/deliveries-history"><div className="icon"><LzIcon source="taza-png" name="deliveries" /></div> Delivery History</Link>*/}
          <Link to="/faqs"><div className="icon"><LzIcon size="2x" name="question-circle" /></div> FAQs</Link>
          <Link to="/contact-us"><div className="icon"><LzIcon size="2x" name="envelope" /></div> Contact Us</Link>
          <Link to="/logout"><div className="icon"><LzIcon source="taza-png" name="logout" /></div> Logout</Link>
        </div>

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(UserSettingsScreen);
