import React from 'react';
import { connect } from "react-redux";
import { setErrorMessage, setLoading, setSuccessMessage } from '../../redux/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import LzDimmer from '../commons/dimmer';

class PublicLayout extends React.Component {

    componentDidUpdate() {
        const { errorMessage } = this.props;
        window.onpopstate = e => {
            if (errorMessage !== '') {
                e.preventDefault();
                window.history.go(1);
                this.clearErrorMessage();
            }
        }
    }

    clearErrorMessage() {
        this.props.setErrorMessage('');
    }

    clearSuccessMessage() {
        this.props.setSuccessMessage('');
    }

    render() {
        const { loading, errorMessage, successMessage, children, noBackground, className } = this.props;

        const styles = {};
        if (noBackground) {
            styles.background = 'none';
        }

        return (
            <div className={'lz-public ' + (className || '')} style={styles}>
                <div style={{ minHeight: '100%' }}>

                    <LzDimmer active={loading} style={{ zIndex: 999 }}>
                        <Spinner animation="border" />
                    </LzDimmer>

                    <Modal show={errorMessage !== ''} onHide={() => this.clearErrorMessage()}>
                        <Modal.Header closeButton><Modal.Title>Error Occurred!</Modal.Title></Modal.Header>
                        <Modal.Body><p dangerouslySetInnerHTML={{ __html: errorMessage }}></p></Modal.Body>
                        <Modal.Footer><Button variant="secondary" onClick={() => this.clearErrorMessage()}>Close</Button></Modal.Footer>
                    </Modal>

                    <Modal show={successMessage !== ''} onHide={() => this.clearSuccessMessage()}>
                        <Modal.Header closeButton><Modal.Title>Success!</Modal.Title></Modal.Header>
                        <Modal.Body><p dangerouslySetInnerHTML={{ __html: successMessage }}></p></Modal.Body>
                        <Modal.Footer><Button variant="secondary" onClick={() => this.clearSuccessMessage()}>Close</Button></Modal.Footer>
                    </Modal>

                    {children}

                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.commons.loading,
        errorMessage: state.commons.errorMessage,
        successMessage: state.commons.successMessage,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicLayout);
