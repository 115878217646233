import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import PrivateLayout from '../../../layouts/private';

class UserDashboardScreen extends React.Component {

    render() {
        return (
            <PrivateLayout screenTitle="Home" activeTabKey="home" backLink="/logout">

                <Container className="mk-dashboard">
                    <Row>
                        <Col xs={6}>
                            <Link to="/plan">
                                <LzIcon source="mk-png" name="manage-order" />
                                <p>Manage Order</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/deliveries-history">
                                <LzIcon source="mk-svg" name="ancient-scroll" />
                                <p>Delivery History</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/payment-history">
                                <LzIcon source="mk-svg" name="credit-card" />
                                <p>Payment History</p>
                            </Link>
                        </Col>
                        <Col xs={6}>
                            <Link to="/customer-support">
                                <LzIcon source="mk-png" name="support" />
                                <p>Customer Support</p>
                            </Link>
                        </Col>
                        {/*<Col xs={6}>
                            <Link to="/messages">
                                <LzIcon source="mk-png" name="messages" />
                                <p>Messages</p>
                            </Link>
                        </Col>*/}
                        <Col xs={6}>
                            <Link to="/account">
                                <LzIcon source="mk-svg" name="user" />
                                <p>Account Settings</p>
                            </Link>
                        </Col>
                    </Row>
                </Container>

            </PrivateLayout>
        );
    }

}

export default UserDashboardScreen;
