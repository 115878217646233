import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { Col, Container, Row } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';

class UpdatePlanWeeklyScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            preferredTimes: [],
            weeklySchedule: [],
            model: {},
            estMonthlyBill: 0,
        };
    }

    componentDidMount() {
        this.loadPreferredTimeList();
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    handleWeekdayChange(dex, name, value) {
        const { weeklySchedule } = this.state;

        weeklySchedule[dex][name] = value;

        if ('quantity' === name) {
            weeklySchedule[dex].isSelected = +value > 0 ? 1 : 0;
        }

        this.setState({ weeklySchedule: weeklySchedule, estMonthlyBill: this.evalEstMonthlyBill(weeklySchedule) });
    }

    applyToAll() {
        const { weeklySchedule } = this.state;

        for (const key in weeklySchedule) {
            if (key > 0) {
                weeklySchedule[key].PreferredTime = weeklySchedule[0].PreferredTime;
                weeklySchedule[key].quantity = weeklySchedule[0].quantity;
                weeklySchedule[key].isSelected = weeklySchedule[0].isSelected;
            }
        }

        this.setState({ weeklySchedule: weeklySchedule, estMonthlyBill: this.evalEstMonthlyBill(weeklySchedule) });
    }

    evalEstMonthlyBill(weeklySchedule) {
        const { product } = this.props;

        let totQty = 0;
        for (const key in weeklySchedule) {
            totQty += +weeklySchedule[key].quantity;
        }

        const qtyPerDayApprox = Math.round(totQty / 7);

        const totQtyAllDays = 4 * totQty + 3 * qtyPerDayApprox;

        return totQtyAllDays * product.price;
    }

    async loadPreferredTimeList() {
        // Make the api call and get the data
        const data = await lzCallApi('getPreferredTimeList');

        // Load data if call was successful
        if (data) {
            this.setState({ preferredTimes: data }, () => this.loadWeeklySchedule());
        }
    }

    async loadWeeklySchedule() {
        const { model } = this.state;
        const { product, authUser, history } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get the data
        const data = await lzCallApi('getWeeklySchedule', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Load data if call was successful
        if (data && data.data && data.orderStartDate) {
            model.fromDate = data.orderStartDate;
            this.setState({ model: model, weeklySchedule: data.data, estMonthlyBill: this.evalEstMonthlyBill(data.data) });
        }
    }

    async saveWeeklyPlan() {
        const { model, weeklySchedule } = this.state;
        const { product, authUser, setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('updateWeeklySchedual', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            orderStartDate: model.fromDate,
            data: weeklySchedule,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully saved!');
        }
    }

    async cancelPlan() {
        const { authUser, product, setSuccessMessage, history } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('CancelRegularOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully cancelled!');
            history.push('/plan-select-type');
        }
    }

    render() {
        const { product } = this.props;
        const { model, weeklySchedule, preferredTimes, estMonthlyBill } = this.state;

        return (
            <PrivateLayout screenTitle="Update Plan">

                <div className="lz-form-page lz-plan-form">

                    <h4>Weekly Plan</h4>

                    <Container>

                        <LzForm onSubmit={() => this.saveWeeklyPlan()}>

                            {(weeklySchedule && weeklySchedule.length > 0) &&
                                <React.Fragment>

                                    <Row style={{ marginBottom: 10 }}>
                                        <Col xs={6}>Product Rate :</Col>
                                        <Col xs={6}><b>{lzGetFormattedAmount(product.price, true) + ' per ' + product.unit}</b></Col>
                                    </Row>

                                    <Row style={{ marginBottom: 10 }}>
                                        <Col xs={6}>Est. Monthly Bill :</Col>
                                        <Col xs={6}><b>{lzGetFormattedAmount(estMonthlyBill, true)}</b></Col>
                                    </Row>

                                    <LzFormFieldInput required fluid type="date" label="Start Order From"
                                        name="fromDate" value={model.fromDate} onChange={this.handleModelChange} />

                                    <LzButton type="button" variant="link" size="sm" onClick={() => this.applyToAll()} style={{ marginTop: -10, marginBottom: 10 }}>
                                        <LzIcon name="list-ul" /> Apply to All
                                    </LzButton>

                                    {weeklySchedule.map((ws, dex) =>
                                        <Row
                                            key={'wksched-' + dex}
                                            className="lz-gutters-5px"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <Col xs={3} className="mk-weekly-day">
                                                {ws.day_name}
                                            </Col>
                                            <Col xs={4}>
                                                <select
                                                    className="mk-weekly-preftm"
                                                    value={+ws.PreferredTime}
                                                    onChange={(evt) => this.handleWeekdayChange(dex, 'PreferredTime', evt.target.value)}
                                                >
                                                    <option value={0}>- select -</option>
                                                    {preferredTimes.map((opt, odex) =>
                                                        <option key={'opt-' + odex} value={+opt.preferred_time_id}>{opt.preferred_time_name}</option>
                                                    )}
                                                </select>
                                                {/*<input type="time" value={ws.PreferredTime} className="mk-weekly-time"
                                                    onChange={(evt) => this.handleWeekdayChange(dex, 'PreferredTime', evt.target.value)} />*/}
                                            </Col>
                                            <Col xs={3}>
                                                <input type="number" value={ws.quantity} className="mk-weekly-qty"
                                                    onChange={(evt) => this.handleWeekdayChange(dex, 'quantity', evt.target.value)} />
                                                <small> / {product.unit}</small>
                                            </Col>
                                            <Col xs={2}>
                                                <LzIcon
                                                    name={+ws.isSelected > 0 ? 'dot-circle-o' : 'circle-o'}
                                                    className="mk-weekly-check"
                                                    onClick={() => this.handleWeekdayChange(dex, 'isSelected', +ws.isSelected > 0 ? 0 : 1)}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </React.Fragment>
                            }

                            <LzButton fluid type="submit" icon="save" label="Save Plan" />

                            {/*<LzButton fluid type="button" variant="danger" icon="times" label="Cancel Plan" onClick={() => this.cancelPlan()} />*/}

                        </LzForm>

                    </Container>

                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanWeeklyScreen));
