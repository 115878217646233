import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import UserModel from '../../../../../../common/models/user';
import PrivateLayout from '../../../layouts/private';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzFormFieldSelect from '../../../../../../common/components/commons/fields/select';
import LzButton from '../../../../../../common/components/commons/button';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzOptionModel from '../../../../../../common/models/option';
import LzFormFieldTexarea from '../../../../../../common/components/commons/fields/textarea';

class UpdateProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: null,
            zones: [],
        };
    }

    componentDidMount() {
        this.callLoadProfileApi();
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    async callLoadProfileApi() {
        // Make the api call and get the data
        const data = await lzCallApi('getCustomerProfile', {
            client_id: this.props.authUser.client_id,
        }, 'post');

        // Load data if call was successful
        if (data && data.profile && data.zones) {
            this.setState({
                model: new UserModel(data.profile),
                zones: LzOptionModel.parseArray(data.zones, 'zone_id', 'name'),
            });
        }
    }

    async callUpdateProfileApi() {
        const { model } = this.state;
        const { setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('UpdateCustomerProfile', model, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your profile was successfully updated!');
        }
    }

    render() {
        const { model, zones } = this.state;

        return (
            <PrivateLayout screenTitle="Profile" backLink="/account">

                {model && <div className="lz-form-page">

                    <Container>
                        <LzForm onSubmit={() => this.callUpdateProfileApi()}>

                            <LzFormFieldInput required fluid label="Full Name"
                                name="fullname" value={model.fullname} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid label="Phone No."
                                name="cell_no_1" value={model.cell_no_1} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid label="Email Address"
                                name="email" value={model.email} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid label="CNIC"
                                name="cnic" value={model.cnic} onChange={this.handleModelChange} />

                            <LzFormFieldSelect fluid required label="Zone" options={zones}
                                name="zone_id" value={model.zone_id} onChange={this.handleModelChange} />

                            <LzFormFieldTexarea required fluid label="Address"
                                name="address" value={model.address} onChange={this.handleModelChange} />

                            <LzButton fluid type="submit" icon="save" label="Save Changes" />

                        </LzForm>
                    </Container>

                </div>}

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateProfileScreen);
