import React from 'react';
import Form from 'react-bootstrap/Form';

class LzForm extends React.Component {

    onSubmit(evt) {
        // Prevent form submission by default
        evt.preventDefault();

        // Call if props exist
        if (this.props.onSubmit) {
            this.props.onSubmit(evt);
        }
    }

    render() {
        const { id, name, method, action, target, className, style, children } = this.props;

        return (
            <Form
                id={id}
                name={name}
                method={method}
                action={action}
                target={target}
                className={
                    'lz-form' +
                    (className ? ' ' + className : '')
                }
                style={style}
                onSubmit={(evt) => this.onSubmit(evt)}
            >
                {children}
            </Form>
        );
    }

}

export default LzForm;
