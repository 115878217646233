import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import PrivateLayout from '../../../layouts/private';

class UserAccountScreen extends React.Component {

    render() {
        return (
            <PrivateLayout screenTitle="My Account">

                <Container className="mk-dashboard">
                    <Row>
                        <Col xs={12}>
                            <Link to="/profile">
                                <LzIcon source="mk-svg" name="update-profile" />
                                <p>Update Profile</p>
                            </Link>
                        </Col>
                        <Col xs={12}>
                            <Link to="/password">
                                <LzIcon source="mk-svg" name="change-password" />
                                <p>Change Password</p>
                            </Link>
                        </Col>
                        <Col xs={12}>
                            <Link to="/logout">
                                <LzIcon source="mk-svg" name="logout" />
                                <p>Sign Out</p>
                            </Link>
                        </Col>
                    </Row>
                </Container>

            </PrivateLayout>
        );
    }

}

export default UserAccountScreen;
