import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';
import PrivateLayout from '../../../layouts/private';

class ProductDetailScreen extends React.Component {

  render() {
    const { product } = this.props;

    if (!product) {
      return <Redirect to="/plan" />;
    }

    console.log('PROD', product);

    return (
      <PrivateLayout screenTitle="Product Details" activeTabKey="plan" lockForGuests>

        <Row>
          <Col xs={4}>
            <img src={product.product_image} style={{
              display: 'block',
              width: 'auto',
              height: 'auto',
              margin: 'auto',
              maxWidth: '100%',
              borderRadius: 8,
            }} />
          </Col>
          <Col xs={8}>
            <h4>{product.product_name}</h4>
            <p>{product.description}</p>
            <p><big>{lzGetFormattedAmount(product.price)}</big> <small>per {product.unit}</small></p>
          </Col>
        </Row>
        <br />

        <Link to="/plan-free-sample" className="button-link"><LzButton className="lz-full-width">Order a Sample<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

        <Link to="/plan-interval-onetime" className="button-link"><LzButton className="lz-full-width">Place a One Time Order<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

        <Link to="/plan-calendar" className="button-link"><LzButton className="lz-full-width">Get a Monthly Plan<LzIcon name="angle-double-right" className="caret" /></LzButton></Link>

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    product: state.plan.product,
  }
};

export default connect(
  mapStateToProps,
)(ProductDetailScreen);
