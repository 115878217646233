import React from 'react';
import LzIcon from '../../../../common/components/commons/icon';

class LzSpinnerComponent extends React.Component {

  render() {
    return (
      <LzIcon pulse size="4x" color="white" name="spinner" />
    );
  }

}

export default LzSpinnerComponent;
