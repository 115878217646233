import React from 'react';
import { Link } from 'react-router-dom';
import LzIcon from './icon';

class UnderConstruction extends React.Component {

    render() {
        const { showBack } = this.props;

        return (
            <div style={{ textAlign: "center" }}>
                <p>&nbsp;</p>
                <LzIcon name="cogs" color="olive" size="5x" />
                <h3>Under Construction</h3>
                {showBack &&
                    <Link to="/"><LzIcon name='arrow left' />Back to Dashboard</Link>
                }
            </div>
        );
    }

}

export default UnderConstruction;
