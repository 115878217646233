import moment from 'moment';

export const LZ_DATE_FIELD_FORMAT = 'YYYY-MM-DD';

export const lzGetFormattedDate = (value, format) => {
    format = typeof format === 'undefined' ? 'DD MMM, YYYY' : format;
    return !value || '' === value || 'now' === value ? moment().format(format) : moment(value).format(format);
};

export const lzGetFormattedTime = (value, format) => {
    format = typeof format === 'undefined' ? 'hh:mm a' : format;
    return !value || '' === value || 'now' === value ? moment().format(format) : moment('2000-01-01 ' + value).format(format);
};

export const lzGetFormattedDateTime = (value, format) => {
    format = typeof format === 'undefined' ? 'DD MMM, YYYY hh:mm a' : format;
    return !value || '' === value || 'now' === value ? moment().format(format) : moment(value).format(format);
};

/**
 * Get UNIX Epoch timestamp in seconds.
 *
 * @return {number}
 */
export const lzGetTimestamp = () => {
    return moment().unix();
};

export const lzGetMonthStartDate = (format, date) => {
    return lzGetFormattedDate(moment(date).startOf('month'), format);
}

export const lzGetMonthEndDate = (format, date) => {
    return lzGetFormattedDate(moment(date).endOf('month'), format);
}

export const lzGetWeekStartDate = (format, date) => {
    return lzGetFormattedDate(moment(date).startOf('week'), format);
}

export const lzGetWeekEndDate = (format, date) => {
    return lzGetFormattedDate(moment(date).endOf('week'), format);
}

export const lzDatesDiffInDays = (date1, date2) => {
    return moment(date1).diff(moment(date2), 'days');
}

export const lzDateAddDays = (date, days) => {
    return moment(date).add(days, 'day');
}

export const lzDateAddMonths = (date, months) => {
    return moment(date).add(months, 'month');
}

export const lzGetDayOfWeek = (date) => {
    const d = moment(date).weekday();
    // 0 means Sun since weekdays are like this:
    // 0 - Sun
    // 1 - Mon
    // 2 - Tue
    // 3 - Wed
    // 4 - Thu
    // 5 - Fri
    // 6 - Sat
    return 0 === d ? 7 : d;
}
