import React from 'react';

class RegisterStepsComponent extends React.Component {

  render() {
    const { step } = this.props;

    return (
      <div className="register-steps">
        <div className={'register-step ' + (1 === step ? 'active' : '')}>
          <small>step</small>
          <strong>1</strong>
        </div>
        <div className={'register-step ' + (2 === step ? 'active' : '')}>
          <small>step</small>
          <strong>2</strong>
        </div>
        {/*<div className={'register-step ' + (3 === step ? 'active' : '')}>
          <small>step</small>
          <strong>3</strong>
        </div>*/}
    </div>
    );
  }

}

export default RegisterStepsComponent;
