import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { lzGetFormattedDate, lzGetMonthStartDate } from '../../../../../../common/services/LzMoment';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { Col, Container, Row, Table } from 'react-bootstrap';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzIsArray } from '../../../../../../common/services/LzType';

class LedgerScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      summary: [],
      yearMonth: lzGetMonthStartDate('YYYY-MM'),
    };
  }

  componentDidMount() {
    this.loadHistory();
  }

  async loadHistory() {
    const { yearMonth } = this.state;
    const { authUser } = this.props;

    const yearMonthSplit = yearMonth.split('-');

    // Make the api call and get the data
    const data = await lzCallApi('get_ledger_report', {
      client_id: authUser.client_id,
      year: yearMonthSplit[0],
      month: yearMonthSplit[1],
    });

    // Load data if call was successful
    if (data) {
      this.setState({
        records: data.ledgerData,
        summary: data.sumery,
      });
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { yearMonth, records, summary } = this.state;

    return (
      <PrivateLayout noBackground screenTitle="Bill History">

        <div className="heading-bar">
          <Container>
            <Row noGutters>
              <Col xs={3}></Col>
              <Col xs={6}>
                <LzFormFieldInput fluid inline type="month"
                  name="yearMonth" value={yearMonth} onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={3}>
                <LzButton fluid variant="link" type="button" onClick={() => this.loadHistory()}><LzIcon name="refresh" /></LzButton>
              </Col>
            </Row>
          </Container>
        </div>

        {(lzIsArray(records) && records.length > 0) &&
          <Container>
            <div style={{
              textAlign: "right",
              fontWeight: "bold",
              marginBottom: 15,
            }}>
              Payable Balance: {-records[records.length - 1].balance}
            </div>

            <Table responsive style={{ fontSize: 10, textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Bill</th>
                  <th>Paid</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {records.map((rec, dex) =>
                  <React.Fragment key={'ledger-' + dex}>
                    {0 === dex ?
                      <tr>
                        <td>{lzGetFormattedDate(rec.date)}</td>
                        <td colSpan={2}>{rec.discription}</td>
                        <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>{-rec.balance}</td>
                      </tr>
                      :
                      <tr>
                        <td>{lzGetFormattedDate(rec.date)}</td>
                        <td>{rec.discription}</td>
                        <td>{rec.delivery}</td>
                        <td>{rec.reciveAmount}</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>{-rec.balance}</td>
                      </tr>
                    }
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </Container>
        }

        {lzIsArray(summary) &&
          <Container>
            <Table responsive style={{ fontSize: 10, textAlign: "center" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Product Name</th>
                  <th>Qty</th>
                  <th style={{ textAlign: "right", fontWeight: "bold" }}>Bill</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((rec, dex) =>
                  <tr key={'summary-' + dex}>
                    <td style={{ textAlign: "left" }}>{rec.product_name}</td>
                    <td>{rec.deliveryQuantity_sum} {rec.unit}</td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>{rec.deliverySum}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Container>
        }

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(LedgerScreen);
