import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';

class MakePaymentAlfaPayScreen extends React.Component {

  constructor(props) {
    super(props);
    this.paymentMethod = props.match.params?.type || '';
  }

  render() {
    const { authUser } = this.props;

    // const ifUrl = 'https://dairydelivery.conformiz.com/alpha_payment/alpha_payment_form?client_id=' + authUser.client_id;
    const ifUrl = 'https://dairydelivery.conformiz.com/payments/paymentInitForm?gateway=alfapay&paymentMethod=' + this.paymentMethod + '&clientId=' + authUser.client_id;

    return (
      <PrivateLayout hideBalanceBox screenTitle="Pay via Alfa Pay" activeTabKey="payments">

        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 0,
            scrollbarWidth: 5,
          }}
          src={ifUrl}
        ></iframe>

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(MakePaymentAlfaPayScreen);
