// import Moment from "react-moment";

import UserModel from "../models/user";
import { setAuthToken, setAuthUser, setErrorMessage, setLoading, setSuccessMessage } from "../redux/actions";
import { AppConstants } from "./Constants";
import { lzGetFromLocalStorage } from "./LzLocalStorage";
import { lzGetTimestamp } from "./LzMoment";
import { lzIsArray, lzIsObject, lzJsonToString } from "./LzType";
import { lzLogInfo } from "./LzUtils";

const LZ_API_ENDPOINT_URI = 'https://dairydelivery.conformiz.com/apiionic/';
// const LZ_API_ENDPOINT_URI = 'http://dairydelivery.local/apiionic/';

const lzBuildAndCallApi = async (action, params, method, controllerName) => {

  // Get state and dispatch from redux store
  const reduxState = window.reduxStore.getState();

  // Init based on action type
  let initData = {};
  let url = LZ_API_ENDPOINT_URI + action;

  // If specific controller provided
  if (typeof controllerName !== 'undefined' && controllerName !== '') {
    url = url.replace('/apiionic/', '/' + controllerName + '/');
  }

  // Setup params
  method = method ? method : 'post';      // Default to post for this project as Sadly it uses POST for everything in the Yii-backend
  params = params ? params : {};
  params.company_branch_id = lzGetFromLocalStorage('companyId', AppConstants.app_company_id, true);
  if (0 === +params.company_branch_id) {
    params.company_branch_id = AppConstants.app_company_id;
  }
  params.timestamp = lzGetTimestamp();

  // Setup headers
  const headers = {};
  if (reduxState.auth && reduxState.auth.token) {
    headers['Authorization'] = 'Bearer ' + reduxState.auth.token;
  }

  // Make call params in case of get request
  if ('get' === method) {
    // Edit the url with query string
    url += (url.indexOf('?') < 0 ? '?' : '&') + Object.entries(params).map((p) => p[0] + '=' + encodeURIComponent(p[1])).join('&');

    // Make init data
    initData = {
      method: 'GET',
      headers,
    };
  }

  // In-case of anything else, we take it as POST request
  else {
    // Set data to send for api call
    const data = new FormData();
    if (lzIsObject(params)) {
      for (const key in params) {
        data.append(
          key,
          lzIsObject(params[key]) || lzIsArray(params[key]) ? lzJsonToString(params[key]) : params[key]
        );
      }
    }

    // Set the method (PUT, PATCH, etc) as post _method so that backend handles it accordingly
    data.append('_method', method.toUpperCase());

    // Make init data
    initData = {
      method: 'POST',
      body: data,
      headers,
    };
  }

  // Call the api with await
  lzLogInfo('API CALL: ', url, initData);
  const response = await fetch(url, initData);
  lzLogInfo('API RESP: ', response);

  return response;
};

export const lzCallApi = async (action, params, method, showSuccessMsgIfNotEmpty, controllerName) => {
  // Get state and dispatch from redux store
  const reduxDispatch = window.reduxStore.dispatch;

  // NOTE: We do not skip if loading because then it skips simultaneous calls.
  //       If ever simultaneous calls create a problem, then we will have to
  //       look for an ajax call handler that supports multiple calls at the
  //       same time.

  // Tell app that we are loading
  reduxDispatch(setLoading(true));

  // Call and parse the json out of the response
  try {

    // Make the api call and get processed data
    const response = await lzBuildAndCallApi(action, params, method, controllerName);

    // If response code is 403 then it means that authentication is failed and user should be logged out
    if (403 === response.status) {
      console.error('USER LOGGED OUT! Revoking token and sending to login page...');
      reduxDispatch(setAuthUser(null));
      reduxDispatch(setAuthToken(''));
      reduxDispatch(setLoading(false));
      reduxDispatch(setErrorMessage('User is logged out from server!'));
      return null;
    }

    const json = await response.json();
    lzLogInfo('API DATA: ', json);

    // Handle the data
    if (!json || typeof json.data === 'undefined' || typeof json.message === 'undefined') {
      console.log('SET-ERR-JSON1', json);
      reduxDispatch(setErrorMessage(json.message || 'Invalid data returned from server.'));
      reduxDispatch(setLoading(false));
      return null;
    }

    // Hide loading
    reduxDispatch(setLoading(false));

    // Validate success and return it if valid
    if (json.success) {
      // Check if logged in user data is updated
      const ud = json.logged_in_user_data;
      if (ud && ud.token && ud.user) {
        reduxDispatch(setAuthToken(ud.token, true));
        reduxDispatch(setAuthUser(new UserModel(ud.user), true));
      }

      if (showSuccessMsgIfNotEmpty && json.message && json.message !== '') {
        reduxDispatch(setSuccessMessage(json.message));
      }

      // Return the api call data
      return json.data;
    }

    let errorMessage = json.message + '<pre>';
    const keys = Object.keys(json.data);
    for (const key of keys) {
      for (const msg of json.data[key]) {
        errorMessage += "\n\t- " + msg;
      }
    }
    errorMessage += '</pre>';

    console.log('SET-ERR-JSON', json);
    reduxDispatch(setErrorMessage(errorMessage));
    reduxDispatch(setLoading(false));

    return null;
  } catch (e) {
    console.error(e.toString());
    reduxDispatch(setLoading(false));
    reduxDispatch(setErrorMessage('An error has occured in the api call to server!<br/>Please check logs for details.'));
    return null;
  }
};
