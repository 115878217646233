import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { setSuccessMessage } from '../../../../redux/actions';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../services/LzApiService';
import UserModel from '../../../../models/user';
import LzForm from '../../../commons/form';
import LzFormFieldInput from '../../../commons/fields/input';
import LzButton from '../../../commons/button';

class UpdateProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: null,
        };
    }

    componentDidMount() {
        this.callLoadProfileApi();
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    async callLoadProfileApi() {
        // Make the api call and get the data
        const data = await lzCallApi('profile');

        // Load data if call was successful
        if (data) {
            this.setState({
                model: new UserModel(data),
            });
        }
    }

    async callUpdateProfileApi() {
        const { model } = this.state;
        const { setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('profile', model, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your profile was successfully updated!');
        }
    }

    render() {
        const { model } = this.state;

        return (
            <PrivateLayout screenTitle="Update Profile">

                {model && <div className="lz-form-page lz-form-page-full">

                    <Container>
                        <LzForm onSubmit={() => this.callUpdateProfileApi()}>

                            <LzFormFieldInput required fluid
                                icon="user" label="Name" placeholder="enter your full name"
                                name="name" value={model.name}
                                onChange={this.handleModelChange}
                            />

                            <LzFormFieldInput fluid
                                icon="phone" label="Phone" placeholder="enter your phone number"
                                name="phone" value={model.phone}
                                onChange={this.handleModelChange}
                            />

                            <LzButton fluid type="submit"
                                icon="save" label="Update Profile"
                            />

                        </LzForm>
                    </Container>

                </div>}

            </PrivateLayout>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(UpdateProfileScreen);
