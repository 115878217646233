import React from 'react';
import { connect } from "react-redux";
import { setErrorMessage, setLoading, setSuccessMessage } from '../../../../common/redux/actions';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import LzDimmer from '../../../../common/components/commons/dimmer';
import ImgLogo from '../../assets/images/logo-white.png';
import { lzGetAssetUrl } from '../../../../common/services/LzUtils';

class PublicLayout extends React.Component {

  clearErrorMessage() {
    this.props.setErrorMessage('');
  }

  clearSuccessMessage() {
    this.props.setSuccessMessage('');
  }

  render() {
    const { loading, errorMessage, successMessage, hideHeader, hidePoweredBy, verticallySpreaded, children, noBackground, className } = this.props;

    const styles = {};
    if (noBackground) {
      styles.background = 'none';
    }

    const innerStyles = { minHeight: '100vh' };
    if (verticallySpreaded) {
      innerStyles.display = 'flex';
      innerStyles.flexDirection = 'column';
      innerStyles.justifyContent = 'space-between';
    }

    return (
      <div className={'lz-public ' + (className || '')} style={styles}>
        <div style={innerStyles}>

          <LzDimmer active={loading} style={{ zIndex: 999 }}>
            <Spinner animation="border" />
          </LzDimmer>

          <Modal show={errorMessage !== ''} onHide={() => this.clearErrorMessage()}>
            <Modal.Header closeButton><Modal.Title>Error Occurred!</Modal.Title></Modal.Header>
            <Modal.Body><p dangerouslySetInnerHTML={{ __html: errorMessage }}></p></Modal.Body>
            <Modal.Footer><Button variant="secondary" onClick={() => this.clearErrorMessage()}>Close</Button></Modal.Footer>
          </Modal>

          <Modal show={successMessage !== ''} onHide={() => this.clearSuccessMessage()}>
            <Modal.Header closeButton><Modal.Title>Hooray!</Modal.Title></Modal.Header>
            <Modal.Body><p dangerouslySetInnerHTML={{ __html: successMessage }}></p></Modal.Body>
            <Modal.Footer><Button variant="secondary" onClick={() => this.clearSuccessMessage()}>Close</Button></Modal.Footer>
          </Modal>

          {!hideHeader &&
            <div className="header-1">
              <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' className='logo' />
              <div className="bot-shosha-1"></div>
              <div className="bot-shosha-2"></div>
            </div>
          }

          {children}

          {!hidePoweredBy &&
            <div className="powered-by">Powered by Conformiz</div>
          }
          {/*<a className="powered-by" href="http://www.conformiz.com" target="_blank">Powered by Conformiz</a>*/}

        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    loading: state.commons.loading,
    errorMessage: state.commons.errorMessage,
    successMessage: state.commons.successMessage,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
    setErrorMessage: (value) => dispatch(setErrorMessage(value)),
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicLayout);
