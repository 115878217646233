import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import LzButton from '../../../../common/components/commons/button';
import { lzCallInAppBrowser, lzGetAssetUrl, lzGetFormattedAmount } from '../../../../common/services/LzUtils';
import ImgLogo from '../../assets/images/logo.png';
import LzBotIconsBar from './partials/bot-icons-bar';
import LzFormFieldInput from '../../../../common/components/commons/fields/input';
import { lzCallApi } from '../../../../common/services/LzApiService';
import LzIcon from '../../../../common/components/commons/icon';

class PrivateLayoutMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      payNowShow: false,
      payNowAmount: Math.round(+props.currentBalance < 0 ? 500 : +props.currentBalance),
      payNowLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentBalance } = this.props;

    if (prevProps.currentBalance !== currentBalance) {
      this.setState({ payNowAmount: Math.round(+currentBalance < 0 ? 500 : +currentBalance) });
    }
  }

  async payNowProceed() {
    const { payNowAmount } = this.state;
    const { authUser } = this.props;

    this.setState({ payNowLoading: true });

    // Make the api call and get processed data
    const data = await lzCallApi('payNowInit', {
      client_id: authUser.client_id,
      amount: payNowAmount,
    }, 'post', false, 'AppPaymentApi');

    // If call was successful, then load the redirect url
    if (data && data.redirectUrl) {
      lzCallInAppBrowser(data.redirectUrl);
      this.setState({ payNowShow: false, payNowLoading: false });
    } else {
      this.setState({ payNowLoading: false });
    }
  }

  render() {
    const { payNowShow, payNowAmount, payNowLoading } = this.state;
    const {
      customerName, currentBalance, isGuest, lockForGuests, activeTabKey, hideBalanceBox,
      children, callReloadBalance, onContinueGuestSignUp, onExistGuestMode, isHalted, isCredit
    } = this.props;

    return (
      <React.Fragment>

        <div style={{
          display: "flex",
          flexDirection: "column",
          height: '100vh',
        }}>
          <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' style={{
            maxWidth: '60vw',
            maxHeight: '17vh',
            margin: '1em auto',
          }} />

          <div className="lz-main-grad-box">
            <div style={{ height: '100%' }}>

              {!hideBalanceBox &&
                <React.Fragment>
                  <div className='lz-balance-box' style={{ position: 'relative' }}>
                    <div className='name'>{customerName}</div>
                    {isGuest ?
                      <React.Fragment>
                        <LzButton size="sm" label="Exit to Login" variant="danger" onClick={() => onExistGuestMode()}
                          style={{
                            position: 'absolute',
                            top: -6,
                            right: -6,
                            fontSize: 11,
                          }}
                        />
                        <div className='balance'>
                          <LzButton label="Continue to SignUp" onClick={() => onContinueGuestSignUp()} />
                        </div>
                        <div className='label' style={{ textTransform: 'none', fontSize: 12 }}>click above to start ordering</div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className='label' style={{  marginTop: 6 }}>{isCredit ? (currentBalance < 0 ? 'Balance' : 'Currently Due') : (currentBalance < 0 ? 'Balance' : 'Overdue')}</div>
                        <div className={'balance ' + (currentBalance < 0 ? 'balance-negative' : 'balance-positive')}>
                          {lzGetFormattedAmount(Math.abs(currentBalance), true)}
                          {callReloadBalance &&
                            <small> <LzIcon name="refresh" onClick={() => callReloadBalance()}></LzIcon></small>
                          }
                        </div>
                        <LzButton style={{ position: 'absolute', right: 10 }} label="Pay Now" size="sm" onClick={() => this.setState({ payNowShow: true })} />
                        <div style={{ marginTop: 12, height: 16 }}>
                          {isHalted && <div style={{ marginTop: 9, textAlign: 'left' }} className='tag-delivery-halted'>Delivery Halted!</div>}
                        </div>
                        {/*<div style={{ height: 12, marginTop: 9, clear: 'both' }}>
                          <div style={{ float: 'left', textAlign: 'left' }}>
                            {isHalted ?
                              <div className='tag-delivery-halted'>Delivery Halted!</div>
                              :
                              <div className='tag-delivery-active' style={{ display: 'none' }}>Delivery Active!</div>
                            }
                          </div>
                          <div style={{ float: 'right', textAlign: 'right', fontWeight: 'bold' }} className='label'>
                            {isCredit ? 'Credit' : 'Advance'} Mode
                          </div>
                        </div>*/}
                      </React.Fragment>
                    }
                  </div>
                  <hr className='lz-balance-box-bot-hr' />
                </React.Fragment>
              }

              {(isGuest && lockForGuests) ?
                <div style={{
                  pointerEvents: 'none',
                  opacity: 0.4,
                }}>
                  {children}
                </div>
                :
                <React.Fragment>{children}</React.Fragment>
              }

            </div>
          </div>

          <LzBotIconsBar isGuest={isGuest} activeTabKey={activeTabKey} />

          {payNowShow &&
            <Modal show={payNowShow}>
              <Modal.Header closeButton>
                <Modal.Title>Make Payment</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <LzFormFieldInput required fluid type="number" label="Enter amount to make payment for"
                  name="amount" value={payNowAmount} onChange={(_, val) => this.setState({ payNowAmount: +val })} />
              </Modal.Body>

              <Modal.Footer>
                {payNowLoading ?
                  <Button variant="primary" disabled><LzIcon pulse color="white" name="spinner" /> Processing...</Button>
                  :
                  <Button variant="primary" onClick={() => this.payNowProceed()}>Proceed</Button>
                }
                <Button variant="secondary" onClick={() => this.setState({ payNowShow: false })}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          }

        </div >

      </React.Fragment>
    );
  }

}

export default PrivateLayoutMain;
