import React from 'react';
import PublicLayout from "../../../layouts/public";
import RegisterFormComponent from "./register-form";

class RegisterScreen extends React.Component {

  render() {
    return (
      <PublicLayout hidePoweredBy>

        <RegisterFormComponent />

      </PublicLayout>
    );
  }

}

export default RegisterScreen;
