import React from 'react';
import PublicLayout from '../../../../../../common/components/layouts/public';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { connect } from 'react-redux';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldSelect from '../../../../../../common/components/commons/fields/select';
import LzOptionModel from '../../../../../../common/models/option';
import { Container } from 'react-bootstrap';
import { CompanyConstants } from '../../../../Constants';
import { AppConstants } from '../../../../../../common/services/Constants';
import LzFormFieldTexarea from '../../../../../../common/components/commons/fields/textarea';

class RegisterScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      xxcompany_id: 18,
      xxfullname: '',
      xxphonenum: '',
      xxusername: '',
      xxpassword: '',
      xxaddress: '',
      xxzone_id: 0,
      zones: [],
    };

    this.companies = LzOptionModel.parseArray(CompanyConstants.companies, 'id', 'name');
  }

  componentDidMount() {
    this.loadZones();
  }

  async loadZones() {
    const { xxcompany_id } = this.state;

    if (0 === xxcompany_id) {
      return;
    }

    AppConstants.app_company_id = xxcompany_id;

    const data = await lzCallApi('getZoneList');
    if (data) {
      this.setState({ zones: LzOptionModel.parseArray(data, 'zone_id', 'name') });
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      if ('xxcompany_id' === name) {
        this.loadZones();
      }
    });
  };

  onSubmit() {
    this.callRegisterApi();
  }

  async callRegisterApi() {
    const { setSuccessMessage } = this.props;
    const { xxfullname, xxphonenum, xxusername, xxpassword, xxaddress, xxzone_id } = this.state;

    // Make the api call and get processed data
    const data = await lzCallApi('customerSignUP', {
      fullName: xxfullname,
      cell_no_1: xxphonenum,
      userName: xxusername,
      email: xxusername,
      password: xxpassword,
      address: xxaddress,
      zone_id: xxzone_id,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your account was successfully registered and is under approval! You will be notified via sms when it is ready for login.');
    }
  }

  render() {
    const { xxfullname, xxphonenum, xxusername, xxpassword, xxaddress, xxzone_id, zones } = this.state;

    return (
      <PublicLayout>
        <div className="lz-form-page">
          <Container>
            <LzForm className="form" onSubmit={() => this.onSubmit()}>

              <h2>Create Account!</h2>

              {/*<LzFormFieldSelect fluid required firstOptionText="Select Franchise..." options={this.companies}
                                name="xxcompany_id" value={xxcompany_id} onChange={this.handleInputChange} />*/}

              <LzFormFieldSelect fluid required firstOptionText="Select Delivery Zone..." options={zones}
                name="xxzone_id" value={xxzone_id} onChange={this.handleInputChange} />

              <LzFormFieldInput fluid required type="text" hint=" " placeholder="Full Name"
                name="xxfullname" value={xxfullname} onChange={this.handleInputChange} />

              <LzFormFieldInput fluid required type="text" hint=" " placeholder="Phone No."
                name="xxphonenum" value={xxphonenum} onChange={this.handleInputChange} />

              <LzFormFieldInput fluid required type="email" hint=" " placeholder="Username"
                name="xxusername" value={xxusername} onChange={this.handleInputChange} />

              <LzFormFieldInput fluid required type="password" hint=" " placeholder="Password"
                name="xxpassword" value={xxpassword} onChange={this.handleInputChange} />

              <LzFormFieldTexarea fluid hint=" " placeholder="Address"
                name="xxaddress" value={xxaddress} onChange={this.handleModelChange} />

              <LzButton fluid type="submit" variant="primary" icon="send" label="Send Request" />

            </LzForm>

            <div style={{ textAlign: "center" }}>
              <Link to={'/login'}>already registered? click here</Link>
            </div>
          </Container>
        </div>
      </PublicLayout >
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RegisterScreen);
