import { actions } from '../actions';
import UserModel from "../../models/user";
import { lzGetFromLocalStorage, lzSaveToLocalStorage } from '../../services/LzLocalStorage';
import { AppConstants } from '../../services/Constants';

// Before setting defaultState, if company id is 0 then we must load the company id 
// from storage if it was saved there already, so that auth data from storage is loaded correct.
if (0 === +AppConstants.app_company_id) {
    AppConstants.app_company_id = lzGetFromLocalStorage('companyId', 0, true);
    console.log('Loaded Company ID from storage!', AppConstants.app_company_id);
}

const defaultState = {
    // Logged in user object
    user: new UserModel(lzGetFromLocalStorage('authUser')),

    // Logged in user token
    token: lzGetFromLocalStorage('authToken'),

    // Loaded fresh data for this window or not
    loaded: false,

    // Verification code variable used to auto-pick from mobile sms and fill the form field
    code: '',

    // Guest login id
    guestLoginId: +lzGetFromLocalStorage('guestLoginId', 0),
};

const auth = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SET_AUTH_USER:
            lzSaveToLocalStorage('authUser', action.remember ? action.value : '');
            return Object.assign({}, state, { user: action.value });

        case actions.SET_AUTH_TOKEN:
            lzSaveToLocalStorage('authToken', action.remember ? action.value : '');
            return Object.assign({}, state, { token: action.value });

        case actions.SET_AUTH_LOADED:
            return Object.assign({}, state, { loaded: action.value });

        case actions.SET_GUEST_LOGIN_ID:
            lzSaveToLocalStorage('guestLoginId', +action.value);
            return Object.assign({}, state, { guestLoginId: +action.value });

        default:
            return state;

    }

};

export default auth;
