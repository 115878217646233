import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import PublicLayout from "../../../../common/components/layouts/public";
import LzDimmer from '../../../../common/components/commons/dimmer';
import { setAuthLoaded, setGuestLoginId, setLastRoute } from '../../../../common/redux/actions';
import { lzCallApi } from '../../../../common/services/LzApiService';
import PrivateLayoutMain from './private-layout-main';
import PrivateLayoutSettings from './private-layout-settings';
import PrivateLayoutPlan from './private-layout-plan';
import { lzGetLoggedInUserId } from '../../../../common/services/LzUtils';
import GuestContinueSignUpComponent from '../screens/public/user/guest-register';

class PrivateLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customerId: 0,
      customerName: '',
      currentBalance: 0,
      isGuest: false,
      showGuestSignUp: false,
      isHalted: false,
      isCredit: false,
    };
  }

  componentDidMount() {
    this.saveLastRoute();
    if (this.checkLogin()) {
      this.load();
    }
  }

  componentDidUpdate(prevProps) {
    const { authToken } = this.props;

    // Check login if auth token has changed
    if (prevProps.authToken !== authToken) {
      this.checkLogin();
    }
  }

  async load() {
    const { hideBalanceBox, onCustomerBalanceLoad } = this.props;

    if (hideBalanceBox) {
      return;
    }

    const loggedIn = lzGetLoggedInUserId();

    // Make the api call and get the data
    const data = await lzCallApi('dashboard', {
      client_id: loggedIn.id,
    });

    // Load data if call was successful
    if (data && data.customerName) {
      this.setState({
        customerId: loggedIn.id,
        customerName: data.customerName,
        currentBalance: +data.currentBalance,
        isGuest: loggedIn.is_guest,
        isHalted: 1 === data.is_halted,
        isCredit: 'credit' === data.account_type,
      });

      if (onCustomerBalanceLoad) {
        onCustomerBalanceLoad(data.customerName, +data.currentBalance);
      }
    }
  }

  saveLastRoute() {
    const { history, setLastRoute } = this.props;
    setLastRoute(history?.location?.pathname || '');
  }

  checkLogin() {
    const { authToken, authLoaded, guestLoginId } = this.props;

    // console.log('CHECKING LOGIN', authToken, authLoaded, guestLoginId);

    // If a guest user was logged in then we skip with true
    if (guestLoginId > 0) {
      return true;
    }

    // Check for logged in user
    if (!authToken || '' === authToken) {
      // TODO: Save current screen location to be redirected to after login/register
      this.props.setAuthLoaded(false);
      this.props.history.replace('/landing');
      return false;
    }

    // Check if logged in but fresh data not loaded for this window
    if (!authLoaded) {
      // Call the load api and set loaded to true.
      // lzCallApi('load-auth-data');
      // TODO: Enable when ready
      this.props.setAuthLoaded(true);
    }

    return true;
  }

  onExistGuestMode() {
    this.props.setGuestLoginId(0);
    this.props.history.replace('/landing');
  }

  render() {
    const { customerId, customerName, currentBalance, isGuest, showGuestSignUp, isHalted, isCredit } = this.state;
    const { layout, activeTabKey, screenTitle, backLink, hideBalanceBox, planLayoutSideContent, planLayoutTopBarRightWide,
      children, showLoading, noBackground, lockForGuests, className, authUser } = this.props;

    return (
      <PublicLayout noBackground={noBackground} className={className}>

        {!showLoading ?
          <React.Fragment>

            {(!layout || 'main' === layout) &&
              <PrivateLayoutMain
                authUser={authUser}
                isGuest={isGuest}
                lockForGuests={lockForGuests}
                customerName={customerName}
                currentBalance={currentBalance}
                activeTabKey={activeTabKey}
                hideBalanceBox={hideBalanceBox}
                isHalted={isHalted}
                isCredit={isCredit}
                callReloadBalance={() => this.load()}
                onContinueGuestSignUp={() => this.setState({ showGuestSignUp: true })}
                onExistGuestMode={() => this.onExistGuestMode()}
              >
                {children}
              </PrivateLayoutMain>
            }

            {'settings' === layout &&
              <PrivateLayoutSettings activeTabKey={activeTabKey} screenTitle={screenTitle} backLink={backLink}>
                {children}
              </PrivateLayoutSettings>
            }

            {'plan' === layout &&
              <PrivateLayoutPlan
                screenTitle={screenTitle}
                customerName={customerName}
                currentBalance={currentBalance}
                activeTabKey={activeTabKey}
                hideBalanceBox={hideBalanceBox}
                sideContent={planLayoutSideContent}
                topBarRightWide={planLayoutTopBarRightWide}
              >
                {children}
              </PrivateLayoutPlan>
            }

          </React.Fragment>
          :
          <LzDimmer active={true} style={{ zIndex: 999 }}>
            <Spinner animation="border" />
          </LzDimmer>
        }

        {showGuestSignUp &&
          <GuestContinueSignUpComponent customerId={customerId} onClose={() => this.setState({ showGuestSignUp: false })} />
        }

      </PublicLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    authToken: state.auth.token,
    authLoaded: state.auth.loaded,
    guestLoginId: state.auth.guestLoginId,
    loading: state.commons.loading,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setAuthLoaded: (value) => dispatch(setAuthLoaded(value)),
    setLastRoute: (value) => dispatch(setLastRoute(value)),
    setGuestLoginId: (value) => dispatch(setGuestLoginId(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateLayout));
