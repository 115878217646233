import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import PublicLayout from "../../../layouts/public";
import UserModel from '../../../../../../common/models/user';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import { setAuthToken, setAuthUser, setErrorMessage, setGuestLoginId } from '../../../../../../common/redux/actions';
import LzFormFieldSwitch from '../../../../../../common/components/commons/fields/switch';
import { lzGetLoggedInUserId } from '../../../../../../common/services/LzUtils';
import { callFCMTokenLoadOnHost } from '../../../../../../common/services/LzFirebaseCloudMessaging';
import { lzGetFromLocalStorage, lzSaveToLocalStorage } from '../../../../../../common/services/LzLocalStorage';
import TermsAndConditionsModal from '../../private/help/pop-terms';
import { runOnHost } from '../../../../../../common/services/LzHostInterface';

class LoginScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      xxusername: '',
      xxpassword: '',
      xxremember: true, // to save credentials by default and avoid login every time
      showTerms: false,
      showTermsInit: !lzGetFromLocalStorage('init_terms_accepted', false),
    };
  }

  componentDidMount() {
    if (lzGetLoggedInUserId().id > 0) {
      this.props.history.replace('/');
    }
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit() {
    this.callLoginApi();
  }

  async callLoginApi() {
    const { setAuthUser, setAuthToken, history } = this.props;
    const { xxusername, xxpassword, xxremember } = this.state;

    const fcmToken = lzGetFromLocalStorage('fcm_token', '')

    // Make the api call and get processed data
    const data = await lzCallApi('login', {
      userName: xxusername,
      password: xxpassword,
      messaging_token: fcmToken,
      type: 'Android',
    }, 'post');

    // Validate data and use it
    if (data && data.client_id) {
      // Create new auth user object
      const user = new UserModel(data);

      // Set to store (and local storage if opted to)
      setAuthUser(user, xxremember);
      setAuthToken(data.client_id, xxremember);

      // If fcm token is not registered already, then call host to get it
      if ('' == fcmToken) {
        callFCMTokenLoadOnHost();
      }

      console.log('REDIRECTING...');
      history.replace('/');
      // used replace instead of push to remove login from browser history
      // and make it go to exit if back pressed
    }
  }

  async continueAsGuest() {
    const { setGuestLoginId, history } = this.props;

    // Make the api call and get processed data
    const data = await lzCallApi('createGuestLogin');

    if (data) {
      setGuestLoginId(data);
      history.replace('/'); // same as login
    }
  }

  closeApp() {
    runOnHost(
      () => {
        window.Android.callExitApp();
      },
      () => {
        this.props.history.replace('/terms-rejected-landing');
      },
      () => {
        window.location.href = 'https://www.google.com/';
      },
    );
  }

  acceptTermsInit() {
    this.setState({ showTermsInit: false });
    lzSaveToLocalStorage('init_terms_accepted', true);
  }

  render() {
    const { xxusername, xxpassword, xxremember, showTerms, showTermsInit } = this.state;

    return (
      <PublicLayout verticallySpreaded>

        <Container>
          <LzForm className="form" onSubmit={() => this.onSubmit()}>

            <LzFormFieldInput required fluid disableAutocomplete type="text"
              label="" placeholder="username" hint=" "
              name="xxusername" value={xxusername}
              onChange={this.handleInputChange}
            />

            <LzFormFieldInput required fluid disableAutocomplete type="password"
              label="" placeholder="password" hint=" "
              name="xxpassword" value={xxpassword}
              onChange={this.handleInputChange}
            />

            <LzFormFieldSwitch
              asSwitch
              size="md"
              align="right"
              label="Keep me signed in"
              name="xxremember"
              value={xxremember}
              onChange={this.handleInputChange}
            />

            <LzButton fluid type="submit" variant="primary"
              icon="" label="Login" style={{ marginTop: 15 }}
            />

            {/*<LzButton fluid variant="secondary" icon="" label="Continue as Guest"
              style={{ marginTop: 25 }} onClick={() => this.continueAsGuest()} />*/}

            <Link to={'/register'} style={{ textDecoration: 'none' }}><LzButton fluid type="button" variant="secondary" icon="" label="Create Account" style={{ marginTop: 5 }} /></Link>

            <div onClick={() => this.setState({ showTerms: true })} style={{ marginTop: 20, textAlign: 'center', textDecoration: 'underline', color: 'green' }}>Terms & Conditions</div>

            <TermsAndConditionsModal
              show={showTerms}
              onHide={() => this.setState({ showTerms: false })}
            />

            <TermsAndConditionsModal
              show={showTermsInit}
              onHide={() => this.closeApp()}
              onAccept={() => this.acceptTermsInit()}
              onReject={() => this.closeApp()}
            />

          </LzForm>

        </Container>

        <div style={{ textAlign: "center" }}>
          <Link to={'/forgot-password'}>Forgot your password?</Link>
        </div>

      </PublicLayout>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setAuthUser: (value, remember) => dispatch(setAuthUser(value, remember)),
    setAuthToken: (value, remember) => dispatch(setAuthToken(value, remember)),
    setGuestLoginId: (value) => dispatch(setGuestLoginId(value)),
    setErrorMessage: (value) => dispatch(setErrorMessage(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginScreen);
