export const actions = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
    SET_LAST_ROUTE: 'SET_LAST_ROUTE',
    SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
    SET_AUTH_USER: 'SET_AUTH_USER',
    SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
    SET_AUTH_LOADED: 'SET_AUTH_LOADED',
    SET_GUEST_LOGIN_ID: 'SET_GUEST_LOGIN_ID',
    SHOW_SIDENAV: 'SHOW_SIDENAV',
    HIDE_SIDENAV: 'HIDE_SIDENAV',
    SET_SIDENAV: 'SET_SIDENAV',
    TOGGLE_SIDENAV: 'TOGGLE_SIDENAV',
    SET_PRODUCT: 'SET_PRODUCT',
};

export const setLoading = (value) => {
    return {
        type: actions.SET_LOADING,
        value: value,
    };
};

export const setErrorMessage = (value) => {
    return {
        type: actions.SET_ERROR_MESSAGE,
        value: value,
    };
};

export const setSuccessMessage = (value) => {
    return {
        type: actions.SET_SUCCESS_MESSAGE,
        value: value,
    };
};

export const setLastRoute = (value) => {
    return {
        type: actions.SET_LAST_ROUTE,
        value: value,
    };
};

export const setCurrentLocation = (value) => {
    return {
        type: actions.SET_CURRENT_LOCATION,
        value: value,
    };
};

export const setAuthUser = (value, remember) => {
    return {
        type: actions.SET_AUTH_USER,
        value: value,
        remember: remember,
    };
};

export const setAuthToken = (value, remember) => {
    return {
        type: actions.SET_AUTH_TOKEN,
        value: value,
        remember: remember,
    };
};

export const setAuthLoaded = (value) => {
    return {
        type: actions.SET_AUTH_LOADED,
        value: value,
    };
};

export const setGuestLoginId = (value) => {
    return {
        type: actions.SET_GUEST_LOGIN_ID,
        value: value,
    };
};

export const setSideNav = (value) => {
    return {
        type: actions.SET_SIDENAV,
        value: value,
    };
};

export const toggleSideNav = () => {
    return {
        type: actions.TOGGLE_SIDENAV,
    };
};

export const setProduct = (value) => {
    return {
        type: actions.SET_PRODUCT,
        value: value,
    };
};

