import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { Container } from 'react-bootstrap';
import LzButton from '../../../../../../common/components/commons/button';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldSelect from '../../../../../../common/components/commons/fields/select';
import LzFormFieldTexarea from '../../../../../../common/components/commons/fields/textarea';
import LzOptionModel from '../../../../../../common/models/option';

class CustomerSupportScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            types: [],
            model: {},
        };
    }

    componentDidMount() {
        this.loadComplainTypes();
    }

    async loadComplainTypes() {
        // Make the api call and get the data
        const data = await lzCallApi('getComplainType', {
            client_id: this.props.authUser.client_id,
        }, 'post');

        // Load data if call was successful
        if (data) {
            this.setState({ types: LzOptionModel.parseArray(data, 'complain_type_id', 'name') });
        }
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;

        model[name] = value;

        this.setState({ model: model });
    };

    async saveComplaint() {
        const { model } = this.state;
        const { authUser, setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('createComplaints', {
            client_id: authUser.client_id,
            complain_type_id: model.type_id,
            query_text: model.detail,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your complaint was successfully submitted!');
        }
    }

    render() {
        const { model, types } = this.state;

        return (
            <PrivateLayout screenTitle="Customer Support">

                <Container>

                    <div className="lz-form-page" style={{ paddingTop: 20 }}>

                        <LzForm onSubmit={() => this.saveComplaint()}>

                            <LzFormFieldSelect fluid required label="Subject" options={types}
                                name="type_id" value={model.type_id} onChange={this.handleModelChange} />

                            <LzFormFieldTexarea required fluid label="Complaint" rows={5}
                                name="detail" value={model.detail} onChange={this.handleModelChange} />

                            <LzButton fluid type="submit" icon="save" label="Submit Complaint" />

                        </LzForm>

                        <h3 style={{ textAlign: "center", fontWeight: "bold", color: "black" }}>
                            <br />
                            OR<br />
                            CALL US<br />
                            03 000 73 7777<br />
                            031 3333 42 62<br />
                        </h3>

                    </div>

                </Container>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSupportScreen);
