import React from 'react';
import { connect } from 'react-redux';
import { lzGetCurrentLocation } from '../../../../../../common/services/LzUtils';
import LzGoogleMap from '../../../../../../common/components/commons/google-map';
import LzIcon from '../../../../../../common/components/commons/icon';
import LzButton from '../../../../../../common/components/commons/button';

class RegisterFormMapComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fullScreen: false,
      lat: props.center?.lat && props.center.lat !== 0 ? props.center.lat : props.currentLocation?.latitude || 0,
      lng: props.center?.lng && props.center.lng !== 0 ? props.center.lng : props.currentLocation?.longitude || 0,
      address: '',
    };
  }

  componentDidMount() {
    lzGetCurrentLocation(false);
  }

  componentDidUpdate(prevProps) {
    const { center, currentLocation } = this.props;

    // If provided location changed and is a valid value then use it with high priority
    if (center !== prevProps.center && center && center.lat !== 0 && center.lng !== 0) {
      this.setState({ lat: center.lat, lng: center.lng });
      return;
    }

    // If location was not provided or it does not exist then attemp to load current location if it exists
    if (currentLocation !== prevProps.currentLocation) {
      this.setState({
        lat: currentLocation?.latitude || 0,
        lng: currentLocation?.longitude || 0,
      });
    }
  }

  render() {
    const { fullScreen, lat, lng, address } = this.state;

    console.log('RENDERING-REG-FORM-MAP', lat, lng, address);

    return (
      <React.Fragment>

        {fullScreen ?
          <div className="register-full-map">
            <div className="map-container">
              <LzGoogleMap
                draggable
                zoomControl
                showAddressMarkerBig
                center={{ lat: lat, lng: lng }}
                onCenterChanged={(changes) => this.setState({ lat: changes.lat, lng: changes.lng, address: changes.address })}
              />
            </div>
            <div className="buttons-container">
              <LzButton fluid variant="light" label="Cancel" onClick={this.props.onClose} />
              <LzButton fluid label="Pick Location" onClick={() => this.props.onChange(lat, lng)} />
            </div>
          </div>
          :
          <div className="register-peek-map">
            <div className="overlay" onClick={this.props.onClose}></div>
            <div className="content">
              <h4>Please confirm your location on map:</h4>
              <div className="map-container">
                <LzGoogleMap
                  showCenterMarker
                  center={{ lat: lat, lng: lng }}
                  onAddressUpdated={(newAddress) => this.setState({ address: newAddress })}
                />
              </div>
              <h2>Current Location</h2>
              <div>
                <LzIcon name="map-marker" style={{ marginRight: 6 }} /> {Number(lat).toFixed(6)}°N - {Number(lng).toFixed(6)}°E
                <div style={{ paddingTop: 5, color: '#444444' }}>{address}</div>
              </div>
              <LzButton fluid variant="link" label="+ Select a different location" onClick={() => this.setState({ fullScreen: true })} />
              <LzButton fluid label="Confirm Location" onClick={() => this.props.onChange(lat, lng)} />
            </div>
          </div>
        }

      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    currentLocation: state.commons.currentLocation,
  }
};

export default connect(
  mapStateToProps,
)(RegisterFormMapComponent);
