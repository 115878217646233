import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import { Container } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';

class UpdatePlanIntervalHaltScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            model: {},
        };
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    async haltIntervalPlan() {
        const { model } = this.state;
        const { authUser, setSuccessMessage, setErrorMessage, product, history } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get processed data
        const data = await lzCallApi('haltIntervalRegularOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            halt_start_date: model.haltFromDate,
            halt_end_date: model.haltToDate,
            halt_flag: 2,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully halted!');
        }
    }

    render() {
        const { model } = this.state;
        const { history } = this.props;

        return (
            <PrivateLayout screenTitle="Halt Interval" activeTabKey="plan">

                <div className="lz-form-page lz-plan-form">

                    <Container>

                        <LzForm onSubmit={() => this.haltIntervalPlan()}>

                            <LzFormFieldInput required fluid type="date" label="Halt From"
                                name="haltFromDate" value={model.haltFromDate} onChange={this.handleModelChange} />

                            <LzFormFieldInput required fluid type="date" label="Halt To"
                                name="haltToDate" value={model.haltToDate} onChange={this.handleModelChange} />

                            <LzButton fluid type="submit" icon="save" label="Halt Delivery" />

                            <LzButton fluid type="button" variant="secondary" icon="arrow-left" label="Go Back" onClick={() => history.goBack()} />

                        </LzForm>

                    </Container>

                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanIntervalHaltScreen));
