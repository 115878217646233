import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { createStore } from "redux";
import { Provider } from 'react-redux';
import reducer from "./redux/reducers";
import { isAndroid, isIOS } from "./services/LzHostInterface";

import LandingScreen from './components/screens/public/commons/landing';
import LogoutScreen from './components/screens/private/user/logout';
import LoginScreen from './components/screens/public/user/login';
import UserDashboardScreen from './components/screens/private/user/dashboard';
import AboutApplicationScreen from './components/screens/public/commons/about-application';
import ChangePasswordScreen from './components/screens/private/user/change-password';
import UpdateProfileScreen from './components/screens/private/user/update-profile';
import ForgotPasswordScreen from './components/screens/public/user/forgot-password';

window.reduxStore = createStore(reducer);

export const routing = (
    <Provider store={window.reduxStore}>
        <Router>
            <div>
                <Route path="/landing" component={LandingScreen} />
                <Route path="/login" component={LoginScreen} />
                <Route path="/forgot-password" component={ForgotPasswordScreen} />
                <Route path="/about-application" component={AboutApplicationScreen} />

                <Route path="/" component={UserDashboardScreen} exact />
                <Route path="/profile" component={UpdateProfileScreen} />
                <Route path="/password" component={ChangePasswordScreen} />
                <Route path="/logout" component={LogoutScreen} />

                {/* this below rule will send to / initially even if a url like /user-login is loaded in the browser */}
                {(isAndroid() || isIOS()) && <Route path="*" render={() => (<Redirect to="/" />)} />}
            </div>
        </Router>
    </Provider>
);
