import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import UserModel from '../../../../../../common/models/user';
import PrivateLayout from '../../../layouts/private';
import LzForm from '../../../../../../common/components/commons/form';
import LzButton from '../../../../../../common/components/commons/button';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzFormFieldStatic from '../../../../../../common/components/commons/fields/static';
import { Link } from 'react-router-dom';

class UpdateProfileScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      model: null,
    };
  }

  componentDidMount() {
    this.callLoadProfileApi();
  }

  async callLoadProfileApi() {
    // Make the api call and get the data
    const data = await lzCallApi('getCustomerProfile', {
      client_id: this.props.authUser.client_id,
    }, 'post');

    // Load data if call was successful
    if (data && data.profile) {
      this.setState({
        model: new UserModel(data.profile),
      });
    }
  }

  render() {
    const { model } = this.state;

    return (
      <PrivateLayout screenTitle="Profile" backLink="/account">

        {model && <div className="lz-form-page">

          <Container>
            <LzForm>

              <LzFormFieldStatic fluid label="Full Name" value={model.fullname} />

              <LzFormFieldStatic fluid label="Phone No." value={model.cell_no_1} />

              <LzFormFieldStatic fluid label="Email Address" value={model.email} />

              <LzFormFieldStatic fluid label="CNIC" value={model.cnic} />

              <LzFormFieldStatic fluid label="Zone" value={model.zone_name} />

              <LzFormFieldStatic fluid label="Address" value={model.address} />

              <Link to={'/customer-support'}><LzButton fluid size="sm" type="button" icon="pencil" label="Request Change" /></Link>

            </LzForm>
          </Container>

        </div>}

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProfileScreen);
