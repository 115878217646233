import { actions } from '../actions';

const defaultState = {
    product: null,
};

const plan = (state = defaultState, action) => {

    switch (action.type) {

        case actions.SET_PRODUCT:
            return Object.assign({}, state, { product: action.value });

        default:
            return state;

    }

};

export default plan;
