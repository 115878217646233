import React from 'react';
import { lzGetAssetUrl } from '../../../../common/services/LzUtils';
import ImgLogo from '../../assets/images/logo-white.png';
import LzSpinnerComponent from './spinner';

class LoadingComponent extends React.Component {

  render() {
    const { show } = this.props;

    return (
      <div
        className="gradient-bg1"
        style={{
          display: show ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >

        <img
          src={lzGetAssetUrl(ImgLogo)}
          alt='Logo'
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '60vw',
            maxHeight: '40vw',
            marginBottom: '7vh',
          }}
        />

        <LzSpinnerComponent />

      </div>
    );
  }

}

export default LoadingComponent;
