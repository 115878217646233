import React from 'react';
import PrivateLayout from '../../../layouts/private';

class FAQsScreen extends React.Component {

  render() {
    const ifUrl = 'https://dairydelivery.conformiz.com/MobileAppDocs/FAQs';

    return (
      <PrivateLayout hideBalanceBox screenTitle="FAQs">

        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 0,
            scrollbarWidth: 5,
          }}
          src={ifUrl}
        ></iframe>

      </PrivateLayout>
    );
  }

}

export default FAQsScreen;
