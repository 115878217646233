import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setAuthLoaded, setErrorMessage, setLoading } from '../../redux/actions';
import { lzGetAssetUrl } from '../../services/LzUtils';
import Spinner from 'react-bootstrap/Spinner';
import PublicLayout from "./public";
import LzSideNav from "./partials/sidenav";
import LzTopBar from "./partials/topbar";
import ImgIcon from '../../assets/images/icon.png';
import ImgLogo from '../../assets/images/logo.png';
import LzDimmer from '../commons/dimmer';

class PrivateLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sideNavItems: this.getSideNavItems(props.authUser),
        };
    }

    componentDidMount() {
        this.checkLogin();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authToken, authUser } = this.props;

        // Check login if auth token has changed
        if (prevProps.authToken !== authToken) {
            this.checkLogin();
        }

        // If auth user has changed then re-build side-nav
        if (prevProps.authUser !== authUser) {
            // Re-build side-nav
            this.setState({ sideNavItems: this.getSideNavItems(authUser) });
        }
    }

    checkLogin() {
        const { authToken, authLoaded } = this.props;

        // console.log('CHECKING LOGIN', authToken, authLoaded);

        // Check for logged in user
        if (!authToken || '' === authToken) {
            // TODO: Save current screen location to be redirected to after login/register
            this.props.setAuthLoaded(false);
            this.props.history.replace('/landing');
        }

        // Check if logged in but fresh data not loaded for this window
        if (!authLoaded) {
            // Call the load api and set loaded to true.
            // lzCallApi('load-auth-data');
            // TODO: Enable when ready
            this.props.setAuthLoaded(true);
        }
    }

    getSideNavItems(authUser) {
        const items = [
            { icon: 'dashboard', title: 'Dashboard', route: '/' },
            { icon: 'cog', title: 'Manage Orders', route: '/plan' },
            { icon: 'th-list', title: 'Delivery History', route: '/deliveries-history' },
            { icon: 'support', title: 'Customer Support', route: '/customer-support' },
        ];

        // console.log('NAV', authUser, items);

        return items;
    }

    render() {
        const { sideNavItems } = this.state;
        const { screenTitle, children, showLoading, authUser } = this.props;

        // console.log('AUTHUSER', authUser);

        return (
            <PublicLayout>

                {(authUser && !showLoading) ?
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "hidden",
                    }}>

                        <LzSideNav
                            headerClose={<img src={lzGetAssetUrl(ImgIcon)} alt='Icon' />}
                            headerOpen={<img src={lzGetAssetUrl(ImgLogo)} alt='Logo' />}
                            items={sideNavItems}
                        />

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                        }}>
                            <LzTopBar />

                            <div className="lz-screen-main">
                                {screenTitle && <h1>{screenTitle}</h1>}
                                {children}
                            </div>

                        </div>

                    </div>
                    :
                    <LzDimmer active={true} style={{ zIndex: 999 }}>
                        <Spinner animation="border" />
                    </LzDimmer>
                }

            </PublicLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        authToken: state.auth.token,
        authLoaded: state.auth.loaded,
        loading: state.commons.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setAuthLoaded: (value) => dispatch(setAuthLoaded(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PrivateLayout));
