import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { lzCallApi } from '../../../../common/services/LzApiService';
import ProductModel from '../../../../common/models/product';
import LzButton from '../../../../common/components/commons/button';
import { lzGetFormattedAmount } from '../../../../common/services/LzUtils';
import LzIcon from '../../../../common/components/commons/icon';

class SelectProductComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.callLoadProductsApi();
  }

  async callLoadProductsApi() {
    // Make the api call and get the data
    const data = await lzCallApi('getAllProduct', {
      client_id: this.props.authUser?.client_id || 0,
    }, 'post');

    // Load data if call was successful
    if (data) {
      this.setState({
        products: ProductModel.parseArray(data),
      });
    }
  }

  render() {
    const { products } = this.state;
    const { product, onSelect } = this.props;

    return (
      <Container style={{ width: 'auto', marginLeft: -10, marginRight: -10, paddingLeft: 0, paddingRight: 0 }}>

        {products.map((prod, dex) =>
          <Row noGutters key={'sel-prod-' + dex}
            className={'lz-product-selex' + (product?.product_id === prod.product_id ? ' active' : '')}
          >
            <Col xs={2} className="col-avatar" onClick={() => onSelect(prod, 'detail')}>
              <img src={prod.product_image} alt="prod" />
            </Col>
            <Col xs={4} style={{ flexDirection: 'column' }} className="col-info" onClick={() => onSelect(prod, 'detail')}>
              <div className='title'>{prod.product_name}</div>
              <div className='description'>{prod.description}</div>
              <div className='price'>{lzGetFormattedAmount(prod.price)}</div>
            </Col>
            <Col xs={6} className="col-icons">
              <div className='price'>{lzGetFormattedAmount(prod.price)}</div>
              <LzButton fluid size='sm' onClick={() => onSelect(prod, 'onetime')}><LzIcon name='flash' /><small>1time</small></LzButton>
              <LzButton fluid size='sm' onClick={() => onSelect(prod)}><LzIcon name='calendar' /><small>plan</small></LzButton>
            </Col>
          </Row>
        )}

      </Container>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(SelectProductComponent);
