import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { AppConstants } from '../../services/Constants';
import LzIcon from './icon';

class LzGoogleMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
        };
    }

    onReady(mapProps, map, event) {
        this.evaluateAddress(mapProps);
    }

    onDragEnd(mapProps, map) {
        const { onCenterChanged } = this.props;
        if (onCenterChanged) {
            const center = map.getCenter();
            const changes = {
                lat: center.lat(),
                lng: center.lng(),
            };
            onCenterChanged(changes);
            this.evaluateAddress(mapProps, changes);
        }
    }

    evaluateAddress(mapProps, optCenter) {
        const { onAddressUpdated } = this.props;
        const { google } = mapProps;
        const center = typeof optCenter !== 'undefined' ? optCenter : this.props.center;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: new google.maps.LatLng(center.lat, center.lng) }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results && results.length > 0) {
                    this.setState({ address: results[0].formatted_address || '' }, () => {
                        if (onAddressUpdated) {
                            onAddressUpdated(this.state.address);
                        }
                    });
                }
            } else {
                console.error('Geo-coding failure:', status);
            }
        });
    }

    render() {
        const { address } = this.state;
        const {
            google, zoom, style, center, draggable, zoomControl, mapTypeControl, fullscreenControl,
            streetViewControl, showCenterMarker, showAddressMarkerBig
        } = this.props;

        const theStyle = style || { width: '100%', height: '100%' };
        if (!theStyle.width) { theStyle.width = '100%'; }
        if (!theStyle.height) { theStyle.height = '100%'; }

        console.log('MAPS API KEY:', AppConstants.google_maps_api_key);

        return (
            <React.Fragment>
                <Map
                    google={google}
                    zoom={zoom || 14}
                    style={theStyle}
                    containerStyle={{
                        position: 'relative',
                        width: '100%',
                        height: '100%'
                    }}
                    initialCenter={center}
                    center={center}
                    draggable={draggable || false}
                    zoomControl={zoomControl || false}
                    mapTypeControl={mapTypeControl || false}
                    streetViewControl={streetViewControl || false}
                    fullscreenControl={fullscreenControl || false}
                    onReady={(mapProps, map, event) => this.onReady(mapProps, map, event)}
                    onDragend={(mapProps, map) => this.onDragEnd(mapProps, map)}
                >

                    {showCenterMarker &&
                        <Marker
                            name="Selected Location"
                            title=""
                        />
                    }

                </Map>

                {showAddressMarkerBig &&
                    <div className="google-map-big-address-marker">
                        <div className="content">
                            <b>
                                <LzIcon name="map-marker" style={{ marginRight: 6 }} />
                                {Number(center.lat).toFixed(6)}°N -
                                {Number(center.lng).toFixed(6)}°E
                            </b>
                            <div style={{ paddingTop: 5 }}>{address}</div>
                        </div>
                        <div className="marker"></div>
                    </div>
                }

            </React.Fragment>
        );
    }

}

export default GoogleApiWrapper({
    apiKey: (AppConstants.google_maps_api_key)
})(LzGoogleMap);
