import React from 'react';
import { lzGetAssetUrl } from '../../../../../../common/services/LzUtils';
import ImgLogo from '../../../../assets/images/logo-white.png';

class LandingScreen extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.props.history.replace('/login');
    }, 1000);
  }

  render() {
    return (
      <div
        className="gradient-bg1"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img
          src={lzGetAssetUrl(ImgLogo)}
          alt='Logo'
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '60vw',
            maxHeight: '40vw',
            marginBottom: '7vh',
          }}
        />
      </div>
    );
  }

}

export default LandingScreen;
