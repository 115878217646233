import React from 'react';
import Form from 'react-bootstrap/Form';
import LzIcon from '../../commons/icon';

class LzFormFieldBase extends React.Component {

    render() {
        const { fluid, inline, required, icon, id, name, style, label, hint, errorMessage, children } = this.props;

        const theName = name ? name : '';
        const theId = id ? id : 'id_' + theName;

        const styles = style || { display: fluid ? "block" : "inline-block" };

        return (
            <Form.Group
                controlId={theId}
                className='lz-input-field'
                style={styles}
            >

                {label && <Form.Label className='lz-label'>
                    {icon && <LzIcon name={icon} />}
                    {label}
                    {required ? <b> *</b> : ''}
                    {' '}
                    {inline ? children : ''}
                </Form.Label>}

                {inline ? '' : children}

                {hint !== '' && <Form.Text className="text-muted">{hint}</Form.Text>}

                {errorMessage !== '' && <Form.Text className="lz-danger lz-align-right">{errorMessage}</Form.Text>}

            </Form.Group>
        );
    }

}

export default LzFormFieldBase;
