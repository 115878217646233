export const lzIsObject = (input) => {
    return typeof input === 'object' && input !== null;
};

export const lzIsArray = (input) => {
    return Array.isArray(input);
};

export const lzArrayUnique = (arr) => {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
};

export const lzJsonToString = (object) => {
    return JSON.stringify(object);
};
