import React from 'react';
import PublicLayout from "../../../layouts/public";
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { connect } from 'react-redux';
import { setSuccessMessage } from '../../../../../../common/redux/actions';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzButton from '../../../../../../common/components/commons/button';
import { Container } from 'react-bootstrap';
import RegisterStepsComponent from './register-steps';
import { lzGetFromLocalStorage } from '../../../../../../common/services/LzLocalStorage';
import { Link } from 'react-router-dom';

class RegisterSMSCodeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      xxcell_phone_no: lzGetFromLocalStorage('regPhoneNo', ''),
      xxclient_id: lzGetFromLocalStorage('regClientId', ''),
      xxcode: '',
      fieldErrors: {},
    };
  }

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value,                                                            // assign the value
      fieldErrors: Object.assign({}, this.state.fieldErrors, { [name]: '' }),   // clear the error
    });
  };

  onClickSubmit() {
    const { xxcode } = this.state;

    let isError = false;
    const fieldErrorsUpdate = Object.assign({}, this.state.fieldErrors);

    if ('' === xxcode) {
      fieldErrorsUpdate.xxcode = 'please enter validation code...';
      isError = true;
    }

    if (isError) {
      this.setState({ fieldErrors: fieldErrorsUpdate });
      return;
    }

    this.callVerifyApi();
  }

  async callVerifyApi() {
    const { setSuccessMessage, history } = this.props;
    const { xxclient_id, xxcode } = this.state;

    // Make the api call and get processed data
    const data = await lzCallApi('Customer_activation_by_code', {
      client_id: xxclient_id,
      code: xxcode,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your account is verified! Please continue to login.');
      history.replace('/login');
    }
  }

  async callResendApi() {
    const { setSuccessMessage } = this.props;
    const { xxclient_id } = this.state;

    // Make the api call and get processed data
    const data = await lzCallApi('resendCode', {
      client_id: xxclient_id,
    }, 'post');

    // Check if call was successful
    if (data) {
      setSuccessMessage('Your verification code is sent via sms again, please check!');
    }
  }

  render() {
    const { xxcell_phone_no, xxclient_id, xxcode, fieldErrors } = this.state;

    if ('' === xxcell_phone_no || '' === xxclient_id) {
      return (
        <PublicLayout hidePoweredBy>
          <RegisterStepsComponent step={3} />
          <Container>
            <LzForm className="form">

              <p className="danger" style={{
                textAlign: 'center',
                lineHeight: 1.2,
              }}>
                Your account settings are not saved on this device anymore, please continue using an option below:
              </p>

              <Link to={'/login'}><LzButton fluid variant="primary" icon="" label="Go to Login" /></Link>
              <Link to={'/register'}><LzButton fluid variant="primary" icon="" label="Go to Signup" /></Link>

            </LzForm>
          </Container>
        </PublicLayout >
      );
    }

    return (
      <PublicLayout hidePoweredBy>
        <RegisterStepsComponent step={3} />
        <Container>
          <LzForm className="form">

            <p style={{
              textAlign: 'center',
              lineHeight: 1.2,
            }}>
              We have sent the code<br />
              via SMS on {xxcell_phone_no}
            </p>

            <LzFormFieldInput fluid required type="text" hint=" " placeholder="Enter Code"
              hasError={!!fieldErrors.xxcode} errorMessage={fieldErrors.xxcode}
              name="xxcode" value={xxcode} onChange={this.handleInputChange} />

            <LzButton fluid variant="primary" icon="" label="Submit"
              onClick={() => this.onClickSubmit()}
              style={{ marginTop: 0, marginBottom: 15 }}
            />

            <p style={{
              textAlign: 'center',
              lineHeight: 1.2,
            }}>Please allow up to a minute for your sms to arrive!</p>

          </LzForm>
        </Container>

        <div className="bot-wizard-buttons" style={{ justifyContent: 'space-around' }}>
          <LzButton variant="primary" icon="" label="Resend Code" onClick={() => this.callResendApi()} />
        </div>
      </PublicLayout >
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RegisterSMSCodeScreen);
