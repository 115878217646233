import { lzDatesDiffInDays, lzGetDayOfWeek } from "../services/LzMoment";
import PlanIntervalModel from "./plan-interval";
import PlanWeeklyModel from "./plan-weekly";

export default class PlanModel {

    constructor(model) {
        if (!model) {
            model = {};
        }

        console.log('constructing PLAN with', model);

        this.start_date = model.start_date ? model.start_date : '';
        this.type = 'interval' === model.plan_type || 'interval' === model.type ? 'interval' : 'weekly';

        this.weekly = model.weekly ? model.weekly : null;
        if ('weekly' === this.type && model.plan_info) {
            this.weekly = new PlanWeeklyModel();
            this.weekly.parseArray(model.plan_info);
        }

        this.interval = model.interval ? model.interval : null;
        if ('interval' === this.type && model.plan_info) {
            this.interval = new PlanIntervalModel(model.plan_info);
        }

        this.delivered = model.delivered ? model.delivered : null;
        if (!this.delivered && model.already_delivered) {
            this.delivered = {};
            for (const row of model.already_delivered) {
                this.delivered[row.date] = +row.quantity;
            }
        }

        this.halted = model.halted ? model.halted : null;
        if (!this.halted && model.halted_dates) {
            this.halted = [];
            for (const key in model.halted_dates) {
                this.halted.push(model.halted_dates[key]);
            }
        }

        this.special = model.special ? model.special : null;
        if (!this.special && model.special_orders) {
            this.special = [];
            for (const row of model.special_orders) {
                this.special[row.date] = +row.quantity;
            }
        }
    }

    getPlannedQty(date) {
        if (date < this.start_date) {
            return 0;
        }

        if (this.weekly) {
            const wd = lzGetDayOfWeek(date);
            return this.weekly.days[wd] || 0;
        }

        if (this.interval) {
            const diff = lzDatesDiffInDays(this.start_date, date);
            return 0 === diff % this.interval.interval ? this.interval.quantity : this.interval.default_value;
        }

        return 0;
    }

}
