import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../../../redux/actions';
import { lzCallApi } from '../../../../services/LzApiService';
import PrivateLayout from '../../../layouts/private';
import LzForm from '../../../commons/form';
import LzFormFieldInput from '../../../commons/fields/input';
import LzButton from '../../../commons/button';

class ChangePasswordScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            cfmPassword: '',
        };
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    async callChangePasswordApi() {
        const { oldPassword, newPassword, cfmPassword } = this.state;
        const { setErrorMessage, setSuccessMessage } = this.props;

        // Validate input data
        if (newPassword !== cfmPassword) {
            setErrorMessage('The new password and confirm password do not match.');
            return;
        }

        // Make the api call and get processed data
        const data = await lzCallApi('password', {
            oldPassword: oldPassword,
            newPassword: newPassword,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your password is successfully changed!');
        }
    }

    render() {
        const { oldPassword, newPassword, cfmPassword } = this.state;

        return (
            <PrivateLayout screenTitle="Change Password">
                <div className="lz-form-page lz-form-page-full">
                    <Container>

                        <LzForm onSubmit={() => this.callChangePasswordApi()}>

                            <LzFormFieldInput required fluid type="password"
                                icon="lock" label="Old Password" placeholder="enter your old password"
                                name="oldPassword" value={oldPassword}
                                onChange={this.handleInputChange}
                            />

                            <LzFormFieldInput required fluid type="password"
                                icon="lock" label="New Password" placeholder="enter your new password"
                                name="newPassword" value={newPassword}
                                onChange={this.handleInputChange}
                            />

                            <LzFormFieldInput required fluid type="password"
                                icon="lock" label="Confirm Password" placeholder="confirm your new password"
                                name="cfmPassword" value={cfmPassword}
                                onChange={this.handleInputChange}
                            />

                            <LzButton fluid type="submit"
                                icon="save" label="Change Password"
                            />

                        </LzForm>

                    </Container>
                </div>
            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.commons.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordScreen);
