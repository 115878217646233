import { lzIsArray } from "../services/LzType";

export default class PlanWeeklyModel {

    static WEEKDAY_DEX = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
    };

    constructor(model) {
        if (!model) {
            model = {};
        }

        this.days = {};
    }

    parseArray(data) {
        if (!lzIsArray(data)) {
            return [];
        }

        const weekdays = Object.keys(PlanWeeklyModel.WEEKDAY_DEX);
        for (const item of data) {
            if (weekdays.indexOf(item.day_name) >= 0) {
                this.days[PlanWeeklyModel.WEEKDAY_DEX[item.day_name]] = +item.quantity;
            }
        }
    }

}
