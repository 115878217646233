import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { lzGetFormattedDate } from '../../../../../../common/services/LzMoment';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';
import PrivateLayout from '../../../layouts/private';

class UserDashboardScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentBalance: 0,
            customerName: '',
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { authUser } = this.props;

        // Make the api call and get the data
        const data = await lzCallApi('dashboard', {
            client_id: authUser.client_id,
        });

        // Load data if call was successful
        if (data && data.customerName) {
            this.setState({
                customerName: data.customerName,
                currentBalance: +data.currentBalance || 0,
                activeOrders: +data.activeOrders || 0,
                nextDeliveryDate: data.nextDeliveryDate || '',
            });
        }
    }

    render() {
        const { customerName, currentBalance, activeOrders, nextDeliveryDate } = this.state;

        // Calculate the height of dash-top.png image to adjust with the header
        // 0.385 is the width to height ratio of that image
        // 48 is the top (3em x 16px) that is applied in .lz-screen-main
        const dashTopHeight = window.innerWidth * 0.385 - 48;

        return (
            <PrivateLayout activeTabKey="home" className="ndf-dash-top">

                <div className="ndf-dashboard">
                    <div className="dash-top" style={{ height: dashTopHeight }}>
                        <span>Welcome</span>
                        <b>{customerName}</b>
                        <div className="date">{lzGetFormattedDate('now', 'DD MMM yyyy')}</div>
                        <small>{activeOrders} Active subscriptions</small>
                    </div>

                    <Container>
                        <div className="stats">
                            <Row>
                                <Col xs={7} style={{ textAlign: "left" }}>
                                    <b>Next Delivery: </b>
                                    {lzGetFormattedDate(nextDeliveryDate, 'ddd, DD MMM YYYY')}
                                </Col>
                                <Col xs={5} style={{ textAlign: "right" }}>
                                    <b>Amount Due:</b>
                                    {lzGetFormattedAmount(currentBalance, true)}
                                </Col>
                            </Row>
                        </div>
                        <div className="icons">
                            <Row>
                                <Col xs={4}>
                                    <Link to="/plan">
                                        <LzIcon source="ndf-png" name="order" />
                                        <p>Order</p>
                                    </Link>
                                </Col>
                                <Col xs={4}>
                                    <Link to="/deliveries-history">
                                        <LzIcon source="ndf-png" name="deliveries" />
                                        <p>Deliveries</p>
                                    </Link>
                                </Col>
                                <Col xs={4}>
                                    <Link to="/payment-history">
                                        <LzIcon source="ndf-png" name="payments" />
                                        <p>Payments</p>
                                    </Link>
                                </Col>
                                <Col xs={4}>
                                    <Link to="/ledger">
                                        <LzIcon source="ndf-png" name="schedule" />
                                        <p>Ledger</p>
                                    </Link>
                                </Col>
                                <Col xs={4}>
                                    <Link to="/customer-support">
                                        <LzIcon source="ndf-png" name="complaints" />
                                        <p>Complaints</p>
                                    </Link>
                                </Col>
                                {/*<Col xs={4}>
                            <Link to="/messages">
                                <LzIcon source="ndf-png" name="messages" />
                                <p>Messages</p>
                            </Link>
                        </Col>*/}
                                <Col xs={4}>
                                    <Link to="/account">
                                        <LzIcon source="ndf-png" name="account" />
                                        <p>My Account</p>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(UserDashboardScreen);
