import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import LzButton from '../../../../../../common/components/commons/button';
import LzIcon from '../../../../../../common/components/commons/icon';
import LzFormFieldSwitch from '../../../../../../common/components/commons/fields/switch';
import { Col, Container, Row } from 'react-bootstrap';
import LzForm from '../../../../../../common/components/commons/form';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzGetFormattedAmount } from '../../../../../../common/services/LzUtils';
import ConfirmDialogComponent from '../../../commons/confirm-dialog';

class UpdatePlanWeeklyScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            preferredTimes: [],
            weeklySchedule: [],
            model: {},
            estMonthlyBill: 0,
            confirmCancel: false,
        };
    }

    componentDidMount() {
        this.loadPreferredTimeList();
    }

    handleModelChange = (name, value) => {
        const { model } = this.state;
        model[name] = value;
        this.setState({ model: model });
    };

    handleWeekdayChange(dex, name, value) {
        const { weeklySchedule } = this.state;

        weeklySchedule[dex][name] = value;

        if ('quantity' === name) {
            weeklySchedule[dex].isSelected = +value > 0 ? 1 : 0;
        }

        if ('isSelected' === name) {
            weeklySchedule[dex].quantity = 0;
        }

        this.setState({ weeklySchedule: weeklySchedule, estMonthlyBill: this.evalEstMonthlyBill(weeklySchedule) });
    }

    applyToAll() {
        const { weeklySchedule } = this.state;

        for (const key in weeklySchedule) {
            if (key > 0) {
                weeklySchedule[key].PreferredTime = weeklySchedule[0].PreferredTime;
                weeklySchedule[key].quantity = weeklySchedule[0].quantity;
                weeklySchedule[key].isSelected = weeklySchedule[0].isSelected;
            }
        }

        this.setState({ weeklySchedule: weeklySchedule, estMonthlyBill: this.evalEstMonthlyBill(weeklySchedule) });
    }

    evalEstMonthlyBill(weeklySchedule) {
        const { product } = this.props;

        let totQty = 0;
        for (const key in weeklySchedule) {
            totQty += +weeklySchedule[key].quantity;
        }

        const qtyPerDayApprox = Math.round(totQty / 7);

        const totQtyAllDays = 4 * totQty + 3 * qtyPerDayApprox;

        return totQtyAllDays * product.price;
    }

    async loadPreferredTimeList() {
        // Make the api call and get the data
        const data = await lzCallApi('getPreferredTimeList');

        // Load data if call was successful
        if (data) {
            this.setState({ preferredTimes: data }, () => this.loadWeeklySchedule());
        }
    }

    async loadWeeklySchedule() {
        const { model } = this.state;
        const { product, authUser, history } = this.props;

        if (!product) {
            setErrorMessage('Product not selected!');
            history.push('/plan');
            return;
        }

        // Make the api call and get the data
        const data = await lzCallApi('getWeeklySchedule', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Load data if call was successful
        if (data && data.data && data.orderStartDate) {
            model.fromDate = data.orderStartDate;
            this.setState({ model: model, weeklySchedule: data.data, estMonthlyBill: this.evalEstMonthlyBill(data.data) });
        }
    }

    async saveWeeklyPlan() {
        const { model, weeklySchedule } = this.state;
        const { product, authUser, setSuccessMessage } = this.props;

        // Make the api call and get processed data
        const data = await lzCallApi('updateWeeklySchedual', {
            client_id: authUser.client_id,
            product_id: product.product_id,
            orderStartDate: model.fromDate,
            data: weeklySchedule,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully saved!');
        }
    }

    async cancelPlan(confirmed) {
        const { authUser, product, setSuccessMessage, history } = this.props;

        if (confirmed) {
            this.setState({ confirmCancel: false });
        } else {
            this.setState({ confirmCancel: true });
            return;
        }

        // Make the api call and get processed data
        const data = await lzCallApi('CancelRegularOrder', {
            client_id: authUser.client_id,
            product_id: product.product_id,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your plan was successfully cancelled!');
            history.replace('/plan-select-type');
        }
    }

    render() {
        const { product } = this.props;
        const { model, weeklySchedule, estMonthlyBill, confirmCancel } = this.state;

        if (!product) {
            return <LzIcon name="spinner" />;
        }

        return (
            <PrivateLayout layout="plan" screenTitle="Weekly Plan" planLayoutSideContent={
                <React.Fragment>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={7}>Rate :</Col>
                        <Col xs={5}><b>{lzGetFormattedAmount(product.price, true) + '/' + product.unit}</b></Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={7}>Est. Monthly Bill :</Col>
                        <Col xs={5}><b>{lzGetFormattedAmount(estMonthlyBill, true)}</b></Col>
                    </Row>
                </React.Fragment>
            }>

                <div className="lz-form-page lz-plan-form">

                    <Container>

                        <LzForm onSubmit={() => this.saveWeeklyPlan()}>

                            {(weeklySchedule && weeklySchedule.length > 0) &&
                                <React.Fragment>

                                    <div style={{ textAlign: 'left' }}>
                                        <LzButton size="sm" icon="list-alt" label="Repeat for All" onClick={() => this.applyToAll()} style={{ display: 'inline-block' }}></LzButton>
                                    </div>

                                    <Row noGutters>
                                        <Col xs={5} style={{ lineHeight: 2.6, textAlign: 'center' }}>Start Date :</Col>
                                        <Col xs={7}>
                                            <LzFormFieldInput required fluid type="date" label="" hint=" "
                                                name="fromDate" value={model.fromDate} onChange={this.handleModelChange} />
                                        </Col>
                                    </Row>

                                    {weeklySchedule.map((ws, dex) =>
                                        <React.Fragment key={'wksched-' + dex}>
                                            <Row className="lz-gutters-5px" style={{ marginTop: 0 === dex ? 0 : 10 }}>
                                                <Col xs={2}>
                                                    <LzFormFieldSwitch
                                                        asSwitch
                                                        size="md"
                                                        label=""
                                                        name={'isSelected_' + dex}
                                                        value={+ws.isSelected > 0}
                                                        onChange={() => this.handleWeekdayChange(dex, 'isSelected', +ws.isSelected > 0 ? 0 : 1)}
                                                    />
                                                </Col>
                                                <Col xs={4} className="lz-weekly-day">
                                                    {ws.day_name}
                                                </Col>
                                                <Col xs={3}>
                                                    <input type="number" value={ws.quantity} className="lz-weekly-qty"
                                                        onChange={(evt) => this.handleWeekdayChange(dex, 'quantity', evt.target.value)} />
                                                </Col>
                                                <Col xs={3}>
                                                    <div className="label-box">{product.unit}</div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            }

                            <div style={{ textAlign: 'right', paddingTop: 10 }}>
                                <LzButton type="submit" icon="save" label="Save Plan" style={{ display: 'inline-block' }} />
                            </div>

                            <LzButton type="button" icon="times" label="Cancel Current Schedule" onClick={() => this.cancelPlan()} />

                            <Link to="/plan-calendar"><LzButton type="button" icon="cogs" label="Advanced Options" /></Link>

                            {/*
                            <Link to="/plan-interval-halt"><LzButton type="button" variant="link" label="Halt Plan" /></Link>
                            <Link to="/plan-interval-onetime"><LzButton type="button" variant="link" label="Place One-Time Order" /></Link>
                            */}

                        </LzForm>

                    </Container>

                </div>

                <ConfirmDialogComponent
                    show={confirmCancel}
                    message="Are you sure you want to cancel the current plan?"
                    onCancel={() => this.setState({ confirmCancel: false })}
                    onConfirm={() => this.cancelPlan(true)}
                />

            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        product: state.plan.product,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdatePlanWeeklyScreen));
