import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { createStore } from "redux";
import { Provider } from 'react-redux';
import reducer from "../../common/redux/reducers";
import { isAndroid, isIOS } from "../../common/services/LzHostInterface";

import LogoutScreen from '../../common/components/screens/private/user/logout';
import LoginScreen from './components/screens/public/user/login';
import RegisterScreen from './components/screens/public/user/register';
import UserDashboardScreen from './components/screens/private/user/dashboard';
import UserAccountScreen from './components/screens/private/user/account';
import ChangePasswordScreen from './components/screens/private/user/change-password';
import UpdateProfileScreen from './components/screens/private/user/update-profile';
import UpdatePlanProductsScreen from './components/screens/private/plan/select-product';
import UpdatePlanSelectTypeScreen from './components/screens/private/plan/select-type';
import UpdatePlanIntervalScreen from './components/screens/private/plan/interval';
import UpdatePlanIntervalHaltScreen from './components/screens/private/plan/interval-halt';
import UpdatePlanIntervalOneTimeScreen from './components/screens/private/plan/interval-onetime';
import UpdatePlanWeeklyScreen from './components/screens/private/plan/weekly';
import LedgerScreen from './components/screens/private/history/ledger';
import DeliveriesHistoryScreen from './components/screens/private/history/deliveries';
import PaymentsHistoryScreen from './components/screens/private/history/payments';
import CustomerSupportScreen from './components/screens/private/help/customer-support';
import AboutApplicationScreen from '../../common/components/screens/public/commons/about-application';
import ForgotPasswordScreen from './components/screens/public/user/forgot-password';

window.reduxStore = createStore(reducer);

export const routing = (
    <Provider store={window.reduxStore}>
        <Router>
            <div>
                <Route path="/landing" component={LoginScreen} />
                <Route path="/login" component={LoginScreen} />
                <Route path="/register" component={RegisterScreen} />
                <Route path="/forgot-password" component={ForgotPasswordScreen} />
                <Route path="/about-application" component={AboutApplicationScreen} />

                <Route path="/" component={UserDashboardScreen} exact />
                <Route path="/account" component={UserAccountScreen} />
                <Route path="/profile" component={UpdateProfileScreen} />
                <Route path="/password" component={ChangePasswordScreen} />
                <Route path="/logout" component={LogoutScreen} />

                <Route path="/plan" component={UpdatePlanProductsScreen} />
                <Route path="/plan-select-type" component={UpdatePlanSelectTypeScreen} />
                <Route path="/plan-interval" component={UpdatePlanIntervalScreen} />
                <Route path="/plan-interval-halt" component={UpdatePlanIntervalHaltScreen} />
                <Route path="/plan-interval-onetime" component={UpdatePlanIntervalOneTimeScreen} />
                <Route path="/plan-weekly" component={UpdatePlanWeeklyScreen} />

                <Route path="/ledger" component={LedgerScreen} />
                <Route path="/deliveries-history" component={DeliveriesHistoryScreen} />
                <Route path="/payment-history" component={PaymentsHistoryScreen} />

                <Route path="/customer-support" component={CustomerSupportScreen} />

                {/* this below rule will send to / initially even if a url like /user-login is loaded in the browser */}
                {(isAndroid() || isIOS()) && <Route path="*" render={() => (<Redirect to="/" />)} />}
            </div>
        </Router>
    </Provider>
);
