import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class ConfirmDialogComponent extends React.Component {

  render() {
    const { show, heading, message, onCancel, onConfirm } = this.props;

    return (
      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>{heading || 'Please Confirm!'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{message || 'Are you sure you want to continue?'}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => onConfirm()}>Yes</Button>
          <Button variant="secondary" onClick={() => onCancel()}>No</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default ConfirmDialogComponent;
