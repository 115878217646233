import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PrivateLayout from '../../../layouts/private';
import SelectProductComponent from '../../../commons/select-product';
import { setProduct } from '../../../../../../common/redux/actions';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { lzGetLoggedInUserId } from '../../../../../../common/services/LzUtils';
import LzIcon from '../../../../../../common/components/commons/icon';

class UpdatePlanProductsScreen extends React.Component {

  selectProduct(product, action) {
    const { setProduct, history } = this.props;

    setProduct(product);

    if (product) {
      if ('onetime' === action) {
        history.push('/plan-interval-onetime');
        return;
      }

      if ('detail' === action) {
        history.push('/plan-product-detail');
        return;
      }

      this.loadProductPlanType(product);
    }
  }

  async loadProductPlanType(product) {
    const { history } = this.props;

    // Make the api call and get the data
    const data = await lzCallApi('getCustomerRegularOrderType', {
      product_id: product.product_id,
      client_id: lzGetLoggedInUserId().id,
    }, 'post');

    // Load data if call was successful
    if (2 === data) {
      history.push('/plan-interval');
    }
    else {
      history.push('/plan-weekly');
    }
  }

  render() {
    return (
      <PrivateLayout screenTitle="Update Plan" activeTabKey="plan" lockForGuests>

        <SelectProductComponent onSelect={(product, action) => this.selectProduct(product, action)} />

        <Link to="/quality-report" style={{ display: 'block', textAlign: 'center', color: 'white', textDecoration: 'none' }}>
          <LzIcon name="certificate" size="5x" style={{ display: 'block' }} />
          Quality Report
        </Link>

      </PrivateLayout>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    setProduct: (value) => dispatch(setProduct(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(UpdatePlanProductsScreen));
