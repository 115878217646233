import { combineReducers } from 'redux';

import commons from "./commons";
import auth from "./auth";
import layout from "./layout";
import plan from "./plan";

export default combineReducers({
    commons,
    auth,
    layout,
    plan,
});
