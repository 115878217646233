import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import LzButton from '../../../../../../common/components/commons/button';
import LzFormFieldInput from '../../../../../../common/components/commons/fields/input';
import LzForm from '../../../../../../common/components/commons/form';
import { setErrorMessage, setSuccessMessage } from '../../../../../../common/redux/actions';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import PrivateLayout from '../../../layouts/private';

class ChangePasswordScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            cfmPassword: '',
        };
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    async callChangePasswordApi() {
        const { oldPassword, newPassword, cfmPassword } = this.state;
        const { authUser, setErrorMessage, setSuccessMessage } = this.props;

        // Validate input data
        if (newPassword !== cfmPassword) {
            setErrorMessage('The new password and confirm password do not match.');
            return
        }

        // Make the api call and get processed data
        const data = await lzCallApi('changeCustomerPassword', {
            client_id: authUser.client_id,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }, 'post');

        // Check if call was successful
        if (data) {
            setSuccessMessage('Your password is successfully changed!');
        }
    }

    render() {
        const { oldPassword, newPassword, cfmPassword } = this.state;

        return (
            <PrivateLayout screenTitle="Change Password" backLink="/account" activeTabKey="account">
                <div>
                    <Container>

                        <LzForm onSubmit={() => this.callChangePasswordApi()}>

                            <LzFormFieldInput required fluid type="password" label="Old Password"
                                name="oldPassword" value={oldPassword} onChange={this.handleInputChange} />

                            <LzFormFieldInput required fluid type="password" label="New Password"
                                name="newPassword" value={newPassword} onChange={this.handleInputChange} />

                            <LzFormFieldInput required fluid type="password" label="Confirm Password"
                                name="cfmPassword" value={cfmPassword} onChange={this.handleInputChange} />

                        </LzForm>

                    </Container>

                    <LzButton fluid type="submit" icon="save" label="Save Changes" onClick={() => this.callChangePasswordApi()} />

                </div>
            </PrivateLayout>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        loading: state.commons.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setErrorMessage: (value) => dispatch(setErrorMessage(value)),
        setSuccessMessage: (value) => dispatch(setSuccessMessage(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordScreen);
