import React from 'react';
import LzIcon from '../../../../common/components/commons/icon';

class SocialLinksComponent extends React.Component {

    render() {
        return (
            <div>
                <a href="https://www.facebook.com/mksfarmofficial/" target="_blank" rel="noreferrer"><LzIcon name="facebook" /></a>
                <a href="https://www.instagram.com/mksfarmofficial/" target="_blank" rel="noreferrer"><LzIcon name="instagram" /></a>
                <a href="https://twitter.com/FarmMks?s=08" target="_blank" rel="noreferrer"><LzIcon name="twitter" /></a>
            </div>
        );
    }

}

export default SocialLinksComponent;
