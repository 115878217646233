import React from 'react';
import LzFormFieldBase from './_base';

class LzFormFieldStatic extends React.Component {

    render() {
        const { fluid, inline, muted, icon, className, style, label, value } = this.props;

        return (
            <LzFormFieldBase
                fluid={fluid}
                inline={inline}
                icon={icon}
                label={label}
            >

                <p
                    className={
                        'lz-static ' +
                        (inline ? 'lz-inline ' : '') +
                        (muted ? 'lz-muted ' : '') +
                        (className ? className : '')
                    }
                    style={style || {}}
                    dangerouslySetInnerHTML={{ __html: value }}
                ></p>

            </LzFormFieldBase>
        );
    }

}

export default LzFormFieldStatic;
