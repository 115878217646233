import React from 'react';
import { Link } from 'react-router-dom';
import { lzGetAssetUrl } from '../../../../common/services/LzUtils';
import ImgLogo from '../../assets/images/bg-plan-top.png';
import LzBotIconsBar from './partials/bot-icons-bar';

class PrivateLayoutPlan extends React.Component {

  render() {
    const { activeTabKey, screenTitle, backLink, sideContent, topBarRightWide, children } = this.props;

    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: '100vh',
      }}>
        <div className="header-2" style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}>
          <div className={'top-bar ' + (topBarRightWide ? 'right-wide' : '')}>
            <Link to={backLink ? backLink : '/'}><i className="fa fa-chevron-circle-left"></i></Link>
            <div className="heading">{screenTitle}</div>
            <div></div>
          </div>

          <div className="height-stretcher"></div>

          <div className="side-content">{sideContent}</div>

          <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' className='logo' />
        </div>

        <div className="lz-main-grad-box">
          <div>

            {children}

          </div>
        </div>

        <LzBotIconsBar activeTabKey={activeTabKey} />

      </div>
    );
  }

}
export default PrivateLayoutPlan;
