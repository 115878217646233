import { callIOSNativeCommand, runOnHost } from "./LzHostInterface";
import { lzCallApi } from "./LzApiService";
import { setErrorMessage, setLoading } from "../redux/actions";
import { lzGetFromLocalStorage, lzSaveToLocalStorage } from "./LzLocalStorage";
import { lzGetLoggedInUserId } from "./LzUtils";

export const callFCMTokenLoadOnHost = () => {
    console.log('APP-FCM - Calling host to load fcm register token');
    runOnHost(
        () => {
            window.Android.callGetFCMToken();
        },
        () => {
            callIOSNativeCommand("callGetFCMToken")
        },
        () => {
            // TODO: Apply code
        },
    );
};

export const updateFCMRegisterToken = async (token, from) => {
    // Check if token is same as before and not changed
    if (token === lzGetFromLocalStorage('fcm_token', '')) {
        console.log('FCM Token MATCHED! No need to update on server.');
        return;
    }

    console.log('FCM Token NOT MATCHED! Updating on server...');

    // Get the redux state and dispatch
    const state = window.reduxStore.getState();
    const dispatch = window.reduxStore.dispatch;

    // If already loading then skip
    if (state.commons.loading) {
        console.log('Some loading already in progress... re-scheduling FCM call!');
        window.setTimeout(() => updateFCMRegisterToken(token, from), 1000);
        return;
    }

    // Tell app that we are loading
    dispatch(setLoading(true));

    // Call api with error handling
    try {
        // Get logged in user id
        const loggedInUser = lzGetLoggedInUserId();

        // Make the api call and get processed data
        const data = await lzCallApi('saveFcmToken', {
            client_id: +(loggedInUser?.id || 0),
            type: from || 'Android',
            fcm_token: token,
        });

        console.log('REGISTER-FCM-TOKEN-RESPONSE', data);

        // Save to local storage
        lzSaveToLocalStorage('fcm_token', token);

        // Hide loading
        dispatch(setLoading(false));
    } catch (e) {
        console.log('ERROR', e);
        dispatch(setErrorMessage(e.toString()));
        dispatch(setLoading(false));
    }
};

export const removeFCMRegisterToken = async () => {
    // Get from local storage and skip if already not there
    const token = lzGetFromLocalStorage('fcm_token', '');
    if ('' === token) {
        console.log('FCM Token already not found! No need to remove from server.');
        return;
    }

    console.log('FCM Token FOUND! Removing from server...', token);

    // Get the redux state and dispatch
    const state = window.reduxStore.getState();
    const dispatch = window.reduxStore.dispatch;

    // If already loading then skip
    if (state.commons.loading) {
        return;
    }

    // Tell app that we are loading
    dispatch(setLoading(true));

    // Call api with error handling
    try {
        // Get logged in user id
        const loggedInUser = lzGetLoggedInUserId();

        // Make the api call and get processed data
        const data = await lzCallApi('removeFcmToken', {
            client_id: +(loggedInUser?.id || 0),
            type: 'Android',
            fcm_token: token,
        });

        console.log('REMOVE-FCM-TOKEN-RESPONSE', data);

        // Remove from local storage
        lzSaveToLocalStorage('fcm_token', '');

        // Hide loading
        dispatch(setLoading(false));
    } catch (e) {
        console.log('ERROR', e);
        dispatch(setErrorMessage(e.toString()));
        dispatch(setLoading(false));
    }
};

export const updateFCMNotificationsSummary = async () => {
    /* NOT YET INTEGARTED FOR THIS APP
    console.log('Reloading FCM notifications...');

    // Get the redux state and dispatch
    const state = window.reduxStore.getState();
    const dispatch = window.reduxStore.dispatch;

    // If already loading then skip
    if (state.commons.loading) {
        console.log('Some loading already in progress... re-scheduling FCM call!');
        window.setTimeout(() => updateFCMNotificationsSummary(), 1000);
        return;
    }

    // Tell app that we are loading
    dispatch(setLoading(true));

    // Call api with error handling
    try {
        // Make the api call and get processed data
        const data = await lzCallApi('pushnotification/get-notifications-summary', {
            token: state.auth.token,
        });

        console.log('NOTIFICATIONS-SUMMARY-UPDATE', data);

        // Update to store
        dispatch(setNotificationsSummary(data));

        // Hide loading
        dispatch(setLoading(false));
    } catch (e) {
        console.log('ERROR', e);
        dispatch(setErrorMessage(e.toString()));
        dispatch(setLoading(false));
    }
    */
};
