export default class PlanIntervalModel {

    constructor(model) {
        if (!model) {
            model = {};
        }

        this.interval = model.interval_days ? +model.interval_days : model.interval ? +model.interval : 0;
        this.quantity = model.quantity ? +model.quantity : 0;
        this.default_value = model.default_value ? +model.default_value : 0;
    }

}
