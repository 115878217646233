import { updateFCMNotificationsSummary, updateFCMRegisterToken } from "./LzFirebaseCloudMessaging";
import { lzLogInfo } from "./LzUtils";

// Get the ios bundle path
// export const getIOSBundlePath = () => getFromLocalStorage('ios_bundle_path', window.IOS_BUNDLE_PATH || '');
// we want to avoid badly saved persisted data
export const getIOSBundlePath = () => window.IOS_BUNDLE_PATH || '';

// Identify iOS (this variable is/must-be set from iOS webview caller)
export const isIOS = () => getIOSBundlePath() !== '';

// Identify Android
export const isAndroid = () => !isIOS() && 0 === window.location.href.indexOf('file:///');

// Change identification method if required
export const isBrowser = () => !isAndroid() && !isIOS();

// Call ios native code, the cmd can be anything with arguments passed as part of cmd, e.g. MSG|Some message here
export const callIOSNativeCommand = (cmd, silent) => {
    if (window.webkit?.messageHandlers?.iOSNative?.postMessage) {
        window.webkit.messageHandlers.iOSNative.postMessage(cmd);
    } else if (!silent) {
        lzLogInfo("iOS Native Command:", cmd, " --- cannot be run...");
    }
}

/**
 * Run a callback function on the respective host
 *
 * @param android
 * @param ios
 * @param browser
 */
export const runOnHost = (android, ios, browser) => {
    console.log('APP-RUN-ON-HOST', isAndroid(), isIOS(), isBrowser());
    if (isAndroid() && typeof android === 'function') {
        android();
    }
    if (isIOS() && typeof ios === 'function') {
        ios();
    }
    if (isBrowser() && typeof browser === 'function') {
        browser();
    }
};

window.hostCalledFCMTokenUpdate = (token, from) => {
    lzLogInfo('Host Called FCM Token Update!', token, from);
    updateFCMRegisterToken(token, from || 'Android');
};

window.hostCalledFCMNotificationReceived = () => {
    updateFCMNotificationsSummary();
};
