import React from 'react';
import { Link } from 'react-router-dom';
import { lzGetAssetUrl } from '../../../../common/services/LzUtils';
import ImgLogo from '../../assets/images/bg-settings-top.png';
import LzBotIconsBar from './partials/bot-icons-bar';

class PrivateLayoutSettings extends React.Component {

  render() {
    const { activeTabKey, screenTitle, backLink, children } = this.props;

    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: '100vh',
      }}>
        <div className="header-1" style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}>
          <div className="top-bar-settings">
            <Link to={backLink ? backLink : '/'}><i className="fa fa-chevron-circle-left"></i></Link>
            <div className="heading">{screenTitle}</div>
            {/*<Link to={'/settings'}><i className="fa fa-cog"></i></Link>*/}
            <div></div>
          </div>
          <div className="height-stretcher"></div>
          <img src={lzGetAssetUrl(ImgLogo)} alt='Logo' className='logo-settings' />
          <div className="bot-shosha-1"></div>
          <div className="bot-shosha-2"></div>
        </div>

        <div className="lz-main-white-box">
          <div>

            {children}

          </div>
        </div>

        <LzBotIconsBar activeTabKey={activeTabKey} />

      </div>
    );
  }

}
export default PrivateLayoutSettings;
