import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { lzIsArray } from '../../../services/LzType';
import LzFormFieldBase from './_base';

class LzFormFieldSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: props.options ? props.options : [],
            value: props.value ? props.value : '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { options, value } = this.props;

        // If value changed then apply it to state
        let changed = false;
        let states = {};
        if (typeof value !== 'undefined' && value !== prevProps.value) {
            states.value = value;
            changed = true;
        }
        if (typeof options !== 'undefined' && options !== prevProps.options) {
            states.options = options;
            changed = true;
        }
        if (changed) {
            this.setState(states);
        }
    }

    onChange(val) {
        const { name, onChange } = this.props;

        this.setState({ value: val });

        if (onChange) {
            onChange(name, val);
        }
    }

    render() {
        const { options, value } = this.state;
        const { fluid, required, hasError, errorMessage, icon, className, style, id, name, label, placeholder, noFirstOption, firstOptionText } = this.props;

        const theName = name ? name : '';
        const theId = id ? id : 'id_' + theName;

        console.log('SHOW SELECT', id, name, label, value, options);

        return (
            <LzFormFieldBase
                id={theId}
                fluid={fluid}
                required={required}
                icon={icon}
                name={name}
                label={label}
                placeholder={placeholder}
                errorMessage={hasError ? errorMessage : ''}
            >

                {!lzIsArray(options) &&
                    <div className='lz-select'>
                        {'loading' === options && <Spinner animation="border" />}
                        {'loading' !== options && <Alert variant="warning">{options}</Alert>}
                    </div>
                }

                {lzIsArray(options) &&
                    <Form.Control as="select" custom
                        isInvalid={hasError}
                        required={required}
                        name={name || ''}
                        className={'lz-select ' + (className ? className : '')}
                        style={style || {}}
                        placeholder={placeholder || ''}
                        value={value}
                        onChange={(evt) => this.onChange(evt.target.value)}
                    >
                        {!noFirstOption && <option value={0}>{firstOptionText || '- please select -'}</option>}
                        {options.map((option, dex) =>
                            <option key={'opt-' + dex} value={option.value}>{option.text}</option>
                        )}
                    </Form.Control>
                }

            </LzFormFieldBase>
        );
    }

}

export default LzFormFieldSelect;
