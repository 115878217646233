/**
 * This object holds the company related global constants.
 *
 * @type object
 */
export const CompanyConstants = {
    companies: [
        { id: 18, name: 'Mustafa Town' },
        { id: 20, name: 'Ittehad Town' },
        { id: 21, name: 'Paragon' },
        { id: 22, name: 'Pak Arab' },
    ],
};
