import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
// import PrivateLayout from '../../../layouts/private';

class UserDashboardScreen extends React.Component {

  render() {

    return <Redirect to={'/plan'} />;

    /*
    return (
      <PrivateLayout screenTitle="Home" activeTabKey="home" backLink="/logout">
        redirecting to plans...
      </PrivateLayout>
    );
    */
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(UserDashboardScreen);
