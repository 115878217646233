import React from 'react';
import UnderConstruction from '../../../commons/under-construction';
import PublicLayout from '../../../layouts/public';

class AboutApplicationScreen extends React.Component {

    render() {
        return (
            <PublicLayout>
                <UnderConstruction />
            </PublicLayout>
        );
    }

}

export default AboutApplicationScreen;
