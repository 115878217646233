import React from 'react';
import Form from 'react-bootstrap/Form';
import LzFormFieldBase from './_base';

class LzFormFieldSwitch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value } = this.props;

    // If value changed then apply it to state
    if (typeof value !== 'undefined' && value !== prevProps.value) {
      this.setState({ value: value });
    }
  }

  onChange() {
    const { value } = this.state;
    const { name, onChange } = this.props;

    this.setState({ value: !value });

    if (onChange) {
      onChange(name, !value);
    }
  }

  render() {
    const { value } = this.state;
    const { asSwitch, fluid, className, style, size, transformOrigin, align, id, name, label } = this.props;

    const theName = name ? name : '';
    const theId = id ? id : 'id_' + theName;

    const baseStyle = {
      display: 'inline-block',
      transformOrigin: transformOrigin || align || 'left',
      marginBottom: 0,
      paddingBottom: 0,
    };
    if ('md' === size) {
      baseStyle.transform = 'scale(1.2)';
    }
    else if ('lg' === size) {
      baseStyle.transform = 'scale(1.5)';
    }

    return (
      <div style={{ textAlign: align || 'left' }}>
        <LzFormFieldBase
          id={theId}
          fluid={fluid}
          style={baseStyle}
        >

          <Form.Check
            type={asSwitch ? 'switch' : 'checkbox'}
            name={theName}
            className={'lz-switch ' + (className ? className : '')}
            style={style || {}}
            label={label}
            value={1}
            checked={value}
            onChange={() => this.onChange()}
          />

        </LzFormFieldBase>
      </div>
    );
  }

}

export default LzFormFieldSwitch;
