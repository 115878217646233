/**
 * This object holds the App related global constants.
 *
 * @type object
 */
export const AppConstants = {
    app_company_id: process.env.REACT_APP_COMPANY_ID,
    app_code: process.env.REACT_APP_CODE,
    app_name: process.env.REACT_APP_NAME,
    google_maps_api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};
