import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { lzCallApi } from '../../services/LzApiService';
import ProductModel from '../../models/product';

class SelectProductComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    componentDidMount() {
        this.callLoadProductsApi();
    }

    async callLoadProductsApi() {
        // Make the api call and get the data
        const data = await lzCallApi('getAllProduct', {
            client_id: this.props.authUser.client_id,
        }, 'post');

        // Load data if call was successful
        if (data) {
            this.setState({
                products: ProductModel.parseArray(data),
            });
        }
    }

    render() {
        const { products } = this.state;
        const { product, onSelect } = this.props;

        return (
            <Container>
                <Row>

                    {products.map((prod, dex) =>
                        <Col
                            xs={6}
                            key={'sel-prod-' + dex}
                        >
                            <div
                                className={'lz-product-sel' + (product?.product_id === prod.product_id ? ' active' : '')}
                                onClick={() => onSelect(prod)}
                            >
                                <img src={prod.product_image} alt="prod" />
                                {prod.product_name}
                            </div>
                        </Col>
                    )}

                </Row>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
    }
};

export default connect(
    mapStateToProps
)(SelectProductComponent);
