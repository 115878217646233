import React from 'react';
import { connect } from 'react-redux';
import PrivateLayout from '../../../layouts/private';
import { lzGetFormattedDate, lzGetMonthStartDate } from '../../../../../../common/services/LzMoment';
import { lzCallApi } from '../../../../../../common/services/LzApiService';
import { Table } from 'react-bootstrap';
import LzIcon from '../../../../../../common/components/commons/icon';
import { lzIsArray } from '../../../../../../common/services/LzType';
import LoadingComponent from '../../../commons/loading';

class LedgerScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      summary: [],
      yearMonth: lzGetMonthStartDate('YYYY-MM'),
    };
  }

  componentDidMount() {
    this.loadHistory();
  }

  async loadHistory() {
    const { yearMonth } = this.state;
    const { authUser } = this.props;

    const yearMonthSplit = yearMonth.split('-');

    // Make the api call and get the data
    const data = await lzCallApi('get_ledger_report', {
      client_id: authUser.client_id,
      year: yearMonthSplit[0],
      month: yearMonthSplit[1],
    });

    // Load data if call was successful
    if (data) {
      this.setState({
        records: data.ledgerData,
        summary: data.sumery,
      });
    }
  }

  changeMonthYear(value) {
    this.setState({ yearMonth: value }, () => this.loadHistory());
  }

  render() {
    const { yearMonth, records } = this.state;

    if (!lzIsArray(records) || 0 === records.length) {
      return <LoadingComponent show={true} />
    }

    return (
      <PrivateLayout layout="plan" screenTitle="Bill History" activeTabKey="record"
        planLayoutTopBarRightWide
        planLayoutSideContent={
          <div style={{ marginBottom: 10 }}>
            <input type="month" name="yearMonth" className="input-field" style={{ display: 'block', minWidth: 160, marginBottom: 5 }}
              value={yearMonth} onChange={(evt) => this.changeMonthYear(evt.target.value)}
            />
            Opening Balance | {lzGetFormattedDate(yearMonth, "MMM")} :
            <b> {-records[0].balance}</b>
          </div>
        }
      >
        {(lzIsArray(records) && records.length > 0) &&
          <div className="records-wrapper">
            <Table className="records">
              <thead>
                <tr>
                  <th><div>Date</div></th>
                  <th><div>Description</div></th>
                  <th><div>Bill</div></th>
                  <th><div>Paid</div></th>
                  <th><div>Balance</div></th>
                </tr>
              </thead>
              <tbody>
                {records.map((rec, dex) =>
                  <React.Fragment key={'ledger-' + dex}>
                    {0 === dex ?
                      <tr>
                        <td>{lzGetFormattedDate(rec.date)}</td>
                        <td colSpan={2}>{rec.discription}</td>
                        <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>{-rec.balance}</td>
                      </tr>
                      :
                      <tr>
                        <td>{lzGetFormattedDate(rec.date)}</td>
                        <td>{rec.discription}</td>
                        <td>{rec.delivery}</td>
                        <td>{rec.reciveAmount}</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>{-rec.balance}</td>
                      </tr>
                    }
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </div>
        }

      </PrivateLayout>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  }
};

export default connect(
  mapStateToProps
)(LedgerScreen);
