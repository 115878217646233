import React from 'react';
import Form from 'react-bootstrap/Form';
import LzFormFieldBase from './_base';

class LzFormFieldTexarea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { value } = this.props;

        // If value changed then apply it to state
        if (typeof value !== 'undefined' && value !== prevProps.value) {
            this.setState({ value: value });
        }
    }

    onChange(val) {
        const { name, onChange } = this.props;

        this.setState({ value: val });

        if (onChange) {
            onChange(name, val);
        }
    }

    render() {
        const { value } = this.state;
        const { fluid, required, hasError, errorMessage, icon, className, style, rows, id, name, label, placeholder } = this.props;

        const theName = name ? name : '';
        const theId = id ? id : 'id_' + theName;

        return (
            <LzFormFieldBase
                id={theId}
                fluid={fluid}
                required={required}
                icon={icon}
                name={name}
                label={label}
                errorMessage={hasError ? errorMessage : ''}
            >

                <Form.Control as="textarea"
                    isInvalid={hasError}
                    required={required}
                    rows={rows || 3}
                    name={name || ''}
                    className={'lz-textarea ' + (className ? className : '')}
                    style={style || {}}
                    placeholder={placeholder || ''}
                    value={value}
                    onChange={(evt) => this.onChange(evt.target.value)}
                />

            </LzFormFieldBase>
        );
    }

}

export default LzFormFieldTexarea;
